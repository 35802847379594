import React from 'react';
import { Breadcrumb, BreadcrumbItem } from '@Comp';

const BreadcrumbExample = (props) => {
  return (
    <div className="mb-5">
        <Breadcrumb tag="nav">
            <BreadcrumbItem tag="a" href="#">Home</BreadcrumbItem>
            <BreadcrumbItem tag="a" href="#">Library</BreadcrumbItem>
            <BreadcrumbItem tag="a" href="#">Data</BreadcrumbItem>
            <BreadcrumbItem active tag="span">React Bootstrap</BreadcrumbItem>
      </Breadcrumb>
    </div>
  );
};

export default BreadcrumbExample;
