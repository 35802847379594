import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRouter';
import AppContainer from '../containers/AppContainer';
import DashboardContainer from '../containers/DashboardContainer';
import Dashboard from '../views/dashboard';
import Admin from '../views/admin';
import ApiBalance from '../views/api-balance';
import ViewContent from '../views/admin/view-content';
import Widgets from '../views/Internal library/widgets';

// App
import BusinessProfile1 from '../views/business-profile-copy/';
import LoanApply from '../views/loans/apply';
import LoanView from '../views/loans/view';
import LoanOffers from '../views/loans/offers';
import DocumentStatus from '../views/smes/DocumentStatus';

// ui elements
import Buttons from '../views/Internal library/ui/buttons';
import Typography from '../views/Internal library/ui/typography';
import Cards from '../views/Internal library/ui/cards';
import Modals from '../views/Internal library/ui/modals';
import Notification from '../views/Internal library/ui/notification';
import Extras from '../views/Internal library/ui/extras';

// forms
import FormGeneral from '../views/Internal library/forms/general';
import FormAdvanced from '../views/Internal library/forms/advanced';

// charts
import Charts from '../views/Internal library/charts';

// tables
import Tables from '../views/Internal library/tables';

// pages
import Login from '../views/smes/login';
import Register from '../views/smes/register';
import ForgetPass from '../views/smes/forget';
import ChangePassword from '../views/smes/change-password';
import Page404 from '../views/smes/404';
import Profile from '../views/smes/Profile';
import PublicProfile from '../views/smes/PublicProfile';
import Walkthrough from '../views/Internal library/pages/walkthrough';
import Clean from '../views/Internal library/pages/clean';
import Singleview from '../views/loans/view/loanview';

const Routes = ({ auth, role }) => ( //NOSONAR
    <div>
        <Switch>
            <PrivateRoute auth={auth} path='/home' render={() => (
                <div>
                    <DashboardContainer>
                        <Switch>
                            <Route exact path='/home' component={Dashboard} />
                            <Route path='/home/loan/apply' component={LoanApply} />
                            <Route path='/home/loan/view' component={LoanView} />
                            <Route path='/home/loan/singleview/:loanId' component={Singleview} />
                            <Route path='/home/loan/offers/:loanId' component={LoanOffers} />
                            <Route path='/home/business-profile/:provider' component={BusinessProfile1} />
                            <Route path='/home/business-profile' component={BusinessProfile1} />
                            <Route path='/home/documentsign/:status' component={DocumentStatus} />
                            <PrivateRoute auth={role === 'Administrator'} path='/home/admin/profile:profileId' component={ViewContent} />
                            <PrivateRoute auth={role === 'Administrator'} path='/home/admin' component={Admin} />
                            <PrivateRoute auth={role === 'Administrator'} path='/home/admin-apibalance' component={ApiBalance} />

                            {/* DEV Routes */}
                            <Route path='/home/clean' component={Clean} />

                            <Route path='/home/profile' component={Profile} />
                            <Route path='/home/help' render={() => <h1>Coming Soon</h1>} />

                            <Route path='/home/widgets' component={Widgets} />
                            <Route path='/home/ui/buttons' component={Buttons} />
                            <Route path='/home/ui/typography' component={Typography} />
                            <Route path='/home/ui/cards' component={Cards} />
                            <Route path='/home/ui/modals' component={Modals} />
                            <Route path='/home/ui/notification' component={Notification} />
                            <Route path='/home/ui/extras' component={Extras} />
                            <Route path='/home/forms/general' component={FormGeneral} />
                            <Route path='/home/forms/advanced' component={FormAdvanced} />
                            <Route path='/home/charts' component={Charts} />
                            <Route path='/home/tables' component={Tables} />

                            <Redirect exact from="/" to='/home' />
                        </Switch>
                    </DashboardContainer>
                </div>
            )} />

            <AppContainer>
                <Switch>
                    <Route component={Walkthrough} path='/walkthrough' />
                    <Route component={PublicProfile} path='/user/:userId' />
                    <Route component={Login} path='/login' />
                    <Route component={Register} path='/register' />
                    <Route component={ForgetPass} path='/forget' />

                    <Route component={ChangePassword} path='/change-password' />
                    <Route component={Login} exact path='/' />
                    <Route component={Page404} path='*' />
                </Switch>
            </AppContainer>
        </Switch>
    </div>
);
export default Routes;
