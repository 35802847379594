import React from 'react';
import { Progress} from '@Comp';

const ProgressBar = ({progressBar = 0,isEditable = true ,isViewable, businessProfile}) => {//NOSONAR
    return (
        <div className="view-header">
        <header className="title text-white">
    <h1 className="h4 text-uppercase">{isViewable && `${businessProfile && businessProfile.businessName}'s`} Business Profile</h1>
    <p className="mb-0">{isViewable && `${businessProfile && businessProfile.id}`}</p>
    <p className="mb-0">{isViewable && `${businessProfile && businessProfile.businessEmail}`}</p>
    {!isViewable && <>
            {
    !isEditable && <p className='text-warning'>(Your profile is being verified)</p>
    }
    {isEditable && <p>Complete Business Profile</p>}
            <div className="profileProgress fifth-step eleventh-step">
                <div className="text-center small">Business Profile Progress</div>

                <Progress multi className="mb-8 pbar">
                    <Progress bar color="b1" className="progress-bar-striped progress-bar-animated" value={progressBar}><h6>{progressBar} % Complete</h6></Progress>

                </Progress>
            </div>
            </>
}
        </header>
    </div>
    );
};

export default ProgressBar;
