import React, { Component } from 'react';
import { View } from '../../shared/components';
import { Card, Alert } from 'react-bootstrap';
import {Offers} from '../../services/requests';
import { inject, observer } from 'mobx-react';

@inject('commonStore', 'businessProfileStore')
@observer
class DocumentStatus extends Component {
    constructor() {
        super();
        this.state = {
            error: null,
            status: ''};
    }
    async componentDidMount() {
        const {commonStore}= this.props;
        commonStore.setLoading(true);
        if (this.props.match && this.props.location) {
            const { status } = this.props.match.params;
            const location = this.props.location;
            const queryParams = new URLSearchParams(location.search);
            if (queryParams.has('o_id')) {
            const response = await Offers.get(queryParams.get('o_id'));
            if (response && response.data && status === 'success') {
                await this.intervalCallback(response.data , queryParams.get('o_id'));
            }
                this.setState({ status });
            }
            this.setState({ error: 'Something went Wrong' });
        }
    }
     intervalCallback = async (offerData ,id) => {
        if (!offerData.eversign_url) {
             await new Promise(resolve => {
            const interval = setInterval( async () => {
                 offerData =  await Offers.get(id);
                if (offerData && offerData.data && offerData.data.eversign_url) {
                    offerData = offerData.data;
                    clearInterval(interval);
                     const {commonStore}= this.props;
                    commonStore.setLoading(false);
                     commonStore.addAuditTrail(
                        '5ee245ce68b78100c07f0e19',
                        offerData.smeprofile.id,
                        'loan',
                        {loandetail:offerData.loandetails.id,offer:offerData.id}
                    );
                     commonStore.addAuditTrail(
                        '5ee245ce68b78100c07f0e19',
                        offerData.pofProfile.id,
                        'offer',
                        {offer:offerData.id,loandetail:offerData.loandetails.id}
                    );
                    this.setState({ status:'success' });
                }
            }, 5000);
        });
        }else{
            const {commonStore}= this.props;
            commonStore.addAuditTrail(
               '5ee245ce68b78100c07f0e19',
               offerData.smeprofile.id,
               'loan',
               {loandetail:offerData.loandetails.id,offer:offerData.id}
           );
            commonStore.addAuditTrail(
               '5ee245ce68b78100c07f0e19',
               offerData.pofProfile.id,
               'offer',
               {offer:offerData.id,loandetail:offerData.loandetails.id}
           );
           this.setState({ status:'success' });
           commonStore.setLoading(false);
        }
    };
    render() {
        const { status, error } = this.state;
        return (
            <View>
                <Card>
                    <Card.Body>
                        <Alert variant={(status === 'failed' && 'danger') || status}>
                            <h2 className='text-center'>
                                {error}
                                {status === 'success' &&
                                    'Your loan document has been signed, you can go to your loan application to review the status/download.'}
                                {status === 'failed' &&
                                    'You have declined to sign the document .'}
                                {!status && 'Loading ...'}
                            </h2>
                        </Alert>
                        <div className='d-flex justify-content-center mt-5'>
                        </div>
                    </Card.Body>
                </Card>
            </View>
        );
    }
}

export default DocumentStatus;
