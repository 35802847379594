
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import numeral from 'numeral';
import ReactPaginate from 'react-paginate';
import _ from 'lodash'; 

import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '@Comp';
import Select from 'react-select';
import { Table, Tr, Td } from 'reactable-arc';
import Switch from 'react-toggle-switch';
import OverallStatus from './overall-status';
import BusinessProfile from '../business-profile-copy';
import ViewUploadedFiles from '../business-profile-copy/ViewUploadeFiles';


import { getTransunionData, startExternalVerification } from '../../services/requests';
import { OauthIntegrations} from '../../services/requests';
import AccountModal from '../../components/AccountsModal';
import VerifyIDModal from '../../components/VerifyIDModal';
import NotificationsTable from '../../components/NotificationsTable';

@inject('commonStore', 'viewStore', 'businessProfileStore')
@observer

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalClass: '',
      isDisabled: true,
      showAccountModal: false,
      showVerifyidModal: false,
      xeroData: null,
      sageData: null,
      activeTab: 'xero',
      companyData: null,
      directorData: null,
      auditData: null,
      activeTabVerifyid: 'companyinformation',
      yodleeData: null,
      transunionData: null,
      diaData: null,
      query: '',
      TableData : [], 
    };
    this.debouncedSearch = _.debounce(this.fetchQueryResult, 500);
  }

  handleChange = (e) => { 
    const value = e.target.value; 
    this.setState({query : value}); 
    this.debouncedSearch(value)
  }

  fetchQueryResult = async (searchTerm) => { 
    if(!searchTerm) {
      // If the search term is empty, revert to initial data
      const TableData = this.createTableData();
      this.setState({ TableData: TableData });
      return
    }
    else if(searchTerm) { 
      const {commonStore} = this.props
      const data = await commonStore.searchItem(searchTerm)
      const TableData = this.createTableData(data);
      this.setState({ TableData : TableData });
    }
  }
  

  toggleAccountModal = () => {
    this.setState({ showAccountModal: false });
  }

  toggleVerifyidModal = () => {
    this.setState({ showVerifyidModal: false })
  }

  setTab = activeTab => {
    this.setState({ activeTab });
  }

  setTabVerifyid = activeTabVerifyid => {
    this.setState({ activeTabVerifyid });
  }

  toggle = (e, str) => {
    if (this.state.modal) {
      const { businessProfileStore } = this.props;
      businessProfileStore.getUpdatedData();
    }

    this.setState({
      modal: !this.state.modal,
      modalClass: str
    });
  };

  async componentDidMount() {
    this.fetchQueryResult();
    // const TableData = this.createTableData();
    // this.setState({ TableData });
  }

  async componentWillMount() {
    const { commonStore } = this.props;
    // Refresh Admin data
    await commonStore.isAdmin();
    await commonStore.loadStaticData();
  }

  async componentDidUpdate() {
    const { viewStore, commonStore } = this.props;
    commonStore.setLoading(true);
    const viewDetail =
      viewStore.adminState && viewStore.adminState.businessProfile
        ? viewStore.adminState.businessProfile//NOSONAR
        : null;
    if (
      viewDetail &&
      (viewDetail.id || viewDetail._id) &&
      viewStore.adminState.notfications === null
    ) {
      await viewStore.getProfileNotifications(viewDetail.id || viewDetail._id);
    }
    commonStore.setLoading(false);

  }

  async componentWillUnmount () { 
    const TableData = []
    this.setState({TableData : TableData})
  }

  startDataVerification = async () => {
    const userId = this.props.viewStore.adminState.businessProfile.user._id;
    // const businessId = this.props.viewStore.adminState.businessProfile.id
    // const diaData = await OauthIntegrations.getSavedDiaDhaData(businessId);
    const { data } = await startExternalVerification(userId);
    this.setState(
      { 
        xeroData: data.xero, 
        sageData: data.sageone, 
        yodleeData: data.yodlee, 
        transunionData: data.transunion, 
        // diaData: diaData?.data?.dirSavedDiaDhaData || {}, 
        showAccountModal: true 
      }
    );
  }

  startDataVerifyid = () => {
    const { viewStore } = this.props;
    const viewDetail =
      viewStore.adminState && viewStore.adminState.businessProfile
        ? viewStore.adminState.businessProfile//NOSONAR
        : null;

    //director check
    const data = () => {
      if (viewDetail && viewDetail.verifyid) {
        return true
      } else if (viewDetail && viewDetail.verifyid && viewDetail.verifyid.Directorinformation) {
        return true
      }
      else {
        return false
      }
    }
    const result = data()

    //Auditor check 
    const audit = () => {
      if (viewDetail && viewDetail.verifyid && viewDetail.verifyid.Auditorinformation) {
        if (viewDetail && viewDetail.verifyid && (viewDetail.verifyid.Auditorinformation.Auditor_Name && viewDetail.verifyid.Auditorinformation.Last_Updated_Date && viewDetail.verifyid.Auditorinformation.SAIPA_NO) !== undefined) {
          return true;
        }
      } else {
        return false
      }
    }
    const auditResult = audit();

    this.setState({ companyData: result, directorData: result, auditData: auditResult, showVerifyidModal: true });
  }
  createTableData(data) {
    const { commonStore } = this.props;
    const businessProfiles = commonStore.adminData.businessProfiles.data;
    let tableData
    if(!data) { 
      tableData = businessProfiles?.map(profile => {
        const date = new Date(profile.createdAt);
          return { 
            pId: profile.id || profile._id,
            Name: profile.businessName,
            Email: profile.businessEmail,
            Date: date.toDateString(),
            PercentageCompleted: profile.percCompleted,
            ProfileVerified: profile.profileVerified,
            profile
          }
    })
  }else { 
    tableData = data.map(searchData => { 
      const date = new Date(searchData.createdAt);
      return { 
        pId: searchData.id || searchData._id,
        Name: searchData.businessName,
        Email: searchData.businessEmail,
        Date: date.toDateString(),
        PercentageCompleted: searchData.percCompleted,
        ProfileVerified: searchData.profileVerified,
        searchData
      }
    })
    }
    return tableData.reverse();
  }

  setProfileVerified(id, verified) {
    const { commonStore, businessProfileStore } = this.props;
    commonStore.setLoading(true);

    const result = businessProfileStore
      .setProfileVerified(id, verified)
      .then((res) => {
        // Refresh Data
        const tableData = this.state.TableData.map((item)=> { 
          if(item.pId === res.id ) { 
           return {
            ...item,
            ProfileVerified : res.profileVerified
            };
          }
          return item
        })
        this.setState({TableData: tableData})
        commonStore
          .isAdmin()
          .then(() => {
            commonStore.setLoading(false);
          })
          .catch(() => {
            commonStore.setLoading(false);
          });
      })
      .catch(() => {
        commonStore.setLoading(false);
      });   
  }

  renderLoanApplications(loansProfile,) { //NOSONAR
    if (
      loansProfile &&
      loansProfile.loanApplications &&
      loansProfile.loanApplications.length > 0
    ) {
      const TableData = [];

      loansProfile.loanApplications.forEach(application => {
        const appl = { ...application };
        if (appl.offers.length > 0) {
          if (appl.offerStatus === 'accepted') {
            const accepted = appl.offers.find(offer => offer.id === appl.acceptedOffer);
            appl.status = accepted.status;
          } else {
            const awaiting = appl.offers.find(offer => offer.status.id === '5ec3dfc47db6ab2ca11d07b6' || offer.status.id === '5d9d9fcb875e3cbf48258855');
            appl.status = awaiting.status;
          }
        }
        TableData.push({
          Id: appl.id || appl._id,
          DateSubmitted: new Date(appl.dateSubmitted).toDateString(),
          amount: appl.loanAmount,
          buyer: appl.buyer,
          color: appl.status && appl.status.color ? appl.status.color : '',//NOSONAR
          status: appl.status && appl.status.name ? appl.status.name : '',//NOSONAR
          appl
        });
      });

      return (
        <Card>
          <Table
            className="table"
            sortable={true}
            // itemsPerPage={10}
            // pageButtonLimit={10}
          >
            {TableData.map((item, idx) => (
              <Tr key={idx} className="tableRow">
                <Td key={1} column="Buyer" className="tableCol">
                  <div onClick={() => this.showLoanSingle(item)}>
                    {item.buyer}
                  </div>
                </Td>
                <Td key={2} column="Date Submitted">
                  {item.DateSubmitted}
                </Td>
                <Td key={2} column="Loan Amount">
                  R{numeral(item.amount).format('0,0')}
                </Td>
                <Td key={5} column="Status">
                  <span
                    className="badge badge-pill"
                    style={{ backgroundColor: item.color, color: '#fff' }}
                  >
                    <b className="badgeColorCorrect">{item.status}</b>
                  </span>
                </Td>
              </Tr>
            ))}
          </Table>
        </Card>
      );
    } else {
      return <h2>No Loan Applications found</h2>;
    }
  }

  showLoanSingle = item => {
    const { viewStore } = this.props;
    viewStore.setAdminState({ loanAppl: item.appl });
    this.isDisabledOption();
  };
  async setVerified(type, id, verified) {
    const { commonStore, businessProfileStore, viewStore } = this.props;
    const profile = viewStore.adminState.businessProfile;
    commonStore.setLoading(true);

    switch (type) {
      case 'companyDetails':
        await businessProfileStore.setCompanyDetailsVerified(id, verified);
        profile.companyDetails.verified = verified;
        break;
      case 'pastInvoices':
        await businessProfileStore.setPastInvoicesVerified(id, verified);
        profile.pastInvoices.verified = verified;
        break;
      case 'businessFinancials':
        await businessProfileStore.setBusinessFinancialsVerified(id, verified);
        profile.businessFinancials.verified = verified;
        break;
      case 'loansProfile':
        await businessProfileStore.setLoansProfileVerified(id, verified);
        profile.loansProfile.verified = verified;
        break;
      default:
        break;
    }

    viewStore.setAdminState({ businessProfile: profile });
    // Update View
    this.isDisabledOption();
    commonStore.setLoading(false);
  }

  renderNotificationsTable(data) {
    if (data && data.length > 0) {
      return <NotificationsTable data={data} noref={true} />;
    } else {
      return null;
    }
  }
  isDisabledOption = () => {//NOSONAR
    const { viewStore } = this.props;
    if (viewStore.adminState && viewStore.adminState.businessProfile) {
      const companyVerified =
        viewStore.adminState.businessProfile.companyDetails['verified'] ||
        false;
      const pastInvoicesVerified =
        viewStore.adminState.businessProfile.pastInvoices['verified'] || false;
      const businessFinancialsVerified =
        viewStore.adminState.businessProfile.businessFinancials['verified'] ||
        false;
      const loansProfilefied =
        viewStore.adminState.businessProfile.loansProfile['verified'] || false;
      if (
        companyVerified &&
        pastInvoicesVerified &&
        businessFinancialsVerified &&
        loansProfilefied
      ) {
        this.setState({ isDisabled: false });
        return true;
      }
    }
    if (!this.state.isDisabled) {
      this.setState({ isDisabled: true });
    }
    return false;
  };

  Pagination = ({count, pageSize, page}) => {
    const { commonStore } = this.props;
    
    const pageCount = Math.ceil(count / 10);

    const handlePageClick = async ({selected}) => {
      const offSet = selected * 10;
      const query = `_limit=10&_start=${offSet}`;
      await commonStore.getAdminData(query, true);
      const tableData = await this.createTableData()
      this.setState({TableData: tableData})
    };

    return (
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="< prev"
        renderOnZeroPageCount={null}
        initialPage={0}
        className="pagination"
        activeClassName="active"
        previousClassName="prev"
        nextClassName="next"
        breakClassName="break"
        pageClassName="page"
        disabledClassName="disabled"
      />
    );
};


  render() {//NOSONAR
    const {TableData} = this.state; 
    const { commonStore, viewStore, businessProfileStore } = this.props;
    const businessProfiles = commonStore.adminData.businessProfiles.data;

    const viewDetail =
      viewStore.adminState && viewStore.adminState.businessProfile
        ? viewStore.adminState.businessProfile//NOSONAR
        : null;
    const loanAppl = viewStore.adminState.loanAppl || null;

    const selectOptions =
      commonStore.statusus && commonStore.statusus.length > 0
        ? commonStore.statusus.map(m => {//NOSONAR
          return { value: m.id || m._id, label: m.name };
        })
        : [];

    if (businessProfiles && businessProfiles.length > 0) {
      const businessProfileDetails = this.props.commonStore.adminData.businessProfiles.details;
      const noOfBusinessProfiles = this.props.commonStore.adminData.businessProfiles.details.count;
      const noOfBusinessProfilesComplete = businessProfiles.filter(
        f => f.complete
      ).length;
      const noOfLoanApplications = 0; // TODO
      const noOfLoanApplicationsComplete = 0; // TODO
      if(this.state.TableData.length===0) { 
        const TableData = this.createTableData(); 
        this.setState({TableData});
      }
      return (
        <>
          <div className="view-content view-dashboard">
            {viewDetail ? null : (
              <OverallStatus
                noOfBusinessProfiles={noOfBusinessProfiles}
                noOfBusinessProfilesComplete={noOfBusinessProfilesComplete}
                noOfLoanApplications={noOfLoanApplications}
                noOfLoanApplicationsComplete={noOfLoanApplicationsComplete}
              />
            )}
            {viewDetail ? (
              <Row>
                <Col xs="4">
                  <Card>
                    <CardBody>
                      <CardTitle className="text-uppercase h6">
                        Business Profile - <b>{viewDetail.businessName}</b>
                      </CardTitle>
                      <Row className="detailRow">
                        <Col xs="8">
                          <Label>
                            {viewDetail.isEditable && 'Start Verification'}
                            {!viewDetail.isEditable && 'Stop Verification'}:
                            </Label>
                        </Col>
                        <Col xs="4">
                          <Switch
                            onClick={() =>
                              businessProfileStore.toggleProfileEditable(
                                viewDetail._id,
                                !viewDetail.isEditable
                              )
                            }
                            className="profileSwitch"
                            on={viewDetail.isEditable ? false : true}//NOSONAR
                          />
                        </Col>
                      </Row>
                      {viewDetail.companyDetails &&
                        viewDetail.companyDetails._id && (
                          <Row className="detailRow">
                            <Col xs="8">
                              <Label>Company Details Verified:</Label>
                            </Col>
                            <Col xs="4">
                              <Switch
                                onClick={() =>
                                  this.setVerified(
                                    'companyDetails',
                                    viewDetail.companyDetails.id ||
                                    viewDetail.companyDetails._id,
                                    !viewDetail.companyDetails.verified
                                  )
                                }
                                className="profileSwitch"
                                on={viewDetail.companyDetails.verified || false}
                              />
                            </Col>
                          </Row>
                        )}
                      {viewDetail.pastInvoices && viewDetail.pastInvoices._id && (
                        <Row className="detailRow">
                          <Col xs="8">
                            <Label>Past Invoices Verified:</Label>
                          </Col>
                          <Col xs="4">
                            <Switch
                              onClick={() =>
                                this.setVerified(
                                  'pastInvoices',
                                  viewDetail.pastInvoices.id ||
                                  viewDetail.pastInvoices._id,
                                  !viewDetail.pastInvoices.verified
                                )}
                              className="profileSwitch"
                              on={viewDetail.pastInvoices.verified || false}
                            />
                          </Col>
                        </Row>
                      )}
                      {viewDetail.businessFinancials &&
                        viewDetail.businessFinancials._id && (
                          <Row className="detailRow">
                            <Col xs="8">
                              <Label>Business Financials Verified:</Label>
                            </Col>
                            <Col xs="4">
                              <Switch
                                onClick={() =>
                                  this.setVerified(
                                    'businessFinancials',
                                    viewDetail.businessFinancials.id ||
                                    viewDetail.businessFinancials._id,
                                    !viewDetail.businessFinancials.verified
                                  )
                                }
                                className="profileSwitch"
                                on={viewDetail.businessFinancials.verified || false}
                              />
                            </Col>
                          </Row>
                        )}
                      {viewDetail.loansProfile && viewDetail.loansProfile._id && (
                        <Row className="detailRow">
                          <Col xs="8">
                            <Label>Loans Profile Verified:</Label>
                          </Col>
                          <Col xs="4">
                            <Switch
                              onClick={() =>
                                this.setVerified(
                                  'loansProfile',
                                  viewDetail.loansProfile.id ||
                                  viewDetail.loansProfile._id,
                                  !viewDetail.loansProfile.verified
                                )
                              }
                              className="profileSwitch"
                              on={viewDetail.loansProfile.verified || false}
                            />
                          </Col>
                        </Row>
                      )}
                      <Row className="detailRow">
                        <Col xs="6">
                          <Button
                            color="danger"
                            outline
                            onClick={() => {
                              viewStore.setAdminState({
                                businessProfile: null,
                                notfications: null,
                                loanAppl: null
                              });
                            }}
                          >
                            Close
                            </Button>
                        </Col>
                        <Col xs="6">
                          <Button
                            color="primary"
                            outline
                            onClick={e => this.toggle(e, 'modalFadeInScale')}
                          >
                            Open Profile
                            </Button>

                          <div className="float-right text-sm-left">
                          </div>
                        </Col>
                      </Row>
                      <Row className="detailRow">
                        <Col xs="12" className="text-center">
                          <Button className="btn btn-primary btn-lg" onClick={this.startDataVerifyid}>Verifyid</Button>
                        </Col>
                        <Col xs="12" className="text-center">
                          <Button className="btn btn-primary btn-lg" onClick={this.startDataVerification}>Start external verification</Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                {loanAppl && (
                  <Col xs="8">
                    <Card>
                      <CardBody>
                        <CardTitle className="text-uppercase h6">
                          Loan Application -{' '}
                          <b>
                            {loanAppl.buyer} (
                                <span style={{ textTransform: 'lowercase' }}>
                              {loanAppl.id || loanAppl._id}
                            </span>
                                                    )
                                                </b>
                        </CardTitle>
                        <Row className="detailRow">
                          <Col xs="6">
                            <Label>Loan Amount:</Label>
                          </Col>
                          <Col xs="6">
                            <b>R {numeral(loanAppl.loanAmount).format('0,0')}</b>
                          </Col>
                        </Row>
                        <Row className="detailRow">
                          <Col xs="6">
                            <Label>Loan Percent:</Label>
                          </Col>
                          <Col xs="6">
                            <b>{loanAppl.loanPercent}%</b>
                          </Col>
                        </Row>
                        <Row className="detailRow">
                          <Col xs="6">
                            <Label>Loan Status:</Label>
                          </Col>
                          <Col xs="6">
                            {selectOptions && selectOptions.length > 0 ? (//NOSONAR
                              <Select
                                options={selectOptions}
                                isDisabled={this.state.isDisabled}
                                value={
                                  loanAppl && loanAppl.status
                                  && {
                                    value:
                                      loanAppl.status.id ||
                                      loanAppl.status._id,
                                    label: loanAppl.status.smeTitle || loanAppl.status.name
                                  }}
                                onChange={newStatusId =>
                                  //console.log('[newStatusId]', newStatusId.value)
                                  businessProfileStore.setLoansApplicationStatus(
                                    loanAppl.id || loanAppl._id,
                                    newStatusId.value
                                  )
                                }
                              />
                            ) : (
                                <h2>No Status loaded</h2>
                              )}
                          </Col>
                        </Row>
                        <Row className="detailRow">
                          <Col xs="6">
                            <Label>Total Repayable:</Label>
                          </Col>
                          <Col xs="6">
                            <b>
                              R{numeral(loanAppl.totalRepayable).format('0,0')}
                            </b>
                          </Col>
                        </Row>
                        <Row className="detailRow">
                          <Col xs="6">
                            <Label>Monthly Repayable:</Label>
                          </Col>
                          <Col xs="6">
                            <b>
                              R{numeral(loanAppl.monthlyRepayable).format('0,0')}
                            </b>
                          </Col>
                        </Row>
                        <Row className="detailRow">
                          <Col xs="6">
                            <Label>Interest:</Label>
                          </Col>
                          <Col xs="6">
                            <b>{loanAppl.interest}</b>
                          </Col>
                        </Row>
                        <Row className="detailRow">
                          <Col xs="6">
                            <Label>Date Submitted:</Label>
                          </Col>
                          <Col xs="6">
                            <b>
                              {new Date(loanAppl.dateSubmitted).toDateString()}
                            </b>
                          </Col>
                        </Row>
                        <Row className="detailRow">
                          <Col xs="6">
                            <Label>Date Awarded:</Label>
                          </Col>
                          <Col xs="6">
                            <b>{new Date(loanAppl.dateAwarded).toDateString()}</b>
                          </Col>
                        </Row>
                        <Row className="detailRow">
                          <Col xs="6">
                            <Label>Invoice Amount:</Label>
                          </Col>
                          <Col xs="6">
                            <b>
                              R{numeral(loanAppl.invoiceAmount).format('0,0')}
                            </b>
                          </Col>
                        </Row>
                        <Row className="detailRow">
                          <Col xs="6">
                            <Label>Invoice Due Date:</Label>
                          </Col>
                          <Col xs="6">
                            <b>
                              {new Date(loanAppl.invoiceDueDate).toDateString()}
                            </b>
                          </Col>
                        </Row>
                        <Row className="detailRow">
                          <Col xs="6">
                            <Label>Total Paid Back:</Label>
                          </Col>
                          <Col xs="6">
                            <b>
                              R{numeral(loanAppl.totalPaidBack).format('0,0')}
                            </b>
                          </Col>
                        </Row>
                        <Row className="detailRow">
                          <Col xs="6">
                            <Label>Uploaded Invoices:</Label>
                          </Col>
                          <Col xs="6">
                            {loanAppl.invoice && (
                              <ViewUploadedFiles
                                title="Uploaded Inoice"
                                data={[loanAppl.invoice]}
                              />
                            )}
                          </Col>
                        </Row>
                        <Row className="detailRow">
                          <Col xs="12">
                            <Button
                              color="info"
                              outline
                              onClick={() =>
                                viewStore.setAdminState({ loanAppl: null })
                              }
                            >
                              Back
                                                    </Button>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                )} {!loanAppl && (
                  <Col xs="8">
                    <Card>
                      <CardBody>
                        <CardTitle className="text-uppercase h6">
                          Loan Applications
                                            </CardTitle>
                        {this.renderLoanApplications(viewDetail.loansProfile)}
                      </CardBody>
                    </Card>
                  </Col>
                )}
                {viewStore.adminState.notfications &&
                  viewStore.adminState.notfications.length > 0 && (
                    <Col xs="8">
                      <Card>
                        <CardBody>
                          <CardTitle className="text-uppercase h6">
                            Audit Trail
                                            </CardTitle>
                          {this.renderNotificationsTable(
                            viewStore.adminState.notfications
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  )}
                <div className="modalContainer">
                  <Modal
                    isOpen={this.state.modal}
                    size="xl"
                    toggle={this.toggle}
                    className={this.state.modalClass}
                  >
                    <ModalHeader toggle={this.toggle}>
                      Business Profile
                                    </ModalHeader>
                    <ModalBody>
                      {
                                            /*this.state.renderChild ?*/ <BusinessProfile
                          id={viewDetail.id}
                        /> /*: null */
                      }
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={this.toggle}>
                        Close
                        </Button>
                    </ModalFooter>
                  </Modal>
                </div>
              </Row>
            ) : (
              <>
                <Row>
                  {/* All Business Profiles */}
                  <Col xs="12">
                    <Card>
                      <CardBody className="table-responsive">
                        <h6 className="mb-4 text-uppercase">
                          Business Profiles [{noOfBusinessProfiles}]
                        </h6>
                        <input 
                            className='border border-secondary mb-4 p-2 float-right' 
                            placeholder='search business profile' 
                            onChange={this.handleChange}
                            value={this.state.query}
                            type='text'
                        /> 
                        <Table
                          className="table"
                          // filterable={['Name', 'email']}
                          sortable={true}
                        >
                          {/* <Thead>
                                            <Th column="date">Date</Th>
                                            <Th column="name">Business Name</Th>
                                            <Th column="email">Business Email</Th>
                                            <Th column="percCompleted">Completed (%)</Th>
                                            <Th column="profileVerified">Profile Verified</Th>
                              </Thead> */}
                          {TableData?.map((item, idx) => (
                            (this.state.TableData.length >0 ? 
                              <Tr key={idx} className="tableRow">
                              <Td key={1} column="Name" className="tableCol">
                                <div
                                  onClick={() =>
                                    viewStore.setAdminState({
                                      businessProfile: item.profile
                                    })
                                  }
                                >
                                  {item.Name}
                                </div>
                              </Td>
                              <Td key={2} column="email">
                                {item.Email}
                              </Td>
                              <Td key={3} column="Date">
                                {item.Date}
                              </Td>
                              <Td key={4} column="PercentageCompleted">
                                {item.PercentageCompleted}
                              </Td>
                              <Td key={5} column="ProfileVerified">
                                <Switch
                                  onClick={() =>
                                    this.setProfileVerified(
                                      item.pId,
                                      !item.ProfileVerified
                                    )
                                  }
                                  className="profileSwitch"
                                  on={item.ProfileVerified}
                                />
                              </Td>
                            </Tr>
                              : 
                            <Tr>
                              <div> No Result found....!! </div>
                            </Tr>
                            )
                            
                          ))}
                        </Table>
                      </CardBody>
                    </Card>
                  </Col>
                  {/* <Col xs='6'>
                            {/* Business Profiles *
                            <Card>
                                <CardBody className="table-responsive">
                                    <CardTitle className="text-uppercase h6">Business Profiles</CardTitle>
                                    <BusinessProfilesTable data={businessProfiles}/>
                                </CardBody>
                            </Card>
                        </Col> */}
                </Row>
                {this.Pagination(businessProfileDetails)}
              </>
              )}
          </div>
          <VerifyIDModal
            showVerifyidModal={this.state.showVerifyidModal}
            toggleVerifyidModal={this.toggleVerifyidModal}
            modalClass={''}
            activeTabVerifyid={this.state.activeTabVerifyid}
            setTabVerifyid={this.setTabVerifyid}
            companyData={this.state.companyData}
            directorData={this.state.directorData}
            auditData={this.state.auditData}
          >
          </VerifyIDModal>
          <AccountModal
            businessProfile={this.props.viewStore.adminState.businessProfile}
            businessProfileStore={this.props.businessProfileStore}
            showAccountModal={this.state.showAccountModal}
            toggleAccountModal={this.toggleAccountModal}
            modalClass={''}
            activeTab={this.state.activeTab}
            setTab={this.setTab}
            xeroData={this.state.xeroData}
            sageData={this.state.sageData}
            yodleeData={this.state.yodleeData}
            transunionData={this.state.transunionData}
            diaData={this.state.diaData}
          ></AccountModal>
        </>
      );
    } else {
      return (
        <div className="view-content view-dashboard">
          <h1>No Data</h1>
        </div>
      );
    }
  }
}

export default HomePage;
