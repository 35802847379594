import React , {Component} from 'react';
import {inject, observer} from 'mobx-react';
@inject('businessProfileStore')
@observer
class IncomeStatement extends Component  {
     handleChange = e => {
        const  {incomeStatement ,fieldsToUpdate} = this.props.businessProfileStore;
        const {value , name} = e.target;
        incomeStatement[name] = value;
        fieldsToUpdate.annualIncomeStatementDirty = true;
        this.props.formDataChange(false);
    }
    render(){
        const  {incomeStatement} = this.props.businessProfileStore;

    return (
        <section>
        <form className="m-t-15">
            <div className="form-group row">
                <label className="col-sm-2 col-form-label control-label text-sm-right">Turnover</label>
                <div className="col-sm-10">
                    <input
                        id="turnOver"
                        name="turnOver"
                        type="number"
                        className="form-control incomeStatement"
                        placeholder="R"
                        value={incomeStatement.turnOver}
                        onChange={this.handleChange}
                    />
                </div>
            </div>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label control-label text-sm-right">Cost of Sales</label>
                <div className="col-sm-10">
                    <input
                        id="costOfSales"
                        name="costOfSales"
                        type="number"
                        className="form-control
                        incomeStatement"
                        placeholder="R"
                        value={incomeStatement.costOfSales}
                        onChange={this.handleChange}

                    />
                </div>
            </div>
            <div className="form-group row">
                <label className="col-sm-2 col-form-label control-label text-sm-right">Net Profit After Tax</label>
                <div className="col-sm-10">
                    <input
                        id="netProfitAfterTax"
                        name="netProfitAfterTax"
                        type="number"
                        className="form-control incomeStatement"
                        placeholder="R"
                        value={incomeStatement.netProfitAfterTax}
                        onChange={this.handleChange}
                    />
                </div>
            </div>
        </form>
    </section>
    );
}
}
export default IncomeStatement;
