// All Assets

const images = {
    // Icons
    menu: require('./icons/Menu.png'),
    menu_white: require('./icons/Menu_w.png'),
    back: require('./icons/Back.png'),
    back_white: require('./icons/Back_w.png'),
    //app: require('../icons/app.jpg'),
    app: require('./icons/app.png'),
    loading: require('./icons/loading.png'),
    loginIcon: require('./images/logo/logo-white.png'),

    // Images
    img1: require('./images/others/img-1.jpg'),
    img2: require('./images/others/img-2.jpg'),
    img3: require('./images/others/img-3.jpg'),
    img4: require('./images/others/img-4.jpg'),

    NisaFinLogo: require('./images/logo/NISA_FINANCE.png'),
    NisaFinLogoTransparent: require('./images/logo/NISA_FINANCE_Trans - Copy.png'),
    xeroLogo: require('./images/logo/xero.svg'),
    yodleeLogo: require('./images/logo/yodlee.png'),
    sageoneLogo: require('./images/logo/sageone.svg'),
    verifyidLogo: require('./images/logo/verifyid.png'),
    diaLogo: require('./images/logo/DIA.png'),
    nisaDoclockLogo: require('./images/logo/NisaDoclock.png')
};

export default images;