import React from 'react';
import {Router} from 'react-router-dom';
import { inject, observer } from 'mobx-react';
// Dep: import createHistory from 'history/createBrowserHistory';
import { createBrowserHistory } from 'history';
import Toast from './components/Toast';
import Routes from './routes';
export const history = createBrowserHistory(); //createHistory();

@inject('commonStore')
@observer
export default class AppContainer extends React.Component {
    componentDidMount(){
        this.props.commonStore.isAdmin(); // Just checking on every load, make sure!
    }
    setShow = (bool) => {
        this.props.commonStore.setMsg('',bool);
    }
    render() {
        let { appLoaded, auth, user,isShow ,smsg} = this.props.commonStore;
        if(appLoaded){
            return (
                <Router history={history}>
                    <Toast isShow={isShow} msg={smsg} setShow={this.setShow}/>
                    <Routes auth={auth} role={(user && user.role && user.role.name)?user.role.name:''}/>     
                </Router>
            );
        } else {
            return null;
        }
    }
}
