import React from 'react';
import { Header } from '@Nisa';


let desc = () => {
    return <div>Review All Credit Balances For Your APIs Calls</div>
};

export default () => (
    <Header
        title={'Admin Api Balance'}
        description={desc()}
    />
);
