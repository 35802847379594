const IS_REQUIRED = 'is required';
const businessAddressSchema = {
  unitNo: {
    presence: { allowEmpty: false, message: IS_REQUIRED }
  },
  streetName: {
    presence: { allowEmpty: false, message: IS_REQUIRED }
  },
  suburb: {
    presence: { allowEmpty: false, message: IS_REQUIRED }
  },
  city: {
    presence: { allowEmpty: false, message: IS_REQUIRED }
  },
  province: {
    presence: { allowEmpty: false, message: IS_REQUIRED }
  },
  postalCode: {
    presence: { allowEmpty: false, message: IS_REQUIRED }
  },
  description: {
    presence: { allowEmpty: false, message: IS_REQUIRED },
    length: {
      maximum: 250,
    }
  }
};

const accountantSchema = {
  saipaNo: {
    presence: { allowEmpty: false, message: IS_REQUIRED }
  },
  idNo: {
    presence: { allowEmpty: false, message: IS_REQUIRED },
    length: {
      maximum: 12,
      minimum: 10
    }
  },
  email: {
    presence: { allowEmpty: false, message: IS_REQUIRED },
    email: true,
    length: {
      maximum: 64
    }
  }
};
const loanFormSchema = {
  invoiceAmount: {
    presence: { allowEmpty: false, message: IS_REQUIRED }
  },
  invoiceBuyer: {
    presence: { allowEmpty: false, message: IS_REQUIRED }
  },
  contactPerson: {
    presence: { allowEmpty: false, message: IS_REQUIRED },
    length: {
      maximum: 10,
      minimum: 10
    }
  },
  buyerEmail: {
    presence: { allowEmpty: false, message: IS_REQUIRED },
    email: true,
    length: {
      maximum: 64
    }
  },
  loanDescription: {
    presence: { allowEmpty: false, message: IS_REQUIRED }
  }
};
export { businessAddressSchema, accountantSchema, loanFormSchema };
