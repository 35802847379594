import React, { Component } from 'react';
import {
    Col,
    Container,
    Row,
    FormGroup,
    Form,
    Label,
    Tooltip
} from '@Comp';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InputRange from 'react-input-range';
import validate from 'validate.js';
import { loanFormSchema } from '../../business-profile-copy/ValidationSchemas';
import { TextField, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

class InputSlider extends Component {
    state = {
        value: this.props.loanPercent
    };
    render() {
        const { loanPercent, handleLoanPercent } = this.props;
        return (
            <div>
                <Row>
                    <Col xs="3">
                        <p>INVOICE PERCENT FOR LOAN</p>
                    </Col>
                    <Col xs="9">
                        <InputRange
                            maxValue={90}
                            minValue={20}
                            value={loanPercent}
                            name="loanPercent"
                            onChange={value => handleLoanPercent(value)}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}

class LoanForm extends Component {
    constructor() {
        super();
        this.state = {
            touched: '',
            showBuyerTooltip: false,
            showChainTooltip: false,
            showProcureTooltip: false
        };
        this.formState = {
            isValid: false,
            values: {},
            touched: {},
            errors: {}
        };
    }
    toggleBuyer() {
        this.setState({
            showBuyerTooltip: !this.state.showBuyerTooltip,
            showChainTooltip: this.state.showChainTooltip,
            showProcureTooltip: this.state.showProcureTooltip
        });
    }
    toggleChain = (show) => {
        this.setState({
            showBuyerTooltip: this.state.showBuyerTooltip,
            showChainTooltip: show,
            showProcureTooltip: this.state.showProcureTooltip
        });
    }
    
    toggleProcure = (show) => {
        this.setState({
            showBuyerTooltip: this.state.showBuyerTooltip,
            showChainTooltip: this.state.showChainTooltip,
            showProcureTooltip: show
        });
    }
    
    handleChange = e => {
        e.persist();
        const { checked, value, type } = e.target;
        const { handleChange } = this.props;
        let name = value;
        if (type === 'checkbox') {
            name = checked;
        }
        this.formState = {
            ...this.formState,
            values: {
                ...this.formState.values,
                [e.target.name]: name
            },
            touched: {
                ...this.formState.touched,
                [e.target.name]: true
            }
        };
        const errors = validate(this.formState.values, loanFormSchema);
        this.formState = {
            ...this.formState,
            isValid: errors ? false : true,//NOSONAR
            errors: errors || {}
        };
        this.setState({ touched: value });
        handleChange(e);
    }

    hasError = field => {
        let bool = false;
        if (this.formState.touched[field] && this.formState.errors[field]) {
            bool = true;
        }
        return bool;
    };
    checkError = field => {
        const hasErrorf = this.hasError(field);
        if (hasErrorf) {
            return this.formState.errors[field][0];
        }
        return null;
    };
    render() {
        const {
            invoiceType,
            invoiceAmount,
            startDate,
            invoiceBuyer,
            contactPerson,
            buyerEmail,
            loanDescription,
            buyerRelationship,
            loanPercent,
            handleLoanPercent,
            handleDateChange,
        } = this.props;
        return (
            <div>
                <Container>
                    <Form>
                        <Row className="mt-4 mb-4">
                            <Col xs="6">
                                <Label>
                                    <strong>
                                        Please select the loan type you are requesting:
                                    </strong>
                                </Label>
                            </Col>
                            <Col>
                                <FormGroup tag="fieldset">
                                    <Row>
                                        <RadioGroup row aria-label="invoiceType" name="invoiceType" value={invoiceType} onChange={this.handleChange}>
                                            <FormControlLabel value="Purchase Order" control={<Radio color="primary" />} label="Purchase Order" />
                                            <FormControlLabel value="Invoice" control={<Radio color="primary" />} label="Invoice" />
                                        </RadioGroup>
                                    </Row>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row className="mt-4 mb-4">
                            <Col xs="3">
                                <Label>INVOICE AMOUNT</Label>
                            </Col>
                            <Col xs="9">
                                <TextField
                                    type="number"
                                    placeholder="Please enter the amount in Rands"
                                    name="invoiceAmount"
                                    value={invoiceAmount}
                                    className='w-100'
                                    onChange={this.handleChange}
                                    error={this.hasError('invoiceAmount')}
                                    helperText={
                                        this.checkError('invoiceAmount')
                                    }
                                />
                            </Col>
                        </Row>

                        <Row className="mt-4 mb-4">
                            <Col xs="3">
                                <Label>INVOICE DUE DATE</Label>
                            </Col>
                            <Col xs="9">
                                <DatePicker
                                    className="datePicker"
                                    selected={startDate}
                                    name="date"
                                    onChange={handleDateChange}
                                />
                            </Col>
                        </Row>

                        <Row className="mt-4 mb-4">
                            <Col>
                                <Row>
                                    <Col xs="3">
                                        <Label
                                            id="buyerLabel"
                                            onMouseOver={this.toggleBuyer.bind(this)}
                                            onMouseLeave={this.toggleBuyer.bind(this)}
                                        >
                                            BUYER <span>&#8505;</span>
                                        </Label>
                                        <Tooltip
                                            placement="top"
                                            isOpen={this.state.showBuyerTooltip}
                                            target="buyerLabel"
                                        >
                                            This is the company the purchase order was issued by or
                                            invoice is due to
                                        </Tooltip>
                                    </Col>
                                    <Col xs="9">
                                        <TextField
                                            type="text"
                                            placeholder="Please provide the name of the buyer"
                                            name="invoiceBuyer"
                                            value={invoiceBuyer}
                                            className='w-100'
                                            onChange={this.handleChange}
                                            error={this.hasError('invoiceBuyer')}
                                            helperText={
                                                this.checkError('invoiceBuyer')
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-4 mb-4">
                                    <Col xs="3">
                                        <Label>BUYER TELEPHONE NUMBER</Label>
                                    </Col>
                                    <Col xs="9">
                                        <TextField
                                            fullWidth
                                            multiline
                                            minRows={!contactPerson ? 2 : 1}
                                            type="text"
                                            placeholder="Please provide the telephone number to the buyer's procurement office"
                                            name="contactPerson"
                                            value={contactPerson}
                                            onChange={this.handleChange}
                                            error={this.hasError('contactPerson')}
                                            helperText={
                                                this.checkError('contactPerson')
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-4 mb-4">
                                    <Col xs="3">
                                        <Label>BUYER EMAIL</Label>
                                    </Col>
                                    <Col xs="9">
                                        <TextField
                                            type="text"
                                            placeholder="Please provide the email address to the buyer's procurement office.in the input field"
                                            name="buyerEmail"
                                            value={buyerEmail}
                                            fullWidth
                                            multiline
                                            minRows={!buyerEmail ? 2 : 1}
                                            onChange={this.handleChange}
                                            error={this.hasError('buyerEmail')}
                                            helperText={
                                                this.checkError('buyerEmail')
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-4 mb-4">
                                    <Col xs="3">
                                        <Label>LOAN DESCRIPTION </Label>
                                    </Col>
                                    <Col xs="9">
                                        <TextField
                                            type="text"
                                            placeholder="Please describe what the loan will be used for"
                                            name="loanDescription"
                                            value={loanDescription}
                                            className='w-100'
                                            multiline
                                            onChange={this.handleChange}
                                            error={this.hasError('loanDescription')}
                                            helperText={
                                                this.checkError('loanDescription')
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-4 mb-4">
                                    <Col xs="3">
                                    </Col>
                                    <Col xs="9">
                                    <FormGroup tag="fieldset">
                                        <Row>
                                            <RadioGroup
                                                row
                                                aria-label="buyerRelationship"
                                                name="buyerRelationship"
                                                value={buyerRelationship}
                                                onChange={this.handleChange}
                                            >
                                                <FormControlLabel
                                                    value="supplyChain"
                                                    control={<Radio color="primary" />}
                                                    label="Part of supply chain"
                                                    onMouseOver={() => this.toggleChain(true)}
                                                    onMouseLeave={() => this.toggleChain(false)}
                                                    id="chainLabel"
                                                />
                                                <FormControlLabel
                                                    value="procurement"
                                                    control={<Radio color="primary" />}
                                                    label="Procurement of goods/services"
                                                    id="procureLabel"
                                                    onMouseOver={() => this.toggleProcure(true)}
                                                    onMouseLeave={() => this.toggleProcure(false)}
                                                />
                                            </RadioGroup>
                                            <Tooltip
                                                placement="top"
                                                isOpen={this.state.showChainTooltip}
                                                target="chainLabel"
                                            >
                                                The invoice is for goods that form part of the core
                                                supply chain for the buyer.
                                            </Tooltip>
                                            <Tooltip
                                                placement="top"
                                                isOpen={this.state.showProcureTooltip}
                                                target="procureLabel"
                                            >
                                                The invoice is for goods and services procured that
                                                do not form part of the core supply chain for the
                                                buyer
                                            </Tooltip>
                                        </Row>
                                    </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="mt-8 mb-4">
                            <Col>
                                <InputSlider
                                    loanPercent={loanPercent}
                                    handleLoanPercent={handleLoanPercent}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Container>
            </div>
        );
    }
}

export default LoanForm;
