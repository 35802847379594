import React, { useState } from "react";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "@Comp";

import YodleeModalData from "./YodleeModalData";
import SageModalData from "./SageModalData";
import TransunionModalData from "./TransunionModalData";
import DIAModalData from "./complex/DIAModalData";

const AccountModal = ({
  businessProfile,
  businessProfileStore,
  showAccountModal,
  toggleAccountModal,
  modalClass,
  activeTab,
  setTab,
  xeroData,
  sageData,
  yodleeData,
  transunionData,
  diaData
}) => {
  const [collapse, setCollapse] = useState("");
  const toggleCollapse = (key) => {
    if (key === collapse) {
      setCollapse("");
    } else {
      setCollapse(key);
    }
  };

  const formatDate = (str) => {
    const pattern = /^\/Date\((.*)\)\/$/;
    if (pattern.test(str)) {
      return str.replace(
        pattern,
        (match, match1) => new Date(parseInt(match1, 10))
      );
    }
  };

  const getActiveTabData = () => {
    switch (activeTab) {
      case "yodlee":
        return <YodleeModalData data={yodleeData}></YodleeModalData>;
      case "transunion":
        return <TransunionModalData data={transunionData} businessProfile={businessProfile}></TransunionModalData>;
      case "dia":
        return <DIAModalData data={diaData} businessId={businessProfile.id} businessProfileStore={businessProfileStore}></DIAModalData>;
      case "sageone":
        return <SageModalData data={sageData}></SageModalData>;
      default: return;
    }
  };

  return (
    <div className="modalContainer">
      <Modal
        id={"accountModal"}
        isOpen={showAccountModal}
        size="lg"
        toggle={toggleAccountModal}
        className={modalClass}
      >
        <ModalHeader toggle={toggleAccountModal}>User Accounts</ModalHeader>
        <ModalBody>
          <>
            <div>
              <ul className="nav nav-pills">
                <li className="nav-item" onClick={() => setTab("xero")}>
                  <a
                    className={`nav-link ${
                      activeTab === "xero" ? "active" : ""
                    }`}
                    href="#"
                  >
                    Xero
                  </a>
                </li>
                <li className="nav-item" onClick={() => setTab("yodlee")}>
                  <a
                    className={`nav-link ${
                      activeTab === "yodlee" ? "active" : ""
                    }`}
                    href="#"
                  >
                    Yodlee
                  </a>
                </li>
                <li className="nav-item" onClick={() => setTab("transunion")}>
                  <a
                    className={`nav-link ${
                      activeTab === "transunion" ? "active" : ""
                    }`}
                    href="#"
                  >
                    Transunion
                  </a>
                </li>
                <li className="nav-item" onClick={() => setTab("dia")}>
                  <a
                    className={`nav-link ${
                      activeTab === "dia" ? "active" : ""
                    }`}
                    href="#"
                  >
                    Director Identity Verification
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={`nav-link ${
                      activeTab === "sageone" ? "active" : ""
                    }`}
                    href="#"
                    onClick={() => setTab("sageone")}
                  >
                    Sage One
                  </a>
                </li>
              </ul>
              {activeTab === "xero" ? (
                <div style={{ marginTop: "20px" }}>
                  {xeroData ? (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => toggleCollapse("bankTransactions")}
                      >
                        Bank Transactions
                      </button>
                      {collapse === "bankTransactions" ? (
                        <div id="bankTransactions">
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">SubTotal</th>
                                  <th scope="col">Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {xeroData.bankTransactions
                                  .slice(0, 5)
                                  .map((row, index) => (
                                    <tr key={`transaction_${index}`}>
                                      <td>{row.SubTotal}</td>
                                      <td>{row.Total}</td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ) : null}
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => toggleCollapse("invoices")}
                      >
                        Invoices
                      </button>
                      {collapse === "invoices" ? (
                        <div id="invoices">
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Due Date</th>
                                  <th scope="col">Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {xeroData.invoices
                                  .slice(0, 5)
                                  .map((row, index) => (
                                    <tr key={`invoices_${index}`}>
                                      <td>{formatDate(row.DueDate)}</td>
                                      <td>{row.Total}</td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ) : null}
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={() => toggleCollapse("purchaseorder")}
                      >
                        Purchase Orders
                      </button>
                      {collapse === "purchaseorder" ? (
                        <div id="purchaseorder">
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Sub Total</th>
                                  <th scope="col">Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {xeroData.purchaseOrders
                                  .slice(0, 5)
                                  .map((row, index) => (
                                    <tr key={`purchaseorder_${index}`}>
                                      <td>{row.SubTotal}</td>
                                      <td>{row.Total}</td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <div className="alert">No data available</div>
                  )}
                </div>
              ) : null}
              {getActiveTabData()}
            </div>
          </>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleAccountModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AccountModal;
