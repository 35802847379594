import React, { Component } from 'react';
import { Form } from '@Comp';
import { inject, observer } from 'mobx-react';

@inject('businessProfileStore')
@observer
class BalanceSheetPage extends Component {
  handleChange = e => {
    const { balanceSheet, fieldsToUpdate } = this.props.businessProfileStore;
    const { value, name } = e.target;
    balanceSheet[name] = value;
    fieldsToUpdate.annualBalanceSheetDirty = true;
    this.props.formDataChange(false);
  };
  render() {
    const { balanceSheet } = this.props.businessProfileStore;

    return (
      <section>
        <Form className="m-t-15" name="balanceSheet" id="balanceSheet">
          <div className="form-group row">
            <label className="col-sm-2 col-form-label control-label text-sm-right">
              Accounts Receivable
            </label>
            <div className="col-sm-10">
              <input
                id="accountsReceivable"
                name="accountsReceivable"
                type="number"
                className="form-control
                                balanceSheet"
                placeholder="R"
                value={balanceSheet.accountsReceivable}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label control-label text-sm-right">
              Cash and Cash Equivalents
            </label>
            <div className="col-sm-10">
              <input
                id="cashAndCashEquivalents"
                name="cashAndCashEquivalents"
                type="number"
                className="form-control balanceSheet"
                placeholder="R"
                value={balanceSheet.cashAndCashEquivalents}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label control-label text-sm-right">
              Accounts Payable
            </label>
            <div className="col-sm-10">
              <input
                id="accPayable"
                name="accountsPayable"
                type="number"
                className="form-control balanceSheet"
                placeholder="R"
                value={balanceSheet.accountsPayable}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label control-label text-sm-right">
              Inventory
            </label>
            <div className="col-sm-10">
              <input
                id="inventory"
                type="number"
                name="inventory"
                className="form-control
                                balanceSheet"
                placeholder="R"
                value={balanceSheet.inventory}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label control-label text-sm-right">
              Total Current Liabilities
            </label>
            <div className="col-sm-10">
              <input
                id="currentLiabilities"
                name="totalCurrentLiabilities"
                type="number"
                className="form-control balanceSheet"
                placeholder="R"
                value={balanceSheet.totalCurrentLiabilities}
                onChange={this.handleChange}
              />
            </div>
          </div>
        </Form>
      </section>
    );
  }
}
export default BalanceSheetPage;
