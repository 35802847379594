const weekDays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'];
const monthNames = [
    'January',
    'Feburary',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'];
    const bankNames = [
        'FNB/RMB',
        'Standard Bank',
        'Nedbank',
        'Bidvest Bank',
        'Capitec Bank',
        'African Bank',
        'Absa Group Limited',
        'African Bank Limited',
        'Bank Zero',
        'Bidvest Bank Limited',
        'Capitec Bank Limited',
        'Capitec Business Limited',
        'Discovery Limited',
        'First National Bank',
        'FirstRand Bank - A Subsidary of First Rand Limited',
        'FMB-Rand Merchant Bank',
        'Grindrod Bank Limited',
        'Imperial Bank South Africa',
        'Investec Bank Limited',
        'Ithala Bank',
        'Mercantile Bank Limited',
        'Nedbank Limited',
        'Sasfin Bank Limited',
        'Standard Bank of South Africa',
        'Ubank Limited',
        'Tyme Bank'
    ];
const two = 2,
    three = 3,
    TEN = 10,
    FIFTEEN = 15,
    THIRTY = 30,
    HUNDRED = 100,
    FIVE = 5,
    THIRTEEN = 13;
const cashFlowSetup = () => {
    const date = new Date();
    let currentThreeMonths = [
        date.getMonth() - 1,
        date.getMonth() - two,
        date.getMonth() - three];
    currentThreeMonths = currentThreeMonths.map(month => {
        let year = date.getFullYear();
        if (month < 0) {
            month += 12; //NOSONAR
            year -= 1; //NOSONAR
        }
        return monthNames[month] + year;
    });
    return currentThreeMonths;
};
const checkForEmpty = obj => Object.values(obj).some( value => (value === '' || value === null || value === undefined));

const isEmailValid = value => {
    // eslint-disable-next-line no-useless-escape
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (reg.test(value) === false){
        return false;
    }

    return true;
};
const statusMsgs = {
    'Completed':'Your business profile is completed you can now apply for loan(s) whilst your profile is being verified',
    'Verified' : 'Your loan has been verified and has been shared with our network of financiers',
    'Not Verified':'Your loan has not been verified. You will receive an email from the Nisa Team indicating what needs to be corrected.',
    'Rejected':'Your loan has been rejected by the Nisa Team. Look out for an email detailing your rejection status.',
    'Awaiting Offer':'Your loan has been shared with our network of financiers. Please be patient whilst we await an offer(s).',
    'Awaiting Verification':'Your loan has been submitted and is currently being reviewed by the Nisa Team.',
    'Awaiting offer acceptance':'You have receivedan offer from financier xand there areawaiting your response.',
    'Financing offer accepted':'You have accepted the loan offer from financier x. Look out for an email with the loan agreement.',
    'Fund Issued':'You have issued',
    'Loan Repaid':'Xhas repaid the loan amount of Rx',
    'Funds paid out by financier':'Financier x has paid out the loan amount of Rx',
    'Loan Default':'This loanhas marked as having paymentdefaults. The Nisa Team will reach out to try and rectify this situation',
    'Awaiting Signature':'A loan agreement has been sent out. Please check your emails.',
    'Financing offer not accepted':'Your offer hasnot been accepted',
    'Financing offers received but rejected':'You have rejected this loan offer ',
    'Overdue Loan':'You have marked this loan as being overdue.',
    'Loan Overdue':'Your loan has been marked as overdue. Please stay up to date with your payments.',
    'Financingoffer sent':'Your offer has been successfully sent to xand is awaiting a response.',
    'Loan Agreement Document Signed':'The loan agreement has been successfully signed. Funds can now be issued.'};
export { weekDays, monthNames, cashFlowSetup, two, three, TEN , FIFTEEN , THIRTY , FIVE , HUNDRED ,
     bankNames , checkForEmpty , isEmailValid,statusMsgs};
