import React, { Fragment, useEffect, useState } from "react";
import { OauthIntegrations } from "../../services/requests";

const DIAModalData = ({ data, businessProfileStore, businessId }) => {
  const [capturedData, setCapturedData] = useState();
  const [dhaData, setDhaData] = useState(data);
  const [directorDetails, setDirectorDetails] = useState([]);

  useEffect(() => {
    let { companyDirectors } = businessProfileStore;
    let tempDirectorDetails = companyDirectors.map((director) => {
      return {
        idNo: director.idNo,
        name: `${director.firstName} ${director.lastName}`,
      };
    });
    // comment out the next line
    tempDirectorDetails = [
      { idNo: 9605085341086, name: "Thando" },
      { idNo: 9709306081081, name: "Sinqobile" },
      { idNo: 980129542008, name: "shobhit" },
    ];
    setDirectorDetails(tempDirectorDetails);
    async function getDiaData() {
      const diaResponseData = await OauthIntegrations.setDiaDhaData(
        tempDirectorDetails.map((director) => director.idNo),
        businessId
      );
      setDhaData(diaResponseData.data);
    }
    !dhaData && getDiaData();
    async function getCapturedData() {
      const savedCapturedData = await OauthIntegrations.getSavedCapturedData(
        businessId
      );
      setCapturedData(savedCapturedData.data.dirSavedCapturedImg);
    }
    !capturedData && getCapturedData();
  }, [dhaData, capturedData, businessProfileStore, businessId]);

  const styles = {
    directorName: {
      textTransform: "uppercase",
      minHeight: 50,
    },
    title: {
      justifySelf: "center",
    },
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <div
        style={{
          display: "grid",
          rowGap: 50,
          columnGap: 30,
          gridTemplateColumns: "repeat(2, 1fr)",
        }}
      >
        <div style={styles.title}>
          <b>Home Affairs Images</b>
        </div>
        <div style={styles.title}>
          <b>Captured Images</b>
        </div>
        {directorDetails.map(({ idNo, name }, index) => (
          <Fragment key={idNo}>
            <div>
              {dhaData &&
              dhaData.find((o) => o.idNo === idNo).directorDhaImg ? (
                <>
                  <div style={styles.directorName}>
                    <b>
                      {index + 1}&nbsp;{name} (Id Number {idNo})
                    </b>
                  </div>
                  <img
                    src={`data:image/png;base64,${
                      dhaData.find((o) => o.idNo === idNo).directorDhaImg
                    }`}
                    alt="verify director"
                    style={{ width: "100%" }}
                  />
                </>
              ) : !dhaData ? (
                <>Loading...</>
              ) : (
                <>
                  <div style={styles.directorName}>
                    <b>
                      {index + 1}&nbsp;{name} (Id Number {idNo})
                    </b>
                  </div>
                  <b style={{ textTransform: "capitalize" }}>
                    {name}'s ({idNo}) image is not available with department of
                    home affairs
                  </b>
                  <div>
                    Recheck Id Number or check the status with home affairs
                    database
                  </div>
                </>
              )}
            </div>
            <div>
              {capturedData && capturedData.find((o) => o.idNo === idNo) ? (
                <>
                  <div style={styles.directorName}>
                  </div>
                  <img
                    src={capturedData.find((o) => o.idNo === idNo).capturedImg}
                    alt="verify director"
                    style={{ width: "100%" }}
                  />
                </>
              ) : capturedData === undefined ? (
                <>Loading</>
              ) : (
                capturedData === null && (
                  <>
                    <div style={styles.directorName}>
                    </div>
                    <div>
                      Latest Image has not yet been upload by {name} (Id Number{" "}
                      {idNo})
                    </div>
                  </>
                )
              )}
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default DIAModalData;
