import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { LoanDetails } from '../../../services/requests';
import { View } from '../../../shared/components';
import { Card, CardBody } from '@Comp';
import { ListGroup, Col, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import ViewUploadedFiles from '../../business-profile-copy/ViewUploadeFiles';
import numeral from 'numeral';

@inject('businessProfileStore', 'loanStore', 'viewStore', 'commonStore')
@observer
@withRouter
class Loanview extends Component {
    constructor() {
        super();
        this.state = {
            loanData: ''
        };
    }
    async componentDidMount() { //NOSONAR
        this.props.commonStore.setLoading(true);
        const { loanId } = this.props.match.params;
        const { showOffer = false } = this.props;
        //from offers screen
        if (loanId && !showOffer) {
            const response = await LoanDetails.get(loanId);
            if (response && response.data) {
                let invoice = [];
                if (response.data.invoice) {
                    invoice = [response.data.invoice];
                }
                if (response.data.offerStatus === 'accepted') {
                    const accepted = response.data.offers.find(offer => offer.id === response.data.acceptedOffer);
                    response.data.status = accepted.status;
                    response.data.offer = accepted;
                }
                response.data.invoice = invoice;
                this.setState({ loanData: response.data });
            }
        }
        if (showOffer) {
            let invoice = [];
            if (showOffer.invoice) {
                invoice = [showOffer.invoice];
            }
            showOffer.invoice = invoice;
            this.setState({ loanData: showOffer });
        }
        this.props.commonStore.setLoading(false);

    }

    linkFormatter = loanData => {
        const { history, viewStore } = this.props;
        switch (loanData.status.name) {
            // Allow Editing
            case 'Not Verified':
                return (
                    <div
                        onClick={() => {
                            // Edit
                            viewStore.setEditLoanDetails(loanData);
                            history.push('/home/loan/apply');
                        }}
                        className="cursor text-center btn btn-info mt-3 float-right"
                    >
                        Update Loan Application
                    </div>
                );
            // Go to Offers Page
            case 'Awaiting offer acceptance':
                return (
                    <div
                        onClick={() => {
                            viewStore.setLoanOffersState({ loanAppl: { ...loanData } });
                            history.push(`/home/loan/offers/${loanData._id}`);
                        }}
                        className="cursor text-center btn btn-info mt-3 float-right"
                    >
                        View Offers
                    </div>
                );

            default:
                break;
        }

        return null;
    };
    handleRemoveDocument = (e, ID) => {
        const { loanData } = this.state;
        const invoices = loanData.invoice.filter(document => document._id !== ID);
        this.setState({ loanData: { ...this.state.loanData, invoice: invoices } });
    };

    render() {//NOSONAR
        const { loanData } = this.state;
        const { showOffer = false } = this.props;
        //from offers screen
        const { acceptedOffer } = loanData;
        let provider = '';
        if (acceptedOffer) {
            const offerRes = loanData.offers.filter(offer => offer.id === acceptedOffer);
            provider = offerRes && offerRes[0]['financialProvider'];
        }
        return (
            loanData && (
                <>
                    <View showOffer={showOffer}>
                        {!showOffer && (<div className="view-header">
                            <header className="title text-white">
                                <h1 className="h4 text-uppercase">Loans</h1>
                                <p className="mb-0">
                                    Loan Application Number : {loanData && loanData._id}
                                </p>
                            </header>
                        </div>)}
                        <div className="view-content view-components">
                            <Card className="mb-4">
                                <CardBody className="table-responsive">

                                    <Row>
                                        <Col>
                                            <h3 className="mb-4 text-uppercase">
                                                <span className="badge badge-info p-3">
                                                    Loan Application Details
                                    </span>
                                            </h3>
                                            <hr />
                                            <ListGroup>
                                                <ListGroup.Item>
                                                    <strong>Application Number </strong>: {loanData._id}
                                                </ListGroup.Item>

                                                <ListGroup.Item>
                                                    <strong>Application Status </strong>:
                                        {loanData.status && (
                                                        <div
                                                            className="badge badge-pill text-white"
                                                            style={{ backgroundColor: loanData.status.color }}
                                                        >
                                                            {loanData.status.smeTitle || loanData.status.name}
                                                        </div>
                                                    )}
                                                </ListGroup.Item>
                                                {loanData.status.name === 'Awaiting offer acceptance' && (
                                                    <ListGroup.Item>
                                                        <strong>Offer Acceptance Status </strong>:
                                                        <div
                                                            className="badge badge-pill text-white"
                                                            style={{ backgroundColor: loanData.status.color }}
                                                        >
                                                            {loanData.offerStatus}

                                                        </div>
                                                        {loanData.offerStatus === 'accepted' &&
                                                            <div
                                                                className="badge badge-pill text-white ml-2"
                                                                style={{ backgroundColor: '#2f7fb6' }}
                                                            >
                                                                {provider}

                                                            </div>}
                                                    </ListGroup.Item>
                                                )}

                                                <ListGroup.Item>
                                                    <strong>Requested Loan Type </strong>:
                                        {loanData.factoring && 'Purchase Order'}
                                                    {loanData.discounting && 'Invoice'}
                                                </ListGroup.Item>

                                                <ListGroup.Item>
                                                    <strong>Invoice Amount </strong>: {loanData.invoiceAmount}
                                                </ListGroup.Item>

                                                <ListGroup.Item>
                                                    <strong>Invoice Due Date </strong>:
                                        {new Date(loanData.invoiceDueDate).toDateString()}
                                                </ListGroup.Item>

                                                <ListGroup.Item>
                                                    <strong>Buyer </strong>: {loanData.buyer}
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <strong>Buyer Telephone Number</strong>: {loanData.contactPerson}
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <strong>Buyer Email</strong>: {loanData.buyerEmail}
                                                </ListGroup.Item>
                                                <ListGroup.Item>
                                                    <strong>Loan Description</strong>: {loanData.loanDescription}
                                                </ListGroup.Item>

                                                <ListGroup.Item>
                                                    <strong>Buyer Relationship </strong> :
                                        {loanData.buyerRelationship === 'supplyChain' && ' Part of supply chain'}
                                                    {loanData.buyerRelationship === 'procurement' && ' Procurement of goods/services'}
                                                </ListGroup.Item>

                                                <ListGroup.Item>
                                                    <strong>Invoice Percent For Loan </strong>:
                                        {loanData.loanPercent} %
                                    </ListGroup.Item>

                                                <ListGroup.Item>
                                                    <strong>Loan Amount </strong>: R {numeral(loanData.loanAmount).format('0,0.00')}
                                                </ListGroup.Item>
                                            </ListGroup>
                                            {loanData.invoice.length > 0 && (
                                                <div className="my-2 float-left">
                                                    <ViewUploadedFiles
                                                        title="Invoice P/O"
                                                        data={loanData.invoice}
                                                        handleRemoveDocument={this.handleRemoveDocument}
                                                        removable={true}
                                                    />
                                                </div>
                                            )}
                                            {loanData.offer && loanData.offer.eversign_url && (
                                                <a className="btn btn-info my-2 float-right" href={`//${loanData.offer.eversign_url}`}>Download Signed Loan Agreement</a>
                                            )}
                                            <div className="d-block w-100" style={{ clear: 'both' }}></div>
                                            <div className="row justify-content-between mt-3 mx-2">
                                                {loanData.id && !showOffer && (
                                                    <div className="text-sm-left mt-3">
                                                        <button
                                                            className="btn btn-danger "
                                                            onClick={() => this.props.history.push('/home/loan/view')}
                                                        >
                                                            Back
                                    </button>
                                                    </div>
                                                )}
                                                <div>{loanData.status && this.linkFormatter(loanData)}</div>
                                            </div>
                                        </Col>
                                        {loanData.offerStatus === 'accepted' &&
                                            <Col>
                                                <h3 className="mb-4">
                                                    <span className="badge badge-info p-3">
                                                        {(showOffer && 'Offer Details') || 'Accepted offer details'}
                                                    </span>
                                                </h3>
                                                <hr />
                                                <ListGroup>
                                                    <ListGroup.Item>
                                                        <strong>POF  </strong> :  {typeof (loanData.offer.pofProfile) === 'object' && loanData.offer.pofProfile.businessName}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item>
                                                        <strong>Total Repayable </strong> :  R {numeral(loanData.offer.totalRepayable).format('0,0.00')}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item>
                                                        <strong>Monthly Repayable </strong> :  R {numeral(loanData.offer.monthlyRepayable).format('0,0.00')}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item>
                                                        <strong>Additional fee </strong> :  R {numeral(loanData.offer.aditionalFees).format('0,0.00')}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item>
                                                        <strong>Interest </strong> :   {numeral(loanData.offer.interest).format('0,0.00')} %
                                </ListGroup.Item>
                                                    <ListGroup.Item>
                                                        <strong>Terms </strong> :  {loanData.offer.terms}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item>
                                                        <strong>Total PaidBack </strong> : R {(Array.isArray(loanData.offer.payments) &&
                                                            numeral(loanData.offer.payments.reduce((acc, cur) => acc + Number(cur.amount), 0) || 0).format('0,0.00'))}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item>
                                                        <strong>Loan to Value (% LTV ) </strong>:
                                        {loanData.offer.loanPercent || loanData.loanPercent} %
                                    </ListGroup.Item>

                                                    <ListGroup.Item>
                                                        <strong>Loan Amount </strong>: R {numeral(loanData.offer.loanAmount || loanData.loanAmount).format('0,0.00')}
                                                    </ListGroup.Item>
                                                </ListGroup>
                                                {loanData.offer.docs && (
                                                    <div className="my-2">
                                                        <ViewUploadedFiles
                                                            title="Original agreement document from Financier"
                                                            data={[loanData.offer.docs]}
                                                            removable={true}
                                                            btnTitle='View original agreement'
                                                        />
                                                    </div>
                                                )}
                                            </Col>
                                        }
                                    </Row>
                                </CardBody>
                            </Card>
                        </div>

                    </View>
                </>
            )
        );
    }
}

export default Loanview;
