const checked = (value, options) => {
    if (value !== true) {
      return options.message || 'must be checked';
    }
  };
  
const cipcValidate = (cipcValue) => {
    let correct = null;

    if (cipcValue){

        cipcValue= cipcValue.trim();
        if (cipcValue.charAt(0)==='K' ||cipcValue.charAt(0)==='k'){
            cipcValue= cipcValue.substring(1)
        }
        // format: YYYY/ INTEGER/ NN
        let yyyy = cipcValue.substring(0,4)
        let nn = cipcValue.substring(cipcValue.length-2)
        let integerBody =  cipcValue.substring(5,cipcValue.length-3)
        if (cipcValue.length < 9 ||!isNumber(yyyy) || !isNumber(nn) || !isNumber(integerBody) ){
            correct= "Incorrect CIPC Format";
        }
        const d = new Date();
        if(yyyy<1800 || yyyy>d.getFullYear()){
            correct="Incorrect CIPC Format";
        }
        if (!(cipcValue.charAt(4)==='/' && cipcValue.charAt(cipcValue.length-3)==='/')){
            correct= "Incorrect CIPC Format";
        }
        //console.log(correct)
        return correct

    }else{
        correct = "Incorrect CIPC Format"
        return correct
    }

};

function isNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

function isEquivalent(a, b) {
    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
        return false;
    }

    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];

        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
            return false;
        }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
}

const  idNumberValidate  = (idNumber) =>{


    //var idNumber = $('#idnumber').val();

    // assume everything is correct and if it later turns out not to be, just set this to false
    let correct = null;
    if(idNumber){
    
    // SA ID Number have to be 13 digits, so check the length
    if (idNumber.length !== 13 || !isNumber(idNumber)) {
          correct = "Incorrect ID Number";
        return correct;
    }
    idNumber = ""+idNumber;

    // get first 6 digits as a valid date
    let tempDate = new Date(idNumber.substring(0, 2), idNumber.substring(2, 4) - 1, idNumber.substring(4, 6));

    let id_date = tempDate.getDate();

    let id_month = tempDate.getMonth();

    let id_year = tempDate.getFullYear();


    let fullDate = id_date + "-" + id_month + 1 + "-" + id_year;


    if (!((""+tempDate.getYear() === idNumber.substring(0, 2)) && (id_month === idNumber.substring(2, 4) - 1) && (""+id_date === idNumber.substring(4, 6)))) {
         correct = "Incorrect ID Number";
        return correct;
    }

    // get the gender
    let genderCode = idNumber.substring(6, 10);
    let gender = parseInt(genderCode) < 5000 ? "Female" : "Male";

    // get country ID for citzenship
    let citzenship = parseInt(idNumber.substring(10, 11)) === 0 ? "Yes" : "No";

    // apply Luhn formula for check-digits
    let tempTotal = 0;
    let checkSum = 0;
    let multiplier = 1;
    for (let i = 0; i < 13; ++i) {
        tempTotal = parseInt(idNumber.charAt(i)) * multiplier;
        if (tempTotal > 9) {
            tempTotal = parseInt(tempTotal.toString().charAt(0)) + parseInt(tempTotal.toString().charAt(1));
        }
        checkSum = checkSum + tempTotal;
        multiplier = (multiplier % 2 === 0) ? 1 : 2;
    }
    if ((checkSum % 10) !== 0) {
        correct = "Incorrect ID Number";
        return correct;
    };
    }


    return correct;
}



export default {
    checked,cipcValidate,idNumberValidate
  };
  
export {cipcValidate,isEquivalent,idNumberValidate}