import React from 'react';
import { Link } from 'react-router-dom';
import { FaDotCircle } from 'react-icons/fa';
import {statusMsgs} from '../constants/constants';

const NotificationsTable = ({ data , noref = false}) => {//NOSONAR
	const clsName = data && data.link && data.link.length > 0 ? 'notifrow' : '';//NOSONAR
	const linkFormat  = ( title,loandetails , view) => {
		if(title!=='Completed' && !noref){
            return <Link to={`/home/loan/${view}/${loandetails.id}`} style={{fontSize:12}}> Ref : {loandetails.buyer} -{loandetails.id}</Link>;
		}
		return '';
	};
	return (
		<table className='table'>
			<tbody>
				{data.map((item, i) => { //NOSONAR
				let message = statusMsgs[item.title];
				const offer = item.offer || {};
				const loandetails = item.loandetail || {};
				const msg = linkFormat(item.title,loandetails,'singleview');
				switch (item.title) {
					case 'Funds paid out by financier':
						message = `Financier ${
							offer.pofProfile && offer.pofProfile.businessName
						} has paid out the loan amount of R  ${offer.loanAmount}`;
						break;
					case 'Financing offer accepted':
						message = `You have accepted the loan offer from financier ${
							offer.pofProfile && offer.pofProfile.businessName
						}. Look out for an email with the loan agreement.`;
						break;
						case 'Loan Repaid':
						message = `${
							offer.smeprofile && offer.smeprofile.businessName
						} has repaid the loan amount of R ${offer.loanAmount}.`;
						break;
					case 'Awaiting offer acceptance':
						message = `You have received an offer from financier ${
							offer.pofProfile && offer.pofProfile.businessName
						} and there are awaiting your response.`;
						break;
					default:
						message = `${message}`;
				}
					return(
					<tr className={clsName} key={i}>
						<td className='d-flex flex-column'>
							<strong>{item.from}</strong>
							<small>{item.date}</small>
						</td>
						<td>
						<div style={{ color: item.color }}>
								<FaDotCircle size='12' />
								&nbsp;{item.status}
							</div>
							<small dangerouslySetInnerHTML={{ __html: message }}></small>
							<br/>
                    <span>
                    {msg}
                    </span>
						</td>
					</tr>
				);
			}
				)}
			</tbody>
		</table>
	);
};

export default NotificationsTable;
