import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';

import Loading from '../loading';

@inject('commonStore')
@observer
export default class View extends React.Component {
    render() {
        let { children , showOffer = false } = this.props;
        return (
            <div className={(!showOffer && 'view')||'view1'}>
                {/* <Loading /> */}
                {children}
            </div>
        );
    }
}
