import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';

const YodleeModal = ({ show, onHide, sageone, sageFormData }) => { //NOSONAR
    const [error, setError] = useState({});
    const onFormSubmit = e => {
        e.preventDefault();
        let err = {};
        let { username, password } = e.target;
        username = username.value;
        password = password.value;
        if (username === '') {
            err = { ...err, username: 'Username can not be empty' };
        }
        if (password === '') {
            err = { ...err, password: 'Password can not be empty' };
        }
        setError(err);
        if (username !== '' && password !== '') {
            sageFormData(username, password);
        }
    };
    const getError = inputname => {
        if (error[inputname]) {
            return error[inputname];
        }
        return '';
    };
    return (
        <>
            <Modal size='lg' show={show} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>{(sageone && 'Add your sageone credentials') || 'Link your Bank Account'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(sageone && <Form className="col-md-8 d-block mx-auto" onSubmit={onFormSubmit}>
                        <Form.Group>
                            <Form.Label>Username</Form.Label>
                            <Form.Control name="username"></Form.Control>
                            <Form.Text className="text-danger">{getError('username')}</Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <Form.Control name="password" type="password"></Form.Control>
                            <Form.Text className="text-danger">{getError('password')}</Form.Text>
                        </Form.Group>
                        <Button
                            className="btn-info mx-right d-block"
                            type="submit">
                            Submit
                </Button>
                    </Form>
                    ) ||
                        <div id="container-fastlink"></div>
                    }
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-between'>
                    <div>
                        {!sageone && 'To find FNB (SA) type search www.fnb.co.za in the search bar'}
                    </div>
                    <Button
                        onClick={onHide}
                        className='bg-transparent border text-dark shadow-none'
                    >
                        Close
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default YodleeModal;
