/* eslint-disable import/first */
// Polyfill for Older browsers
import './polyfills';
const { AppContainer } = require('react-hot-loader');

import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'mobx-react';
//import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
//import createBrowserHistory from 'history/lib/createBrowserHistory';

// import registerServiceWorker from './registerServiceWorker';

// import main style dependency file
import './index.scss';
import { ThemeProvider } from '@material-ui/styles';
import {themeStyles} from './shared/materialUiStyles'

import stores from './stores';
import App from './App';

// For easier debugging ;)
window._____APP_STATE_____ = stores;

// const browserHistory = createBrowserHistory({
//     basename: '/'
// });
// const routingStore = new RouterStore();
// let _stores = {...stores, routing: routingStore};
// const history = syncHistoryWithStore(browserHistory, routingStore);

import validate from 'validate.js';
import validators from './shared/validators/validators';

validate.validators = {
    ...validate.validators,
    ...validators
  };


const _App = (
    <AppContainer>
            <Provider {...stores} >
                <App/>
            </Provider>
    </AppContainer>
);

ReactDOM.render(_App, document.getElementById('root'));

// Register Service Worker
// registerServiceWorker();
