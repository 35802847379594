
import { observable, action } from 'mobx';
import {
    Verifyid
} from '../services/requests';
import storageUtil from './storageUtil';
import commonStore from './commonStore';
import businessProfileStore from './businessProfileStore';

const num4 = 4, num6 = 6, num13 = 13;

class VerifyIdStore {
    @observable businessProfile = storageUtil.getItem('businessProfile') || {
        id: null,
        businessName: null,
        businessEmail: null,
        profileVerified: false,
        isIdle: true,
        loansProfile: null,
        latestloanstatus: null,
        user: null,
        cipcRegNo: null,
        companyDetails: null,
        businessFinancials: null,
        pastInvoices: null,
        verifyid: null,
        profileSetup: false
    };

    //@observable for verifyid
    @observable verifyId = {
        reg_1: '',
        reg_2: '',
        reg_3: '',
        company: '',
        profile: '',
        id: null
    };
    //action post verifyid by admin from verifyid(www.verifyid.co.za)
    @action async postVerifyId(verifyidExists, count = false) {

        //profile to verify
        const data = businessProfileStore.businessProfile
        const str = data.cipcRegNo.toLocaleUpperCase()
        const dataCheck = () => {
            if (str.indexOf('K') > -1) {
                return str
            } else {
                const strAdd = ('K')
                const newData = strAdd.concat(str);
                return newData
            }
        }
        const verifyCipc = dataCheck();

        const newData = businessProfileStore.businessProfile;

        //create data from the selected profile of a user
        const verifyid = {
            reg_1: verifyCipc.substr(1, num4),
            reg_2: verifyCipc.substr(num6, num6),
            reg_3: verifyCipc.substr(num13, num13),
            company: newData.businessName,
            profile: newData.id

        };
        if (count) {
            verifyid.count = count;
        }
        commonStore.setLoading(true);
        // //check if verifyid is already exists !
        const checkExist = commonStore.businessProfile.verifyid;
        let response = '';
        if (verifyidExists && checkExist !== '') {
            response = await Verifyid.edit(data.verifyid._id,
                verifyid
            );
        } else {
            response = await Verifyid.add(verifyid);
        }
        commonStore.setLoading(false);
        return response;
    }

    @action async deleteVerifyId(dataExists) {
        const checkExist = commonStore.businessProfile.verifyid;
        if (dataExists && checkExist !== '') {
            await Verifyid.delete(checkExist.id);
        } else {
            commonStore.setLoading(false);
        }
    }
}

const verifyIdStore = new VerifyIdStore();
export default verifyIdStore;