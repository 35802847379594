import React, { Component } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';
import DocumentUploader from '../dropzone';
import ViewUploadedFiles from '../ViewUploadeFiles';
import { inject, observer } from 'mobx-react';
import {TEN} from '../../../constants/constants';
@inject('businessProfileStore')
@observer
class CompanyDocs extends Component {
    handleRemoveDocument = (e, documentId) => {
        const {
            companyRegDocs
        } = this.props.businessProfileStore.businessProfile.companyDetails;
        const companyRegDocsData = companyRegDocs.filter(
            document => document.id !== documentId
        );
        this.props.businessProfileStore.businessProfile.companyDetails.companyRegDocs = companyRegDocsData;
    };
    render() {
        const {companyDetails } = this.props.businessProfileStore.businessProfile;
       let id = '';
       let companyRegDocs = [];
      if(companyDetails) {
       companyRegDocs = companyDetails.companyRegDocs;
       id = companyDetails.id;
      }
        let fileInfo = {};
        if (id) {
            fileInfo = {
                files: [],
                path: '/public/upload',
                refId: id,
                ref: 'companydetails',
                source: 'content-manager',
                field: 'companyRegDocs'};
        }
        return (
            <div>
                <Accordion allowZeroExpanded={true}>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            Company Registration & BBBEE Certificate / Affidavit
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            {companyRegDocs.length < TEN && id && this.props.isDocumentUploadVisible && (
                                <DocumentUploader
                                    fileData={fileInfo}
                                    maxFiles={TEN - Number(companyRegDocs.length)}
                                    multiple={true}
                                    inputContent={'Upload Cor14.3 and BBBEE'}
                                />
                            ) }
                            <div className="row justify-content-center mt-2">
                                    {companyRegDocs.length > 0 && (
                                        <ViewUploadedFiles
                                            title="Certified Registration Certificates"
                                            data={companyRegDocs}
                                            handleRemoveDocument={this.handleRemoveDocument}
                                        />
                                    )}
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }
}
export default CompanyDocs;
