import React from 'react';
import { Header } from '@Nisa';


let desc = () => {
    return <div>Latest loan statistics&nbsp;<span className="hidden-sm-down">&amp; updates</span></div>
};

export default () => (
    <Header 
        title={'Home'}
        description={desc()}
    />
);
