import React, {useState} from 'react';

const YodleeModalData = ({data}) => {

  const [collapse, setCollapse] = useState('');
  const toggleCollapse = key => {
      if (key === collapse) {
          setCollapse('');
      } else {
          setCollapse(key);
      }
  };
  return (
    <>
      <div style={{marginTop: '20px'}}>
        {data ? (
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <button className="btn btn-primary" type="button" onClick={() => toggleCollapse('yodleeAccounts')}>
                Accounts
            </button>
            {collapse === 'yodleeAccounts' ? (
              <div id="yodleeAccounts">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Account Number</th>
                        <th>Balance</th>
                        <th>Provider Name</th>
                        <th>Last Updated</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.accounts.map((row, index) => (
                        <tr key={`yodlee_account_${index}`}>
                          <td>{row.accountName}</td>
                          <td>{row.accountStatus}</td>
                          <td>{row.accountNumber}</td>
                          <td>{row.balance && row.balance.currency} {row.balance && row.balance.amount}</td>
                          <td>{row.providerName}</td>
                          <td>{row.lastUpdated}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
            <button className="btn btn-primary" type="button" onClick={() => toggleCollapse('yodleeStatements')}>
                Statements
            </button>
            {collapse === 'yodleeStatements' ? (
              <div id="yodleeStatements">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Last Payment Amount</th>
                        <th>Last Payment Date</th>
                        <th>Is Latest</th>
                        <th>Last Updated</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.statements.map((row, index) => (
                        <tr key={`yodlee_account_${index}`}>
                          <td>{row.statementDate}</td>
                          <td>{row.lastPaymentAmount && row.lastPaymentAmount.currency} {row.lastPaymentAmount && row.lastPaymentAmount.amount}</td>
                          <td>{new Date(row.lastPaymentDate).toLocaleString()}</td>
                          <td>{row.isLatest}</td>
                          <td>{row.lastUpdated}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
            <button className="btn btn-primary" type="button" onClick={() => toggleCollapse('yodleeTransactions')}>
                Transactions
            </button>
            {collapse === 'yodleeTransactions' ? (
              <div id="yodleeTransactions">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Container</th>
                        <th>Amount</th>
                        <th>Running Balance</th>
                        <th>Base type</th>
                        <th>Category Type</th>
                        <th>Category</th>
                        <th>Transaction Date</th>
                        <th>Created Date</th>
                        <th>Last updated date</th>
                        <th>Type</th>
                        <th>Subtype</th>
                        <th>Merchant</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.transactions.map((row, index) => (
                        <tr key={`yodlee_account_${index}`}>
                          <td>{row.CONTAINER}</td>
                          <td>{row.amount && row.amount.currency} {row.amount && row.amount.amount}</td>
                          <td>{row.runningBalance && row.runningBalance.currency} {row.runningBalance && row.runningBalance.amount}</td>
                          <td>{row.baseType}</td>
                          <td>{row.categoryType}</td>
                          <td>{row.category}</td>
                          <td>{new Date(row.transactionDate).toLocaleString()}</td>
                          <td>{new Date(row.createdDate).toLocaleString()}</td>
                          <td>
                          {new Date(row.lastUpdated).toLocaleString()}</td>
                          <td>{row.type}</td>
                          <td>{row.subType}</td>
                          <td>
                            {JSON.stringify(row.merchant)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : null}
          </div>
        ): (
          <div className="alert">No data available</div>
        )}
      </div>
    </>
  );
}

export default YodleeModalData;