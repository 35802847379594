import React from 'react'
import images from '@assets';
import { Link, IndexLink } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';

const NoLoan = ()=>{
    const isWidth_1500 = useMediaQuery('(min-width:1200px)')

    return(
        <div className="row d-flex justify-content-center">
            <div className="col-md-12">
                <div className="card">
                    <div className="row no-gutters">
                        <div className="col-md-5">
                            <img className="img-fluid img-fit-cover" src={images.NisaFinLogo} alt=""/>
                        </div>
                        <div className="col-md-7">
                            <div className="card-body">
                                <h4>
                                    <Link to="/home/loan/apply">
                                        {isWidth_1500 ? 
                                        <span className="badge badge-info">
                                            Apply Now For A New Loan
                                        </span> 
                                        :
                                        <span className="badge badge-info">
                                            Apply Now <br/> <div className='badge-info' style={{marginTop:"3px"}}> For A New Loan </div> 
                                        </span>
                                        }
                                    </Link>
                                </h4>
                                <h4 className="m-v-20"><a className="text-dark">No Loans Found</a></h4>
                                <p>Apply now for a new loan to gain access to the most affordable invoice financing,we
                                    will help you manage your cash-flow and grow your business to the next level.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default NoLoan;
