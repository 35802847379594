import React, { Component } from 'react';
import { Form, FormGroup, Label, Col } from '@Comp';
import { TextField } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import DocumentUploader from '../dropzone';
import ViewUploadedFiles from '../ViewUploadeFiles';
import validate from 'validate.js';
import { businessAddressSchema } from '../ValidationSchemas';
@inject('businessProfileStore')
@observer
class BusinessAddress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: undefined,
            businessAddressData: {
                description: '',
                unitNo: '',
                streetName: '',
                suburb: '',
                city: '',
                province: '',
                postalCode: '',
                proofOfAddress: [],
                companyDetails: null,
                complete: false,
                id: null
            },
            fetchClick:false
        };
        this.formState = {
            isValid: false,
            values: {},
            touched: {},
            errors: {}
        };
    }

    componentDidMount() {
        const { companyDetails } = this.props.businessProfileStore.businessProfile;
        if (companyDetails && companyDetails.businessAddress) {
            this.setState({ businessAddressData: companyDetails.businessAddress });
        }
    }
    componentDidUpdate(){
        if(this.props.fetchClick && !this.state.fetchClick){
            const { companyDetails } = this.props.businessProfileStore.businessProfile;
            if (companyDetails && companyDetails.businessAddress) {
                this.setState({ businessAddressData: companyDetails.businessAddress,fetchClick:true });
            }
        }
    }
    handleChange = e => {
        const { name, value } = e.target;
        this.formState = {
            ...this.formState,
            values: {
                ...this.formState.values,
                [name]: value
            },
            touched: {
                ...this.formState.touched,
                [name]: true
            }
        };

        const errors = validate(this.formState.values, businessAddressSchema);
        let bool = true;
        if (errors) {
            bool = false;
        }
        this.formState = {
            ...this.formState,
            isValid: bool,
            errors: errors || {}
        };

        this.setState(
            {
                businessAddressData: { ...this.state.businessAddressData, [name]: value }
            },
            () => {
                this.props.formDataChange(this.state.businessAddressData, 'businessAddress');
                this.props.businessProfileStore.businessAddress = this.state.businessAddressData;
            }
        );
        this.props.businessProfileStore.fieldsToUpdate.businessAddressDirty = true;
    };

    hasError = field => {
        let bool = false;
        if (this.formState.touched[field] && this.formState.errors[field]) {
            bool = true;
        }
        return bool;
    };

    handleRemoveDocument = (e, documentId) => {
        this.props.businessProfileStore.businessProfile.companyDetails.businessAddress.proofOfAddress =
            this.props.businessProfileStore.businessProfile.companyDetails.businessAddress.proofOfAddress.filter(
                document => document.id !== documentId
            );
    };

    ErrorMsg = field => {
        const hasError = this.hasError(field);
        if (hasError) {
            return this.formState.errors[field][0];
        }
        return null;
    }

    render() {
        const { companyDetails } = this.props.businessProfileStore.businessProfile;
        const fileInfo = {
            files: [],
            path: '/public/upload',
            refId: companyDetails && companyDetails.businessAddress && companyDetails.businessAddress._id,
            ref: 'businessaddress',
            source: 'content-manager',
            field: 'proofOfAddress'
        };

        return (
            <div>
                <div className="row">
                    <Form
                        method="post"
                        action="/upload"
                        encType="multipart/form-data"
                        className="m-t-15 companyInfo col-12"
                        id="businessAddress"
                        name="businessAddress"
                    >
                        <FormGroup className="row">
                            <Label
                                className="col-sm-2 col-form-label control-label text-sm-right"
                                htmlFor="description"
                            >
                            </Label>
                            <p>Please give us a short description of your company focusing on its main operations, clients,  number of employees and any other relevant information that you would like to be part of your business profile (MAX 250 words)</p>
                            <Col sm={12}>
                                <TextField
                                    id="description"
                                    name="description"
                                    type="text"
                                    multiline
                                    className="form-control businessAddress"
                                    placeholder="Provide your description here"
                                    value={this.state.businessAddressData.description}
                                    onChange={this.handleChange}
                                    error={this.hasError('description')}
                                    helperText={
                                        this.ErrorMsg('description')
                                    }
                                />
                            </Col>
                        </FormGroup>
                        <h5 className="mt-2">Business Address</h5>
                        <FormGroup className="row">
                            <Label
                                className="col-sm-2 col-form-label control-label text-sm-right"
                                htmlFor="UnitNo"
                            >
                                Building Name &amp; Unit No.
                            </Label>
                            <Col sm={10}>
                                <TextField
                                    id="unitNo"
                                    name="unitNo"
                                    type="text"
                                    className="form-control businessAddress"
                                    placeholder="BuildingName/UnitNo"
                                    value={this.state.businessAddressData.unitNo}
                                    onChange={this.handleChange}
                                    error={this.hasError('unitNo')}
                                    helperText={
                                        this.ErrorMsg('unitNo')
                                    }
                                />
                            </Col>
                        </FormGroup>

                        <FormGroup className="row">
                            <label className="col-sm-2 col-form-label control-label text-sm-right">
                                Street Name
                            </label>
                            <Col sm-={0}>
                                <TextField
                                    id="streetName"
                                    name="streetName"
                                    type="text"
                                    className="form-control businessAddress"
                                    placeholder="StreetName"
                                    value={this.state.businessAddressData.streetName}
                                    onChange={this.handleChange}
                                    error={this.hasError('streetName')}
                                    helperText={
                                        this.ErrorMsg('streetName')
                                    }
                                />
                            </Col>
                        </FormGroup>

                        <FormGroup className="row">
                            <Label className="col-sm-2 col-form-label control-label text-sm-right">
                                Suburb
                            </Label>
                            <Col sm={10}>
                                <TextField
                                    id="suburb"
                                    name="suburb"
                                    type="text"
                                    className="form-control businessAddress"
                                    placeholder="Suburb"
                                    value={this.state.businessAddressData.suburb}
                                    onChange={this.handleChange}
                                    error={this.hasError('suburb')}
                                    helperText={
                                        this.ErrorMsg('suburb')
                                    }
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Label className="col-sm-2 col-form-label control-label text-sm-right">
                                City/Town
                            </Label>
                            <Col sm={10}>
                                <TextField
                                    id="city"
                                    name="city"
                                    type="text"
                                    className="form-control businessAddress"
                                    placeholder="City/Town"
                                    value={this.state.businessAddressData.city}
                                    onChange={this.handleChange}
                                    error={this.hasError('city')}
                                    helperText={
                                        this.ErrorMsg('city')}
                                />
                            </Col>
                        </FormGroup>
                        <div className="form-group row">
                            <Label className="col-sm-2 col-form-label control-label text-sm-right">
                                Province
                            </Label>
                            <Col sm={10}>
                                <TextField
                                    id="province"
                                    name="province"
                                    type="text"
                                    className="form-control businessAddress"
                                    placeholder="Province"
                                    value={this.state.businessAddressData.province}
                                    onChange={this.handleChange}
                                    error={this.hasError('province')}
                                    helperText={
                                        this.ErrorMsg('province')
                                    }
                                />
                            </Col>
                        </div>
                        <FormGroup className="row">
                            <Label className="col-sm-2 col-form-label control-label text-sm-right">
                                Postal Code
                            </Label>
                            <Col sm={10}>
                                <TextField
                                    id="postalCode"
                                    name="postalCode"
                                    className="form-control businessAddress"
                                    placeholder="PostalCode"
                                    minLength="4"
                                    maxLength="4"
                                    value={this.state.businessAddressData.postalCode}
                                    onChange={this.handleChange}
                                    error={this.hasError('postalCode')}
                                    helperText={
                                        this.ErrorMsg('postalCode')
                                    }
                                />
                            </Col>
                        </FormGroup>
                    </Form>
                </div>
                <div className="row">
                    {companyDetails?.businessAddress && companyDetails?.businessAddress?.proofOfAddress && companyDetails?.businessAddress?.proofOfAddress?.length > 0 && (
                        <div className="w-100 d-flex justify-content-center">
                            <ViewUploadedFiles
                                title="Proof Of Address"
                                data={companyDetails.businessAddress.proofOfAddress}
                                handleRemoveDocument={this.handleRemoveDocument}
                            />
                        </div>
                    )}
                    {companyDetails?.businessAddress && companyDetails?.businessAddress?.proofOfAddress && companyDetails?.businessAddress?.proofOfAddress.length < 1 && this.props.isDocumentUploadVisible && (
                        <div className="offset-2 col-10 eightth-step">
                            <DocumentUploader
                                fileData={fileInfo}
                                maxFiles={1}
                                multiple={true}
                                inputContent={'Upload Proof of Address'}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default BusinessAddress;
