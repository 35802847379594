import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import {Card, CardBody, Button} from '@Comp';
import {Modal} from 'react-bootstrap';
import { Offers } from '../../../services/requests';
import './style.scss';
import SingleLoanView from '../view/loanview';

const ViewHeader = () => (
    <div className="view-header">
        <header className="title text-white">
            <h1 className="h4 text-uppercase">Loan Offers</h1>
            <p className="mb-0">View / Accept Loan Offers</p>
        </header>
    </div>
);

const ViewContent = ({children}) => (
    <div className="view-content view-components">
        {children}
    </div>
);

const RenderLoadDetails = ({offer , onAction , openModal}) =>
(
    <Card className="mb-4 loanDetails">
        <CardBody>
            <div>Financial Provider: <b>{offer.pofProfile.businessName}</b></div>
            <div>Interest Rate: <b>{offer.interest || 0} % </b></div>
            <div className='mb-2'>Loan Amount: <b>R {offer.loandetails.loanAmount.toFixed(2)}</b></div>
            {(offer.loandetails.offerStatus !=='accepted') && offer.status.name !== 'Financing offer not accepted' &&  <>
            <Button onClick={()=>onAction('accepted',offer)}>Accept</Button>
            <Button onClick={()=>onAction('rejected',offer)}>Reject</Button>
            <Button onClick={()=>openModal(offer)}>Show more</Button>
            </>
            }
        </CardBody>
    </Card>
);
const MenuItem = ({financialProvider, loanAmount, selected}) => {
    return <div
      className={`menu-item loanScrollItem ${selected ? 'active' : ''}`}>
        <b>{financialProvider}</b><br/>
        <i>R {loanAmount}</i>
      </div>;
};

const Menu = (list, selected) =>
  list.map((el, idx) => { 
    return <MenuItem 
            financialProvider={el.pofProfile.businessName} 
            loanAmount={el.loandetails.loanAmount} 
            key={idx} 
            selected={(idx === selected)?true:false} />;
});

const Arrow = ({ text, className }) => {
    return (
      <div
        className={className}
      >{text}</div>
    );
};

const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });

/**
 * LoanOffers View - Props used: loan.id
 */
@inject('viewStore', 'businessProfileStore','loanStore','commonStore')
@withRouter
@observer
export default class LoanOffers extends React.Component {

    constructor(props){
        super(props);
        this.state={loanData:null,
            rejectAll:0,
        selected:0,
        isModalVisible:false,
        offerData:null}
          }

    async componentDidMount() {
        this.props.commonStore.setLoading(true);
        const { loanId } = this.props.match.params;
        if (loanId) {
            const response = await Offers.getByLoanId(loanId);
            if (response && response.data && response.data.length > 0) {
               const isLast =  response.data.filter(offer=>offer.status.name!=='Financing offer not accepted').length;
                this.setState({ loanData: response.data , rejectAll:isLast});
            }
        }
        this.props.commonStore.setLoading(false);

    }

	handleModal = (e, bool = false) => {
		this.setState({ isModalVisible: bool });
	};
onSelect = key =>{
    if(this.state.selected !==key){
        this.setState({selected:key});
    }
}

    RenderLoanScroll = (providerList, selected) =>{
        const menu = Menu(providerList, selected);
        return <Card className="mb-4 loanDetails">
            <CardBody>
                <ScrollMenu
                    data={menu}
                    arrowLeft={ArrowLeft}
                    arrowRight={ArrowRight}
                    selected={selected}
                    onSelect={this.onSelect}
                />
            </CardBody>
        </Card>
    };

     onAction = async (choice,offer) =>{
        this.props.commonStore.setLoading(true);
        const { loanId } = this.props.match.params;
        const {loanStore , history} = this.props
       await loanStore.updateOfferAction(loanId,choice,offer , this.state.rejectAll);
       history.push(`/home/loan/singleview/${loanId}`)
    }
openModal = offer =>{
    const loandetails = {...offer.loandetails,offer:offer,offerStatus:'accepted'};
    this.setState({offerData:loandetails},()=>{
        this.handleModal('e',true);
    });
}
    render(){
        const {  history } = this.props;
        const { loanId } = this.props.match.params;
        const {loanData , selected} = this.state;
        let _load = loanData;
            return (
                <div className="view">
                    <ViewHeader/>
                    <ViewContent>
                  
                        <Card className="mb-4">
                            <CardBody>
                            {_load  &&
                                (Array.isArray(loanData) && loanData.length > 0)
                                  &&  
                                  <>
                                  <RenderLoadDetails offer={loanData[selected]} onAction={this.onAction} 
                                  openModal={this.openModal}/>
                                  {this.RenderLoanScroll(loanData, selected)}
                                  </>
                            }
                                {!_load &&
                                <h2>Loading...</h2>
                                }
                                 {Array.isArray(_load) && _load.length === 0 &&
                                <h2>No Loan Offers Available</h2>
                                }
                                <div className="text-sm-left">
                                    <button
                                        className="btn btn-danger"
                                        onClick={() => history.push(`/home/loan/singleview/${loanId}`)}
                                    >Back</button>
                                </div>
                            </CardBody>
                        </Card>
                    </ViewContent>
                    <Modal size='xl' show={this.state.isModalVisible} onHide={this.handleModal}>
					<Modal.Header closeButton>
						<Modal.Title>Loan application and Offer details</Modal.Title>
					</Modal.Header>

					<Modal.Body>
							<div>
					<SingleLoanView showOffer={this.state.offerData}/>
							</div>
					</Modal.Body>

					<Modal.Footer>
						<Button
							onClick={ this.handleModal}
							className='bg-transparent border text-dark shadow-none'
						>
							Close
						</Button>
					</Modal.Footer>
				</Modal>
                </div>
            );
    }
}