import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { cashFlowSetup, three , bankNames , checkForEmpty} from '../../constants/constants';
import DocumentUploader from './dropzone';
import ViewUploadedFiles from './ViewUploadeFiles';
import { Select , MenuItem } from '@material-ui/core';

@inject('businessProfileStore')
@observer
class CashFlowRecords extends Component {
  constructor(props) {
    super(props);
    this.state = { cashFlowList: [], disabled: true ,
    bankAccountDetails :'' };
  }
  componentDidMount() {
    const { cashFlowRecords ,bankAccountDetails } = this.props.businessProfileStore;
    let cashFlowData = cashFlowRecords;
    if (cashFlowRecords.length === 0) {
      cashFlowData = cashFlowSetup();
    }
    cashFlowData = cashFlowData.map(monthData => {
      return {
        month: monthData.monthYear ? monthData.monthYear: monthData,//NOSONAR
        amount: monthData.bankBalance ?monthData.bankBalance: '',//NOSONAR
        businessFinancials: monthData.businessFinancials ?monthData.businessFinancials:null,//NOSONAR
        id: monthData.id ?monthData.id:null,//NOSONAR
        verified: monthData.verified ?monthData.verified : false};//NOSONAR
    });
    const bankAccountDetailsObj = {
      accountNumber : bankAccountDetails.accountNumber,
      accountType : bankAccountDetails.accountType,
      branchCode : bankAccountDetails.branchCode,
      bankName : bankAccountDetails.bankName||'',
      bankStatements : bankAccountDetails.bankStatements};
    this.setState({ cashFlowList: cashFlowData , bankAccountDetails:bankAccountDetailsObj});
  }
  handleChange = (e, index) => {
    const value = e.target.value;
    this.setState(prev => ({
      cashFlowList: prev.cashFlowList.fill(
        { ...prev.cashFlowList[index], amount: value, dirty: true },
        index,
        index + 1
      ),
      disabled: false}));
    const {fieldsToUpdate } = this.props.businessProfileStore;
    fieldsToUpdate.cashFlowDirty = true;
  };

  handleSave = async e => {
    e.preventDefault();
    const {fieldsToUpdate } = this.props.businessProfileStore;
    if(fieldsToUpdate.bankAccountDetailsDirty){
   await this.props.businessProfileStore.postBankAccountDetails(this.state.bankAccountDetails);
    }
    if(fieldsToUpdate.cashFlowDirty){
   await this.props.businessProfileStore.postCashFlowRecords(this.state.cashFlowList);
    }
    await  this.props.businessProfileStore.getUpdatedData();
    this.setState({disabled:true});
  };

  handleRemoveDocument = (e, documentId) => {
    this.props.businessProfileStore.businessProfile.businessFinancials.bankStatements = this.props.businessProfileStore.businessProfile.businessFinancials.bankStatements.filter(
      document => document.id !== documentId
    );
  };

  bankDetailsChange = e => {
    const {fieldsToUpdate} = this.props.businessProfileStore;
    fieldsToUpdate.bankAccountDetailsDirty = true;
   const {value , name} = e.target;
   this.setState(prev => ({bankAccountDetails:{...prev.bankAccountDetails,[name]:value}}),()=>{
     const bankAccountDetailsObj = {...this.state.bankAccountDetails};
     delete bankAccountDetailsObj.bankStatements;
    const requiredCheck = checkForEmpty(bankAccountDetailsObj);
    if(requiredCheck){
      this.setState({disabled:true});
    }else{
      this.setState({disabled:false});
    }
   });
  }
  render() { //NOSONAR
    const { businessProfileStore } = this.props;
    const {isVerified} = this.props.businessProfileStore;
    const {bankAccountDetails} = this.state;
    const { confirmationLetter } = this.props.businessProfileStore.bankAccountDetails;
    const fileInfo = {
      files: [],
      path: '/public/upload',
      refId: businessProfileStore.businessProfile.businessFinancials._id,
      ref: 'businessfinancials',
      source: 'content-manager',
      field: 'bankStatements'};
      const fileInfoAccountDetails = {
        files: [],
        path: '/public/upload',
        refId: this.props.businessProfileStore.bankAccountDetails._id,
        ref: 'bankaccountdetails',
        source: 'content-manager',
        field: 'confirmationLetter'};
    return (
      <section>
        <div className="p-h-15 p-v-20">
          <p>
            The transactional bank account of a business is a good indicator of
            the business cash flows and seasonality. We require the past 3
            months Bank Balance to conduct this assessment.{' '}
          </p>
        </div>
        <div className="p-h-15 p-v-20">
          <div>
            <h5>Bank Account Balances {isVerified && <span className="badge text-bold"> - {(isVerified.businessFinancialsVerified && 'Verified')||'Not Verified'}</span>}</h5>
            <div className="p-h-10">
              <form
                className="m-t-15"
                method="post"
                encType="multipart/form-data"
              >
                {this.state.cashFlowList.map((cashFlow, index) => (
                  <div className="form-group row" key={cashFlow.month}>
                    <label className="col-sm-2 col-form-label control-label text-sm-right">
                      {cashFlow.month}
                    </label>
                    <div className="col-sm-10">
                      <input
                        id={cashFlow.month}
                        name={cashFlow.month}
                        type="number"
                        className="form-control cashFlows"
                        placeholder="R"
                        value={cashFlow.amount}
                        onChange={Event => this.handleChange(Event, index)}
                      />
                    </div>
                  </div>
                ))}
              </form>
              <div className="row justify-content-end">
                <div className="col-12">
                  {businessProfileStore.businessProfile.businessFinancials
                    .bankStatements.length < three &&
                  businessProfileStore.businessProfile.businessFinancials
                    .id && this.state.disabled && (
                    <DocumentUploader
                      fileData={fileInfo}
                      maxFiles={
                        three -
                        Number(
                          businessProfileStore.businessProfile
                            .businessFinancials.bankStatements.length
                        )
                      }
                      multiple={true}
                      inputContent={'Upload Bank Statements'}
                    />
                  ) }
                </div>
                <div className="w-100 d-flex justify-content-center my-2">
                  {businessProfileStore.businessProfile.businessFinancials
                    .bankStatements.length > 0 && (
                    <ViewUploadedFiles
                      title="Bank Statements"
                      data={
                        businessProfileStore.businessProfile.businessFinancials
                          .bankStatements
                      }
                      handleRemoveDocument={this.handleRemoveDocument}
                    />
                  )}
                </div>
              </div>
              <div>
            <h5>Banking Details {isVerified && <span className="badge text-bold"> - {(isVerified.businessFinancialsVerified && 'Verified')||'Not Verified'}</span>}</h5>
            <div className="p-h-10">
              <form
                className="m-t-15"
                method="post"
                encType="multipart/form-data"
              >
                  <div className="form-group row" >
                    <label className="col-sm-2 col-form-label control-label text-sm-right">
                   Bank Account Number
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="number"
                        className="form-control "
                        name = "accountNumber"
                        defaultValue={bankAccountDetails && bankAccountDetails.accountNumber}
                        onChange={ e => this.bankDetailsChange(e)}
                      />
                    </div>
                  </div>
                  <div className="form-group row" >
                    <label className="col-sm-2 col-form-label control-label text-sm-right">
                   Bank Account Type
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        name = "accountType"
                        className="form-control "
                        defaultValue={bankAccountDetails && bankAccountDetails.accountType}
                        onChange={ e => this.bankDetailsChange(e)}
                      />
                    </div>
                  </div>
                  <div className="form-group row" >
                    <label className="col-sm-2 col-form-label control-label text-sm-right">
                   Branch Code
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        name = "branchCode"
                        className="form-control "
                        defaultValue={bankAccountDetails && bankAccountDetails.branchCode}
                        onChange={ e => this.bankDetailsChange(e)}
                      />
                    </div>
                  </div>
                  <div className="form-group row" >
                    <label className="col-sm-2 col-form-label control-label text-sm-right">
                   Bank Name
                    </label>
                    <div className="col-sm-10">
                      <Select value={bankAccountDetails && bankAccountDetails.bankName
                      } onChange={this.bankDetailsChange}  className='w-100'  name = "bankName">
     {bankNames.map(bankName => <MenuItem value={bankName} key={bankName}>{bankName}</MenuItem>)}
        </Select>
                    </div>
                  </div>
              </form>
              </div>
              </div>
              <div className="row justify-content-end">
                <div className="col-12">
                  {!confirmationLetter && this.state.disabled && (
                    <DocumentUploader
                      fileData={fileInfoAccountDetails}
                      maxFiles={1}
                      multiple={true}
                      inputContent={'Upload Bank Confirmation letter'}
                    />
                  ) }
                </div>
                <div className="w-100 d-flex justify-content-center my-2">
                  {confirmationLetter && (
                    <ViewUploadedFiles
                      title="Bank Confirmation letter"
                      data={[confirmationLetter]}
                      handleRemoveDocument={this.handleRemoveDocument}
                    />
                  )}
                </div>
              </div>
              <div className="text-sm-right">
              {this.props.businessProfileStore.businessProfile.isEditable &&
                <button
                  className="btn btn-info"
                  id="submitCashFlow"
                  onClick={this.handleSave}
                  disabled={this.state.disabled}
                >
                  Save
                </button>
  }
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CashFlowRecords;
