
import { observable, action } from 'mobx';
import { Auth, Profiles, User, AuditTrails, Statuses, Verifyid } from '../services/requests';
import businessProfileStore from './businessProfileStore';
import storageUtil from './storageUtil';



export class CommonStore {

    /* App Logic */
    @observable profileVerified = false;
    @observable isIdle = true;
    @observable onlyProfileView = false;
    @observable isProfileViewError = false;
    @observable getPublicProfile = false;
    @observable isLoading = false;
    @observable isShow = false;
    @observable smsg = '';
    @observable tourStep =  Number(storageUtil.getItem('isTourOpen')) || 1;
    @observable isTourOpen= storageUtil.getItem('isTourOpen')==='false' ? false : true;
    @observable appLoaded = false;
    @observable navMini = false;
    @observable auth = (storageUtil.hasItem('usr') && storageUtil.hasItem('tkn')) || false;
    @observable token = storageUtil.getItem('tkn');
    @observable user = storageUtil.getItem('usr') || {};
    @observable statusus = [];
    @observable businessProfile = storageUtil.getItem('businessProfile') || {
        'id': null,
        'businessName': null,
        'businessEmail': null,
        'profileVerified': false,
        'complete': false,
        'isIdle': true,
        'loansProfile': null,
        'latestloanstatus': null,
        'percCompleted': 0,
        'user': null,
        'cipcRegNo': null,
        'companyDetails': null,
        'businessFinancials': null,
        'pastInvoices': null,
        'verifyid': null,
        'profileSetup': false,
        bankaccountdetail: null,
        isEditable: false,
        isTourOpen:false,
        tourStep:1
    };

    @observable adminData = {
        isAdmin: false,
        hasAdminData: false,
        businessProfiles: {
            data: null,
            details: {
                count: null,
                pageSize: null,
                page: null
            }
        },
        verifyid: null,
        viewData: null
    };
    @observable msg = '';
    @observable open = false;
    constructor() {
        const _this = this;
        const sec = 500;
        setTimeout(() => {
            _this.setAppLoaded();
        }, sec);

    }

    @action setTourStep(step) {
        this.tourStep = step;
        storageUtil.setItem('tourStep', step);
      }

    @action setAppLoaded() {
        this.appLoaded = true;
    }
    // Helper Async ForEach
    async asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

    @action async login(businessEmailAddress, password) {
        this.setLoading(true);
        const ret = false;

        const args = {
            identifier: businessEmailAddress,
            password
        };

        const retObj = await Auth.login(args);
        const usrObj = retObj.data;
        if (usrObj && usrObj.user && usrObj.user.email) {
            if (usrObj.jwt) {
                this.setToken(usrObj.jwt);
            }

            const newUsr = {};
            Object.keys(usrObj.user).forEach(key => {
                newUsr[key] = usrObj.user[key];
            });

            // Is Admin
            if (newUsr.role === 'Administrator') {
                this.adminData.isAdmin = true;
                const query = `_limit=10&_start=0`
                await this.getAdminData(query);
                // Async, don't need to wait ;)
            }

            this.setUser(newUsr);

        }
        this.setLoading(false);
        return ret;
    }

    @action async isAdmin() {
        const usr = storageUtil.getItem('usr');
        // Is Admin
        if (usr && usr.role && usr.role.name === 'Administrator') {
            this.adminData.isAdmin = true;
            const query = `_limit=10&_start=0`
            await this.getAdminData(query);
            // Async, don't need to wait ;)
        }
    }

    @action async searchItem(query) { 
        const item = await Profiles.search(query)
        const data = await item.data
        return data
    }

    @action async getAdminData(query, enableLoader) {
        if (enableLoader) {
            this.setLoading(true);
   
            const ret = await Profiles.getAll(query);
            const balance = await Verifyid.getCredit();
            const businessProfiles = ret.data.data || null;
            const profileDetails = ret.data || null;
   
            const verifyidCredits = await balance.data;
            const creditData = await verifyidCredits.credits.Result.credits;
            const credit = await JSON.parse(creditData);
            if (businessProfiles && businessProfiles.length > 0) {
                const _bp = [];
                await this.asyncForEach(businessProfiles, async (bp) => {
                    if (bp.loansProfile && (bp.loansProfile.id || bp.loansProfile._id)) {
                        const details = await businessProfileStore.getLoanDetails(bp.loansProfile.id || bp.loansProfile._id);
                        bp.loansProfile.loanApplications = details;
                    }
                    _bp.push(bp);
                });
                const verifyid = businessProfiles.map((data) => {
                    return data.verifyid
                })
   
                this.adminData.hasAdminData = true;
                this.adminData.businessProfiles = {
                    data: _bp,
                    details: {
                        count: profileDetails.count,
                        pageSize: profileDetails.pageSize,
                        page: profileDetails.page
                    }
                };
                this.adminData.verifyidCredits = await credit;
                this.adminData.verifyid = verifyid;
                this.setLoading(false);
            }
        } else {
            const ret = await Profiles.getAll(query);
            const balance = await Verifyid.getCredit();
   
            const businessProfiles = ret.data.data || null;
            const profileDetails = ret.data || null;
   
            const verifyidCredits = await balance.data;
            const creditData = await verifyidCredits.credits.Result.credits;
            const credit = await JSON.parse(creditData);
            if (businessProfiles && businessProfiles.length > 0) {
                const _bp = [];
                await this.asyncForEach(businessProfiles, async (bp) => {
                    if (bp.loansProfile && (bp.loansProfile.id || bp.loansProfile._id)) {
                        const details = await businessProfileStore.getLoanDetails(bp.loansProfile.id || bp.loansProfile._id);
                        bp.loansProfile.loanApplications = details;
                    }
                    _bp.push(bp);
                });
                const verifyid = businessProfiles.map((data) => {
                    return data.verifyid
                })
   
                this.adminData.hasAdminData = true;
                this.adminData.businessProfiles = {
                    data: _bp,
                    details: {
                        count: profileDetails.count,
                        pageSize: profileDetails.pageSize,
                        page: profileDetails.page
                    }
                };
                this.adminData.verifyidCredits = await credit;
                this.adminData.verifyid = verifyid;
   
            }
        }
    }

    @action async register(businessEmailAddress, password) {
        this.setLoading(true);
        const retObj = await Auth.register(businessEmailAddress, businessEmailAddress, password);
        const usrObj = retObj.data;

        if (usrObj && usrObj.user && usrObj.user.email) {
            const newUsr = {};
            Object.keys(usrObj.user).forEach(key => {
                newUsr[key] = usrObj.user[key];
            });

            this.setUser(newUsr);

            if (usrObj.jwt) {
                this.setToken(usrObj.jwt);
            }
            this.setAuth(true);

        }
    }

    @action async blockAccount() {

        const data = {
            'blocked': true
        };
        this.setLoading(true);
        await User.edit(this.user._id, data);
        this.setLoading(false);
    }

    @action async addBusinessProfile(businessName, businessEmailAddress, cipcRegNo) {

        const businessProfile = {
            businessName,
            cipcRegNo,
            businessEmail: businessEmailAddress,
            profileVerified: false,
            isIdle: true,
            user: this.user._id,
            profileSetup: false
        };

        const retObj = await Profiles.add(businessProfile);
        const businessProfileObj = retObj.data;
        if (businessProfileObj && businessProfileObj.cipcRegNo && businessProfileObj.businessEmail) {
            this.joinProfileAndUser(businessProfileObj.id);
        }

    }


    @action setLoading(val) {
        this.isLoading = val;
    }

    @action setNavMini(val) {
        this.navMini = val;
    }

    @action getToken(stop = false) {

        if (!stop) {
            if (this.token) {
                return this.token;
            } else {
                const tkn = storageUtil.getItem('tkn');
                if (tkn) {
                    this.setToken(tkn);
                    this.getToken(true);
                } else {
                    return null;
                }
            }
        }
        return this.token;
    }

    @action setToken(token) {
        this.token = token;
        storageUtil.setItem('tkn', token);
    }
    @action setError(msg) {
        this.msg = msg;
        this.open = true;
    }
    @action setMsg(msg,isShow=true) {
        this.smsg = msg;
        this.isShow = isShow;
    }

    @action setUser(usr) {
        this.user = usr;
        storageUtil.setItem('usr', usr);

        this.loadStaticData();
    }


    @action setAuth(status) {
        this.auth = status;
    }

    @action logout() {
        this.setToken(null);
        this.setAuth(false);
        storageUtil.removeItem('tkn');
        storageUtil.removeItem('usr');
        storageUtil.removeItem('profileID');
        storageUtil.removeItem('companyDetailsID');
        storageUtil.removeItem('businessProfile');
    }

    @action async forgetPassword(businessEmailAddress) {
        await Auth.forgotPass(businessEmailAddress);
    }

    @action async resetPass(code, password) {

        await Auth.resetPass(code, password);

    }
    @action async handleTour(isTourOpen,tourStep,updateDB = true){
        try{
        const tourCloseObj = { isTourOpen,tourStep };
        storageUtil.setItem('isTourOpen', isTourOpen);
        storageUtil.setItem('tourStep', tourStep);
        if(updateDB){
        const { data } = await Profiles.edit(
            this.businessProfile.id,
            tourCloseObj
        );
        return data;
        }
        return false;
        }catch(e){
            console.log(e);
            return false;
        }
    }
    @action async setBusinessProfile(businessProfile) {
        // Check if the businessProfile has valid data before setting it
        if (businessProfile && typeof businessProfile === 'object' && Object.keys(businessProfile).length > 0) {
            this.businessProfile = businessProfile;
            businessProfileStore.businessProfile = businessProfile;
    
            if ([undefined, null].includes(storageUtil.getItem('isTourOpen'))) {
                storageUtil.setItem('isTourOpen', businessProfile.isTourOpen);
                this.isTourOpen = businessProfile.isTourOpen;
            }
    
            if ([undefined, null].includes(storageUtil.getItem('tourStep'))) {
                storageUtil.setItem('tourStep', businessProfile.tourStep);
                this.tourStep = businessProfile.tourStep;
            }
    
            if (!this.businessProfile.profileSetup) {
                // Business profile sections not set up yet
                const { data } = await Profiles.edit(this.businessProfile.id, { 'profileSetup': true });
                if (data && data.profileSetup) {
                    businessProfile.profileSetup = true;
                    storageUtil.setItem('businessProfile', businessProfile);
                }
                businessProfileStore.businessProfile = businessProfile;
                await businessProfileStore.setUpProfileSections(this.user.profile);
                this.setLoading(false);
            } else {
                // Business profile sections already set up
            }
        } else {
            this.isProfileViewError = true;
        }
    }
    

    @action async getBusinessProfile(profileID) { //NOSONAR
        if (profileID) {
            const params = [
                'user:role',
                'companyDetails:businessAddress',
                'companyDetails:companyDirectors',
                'businessFinancials:annualBalanceSheet',
                'businessFinancials:annualIncomeStatement',
                'businessFinancials:accountantDetails',
                'businessFinancials:cashflowRecords',
                'pastInvoices:debtorInvoices',
                'verifyid:verifyid'
            ];
            try {
                let retObj = '';
                if (this.getPublicProfile) {
                    retObj = await Profiles.getPublic(profileID, params.join(','));
                } else {
                    retObj = await Profiles.get(profileID, params.join(','));
                }
                const businessProfileObj = retObj.data;
                // Validate verifyid and set default if not valid
                const verifyidCheck = businessProfileObj.verifyid || 1;
                // Check if required fields are present and valid
                const isValidProfile = businessProfileObj && businessProfileObj.cipcRegNo && businessProfileObj.businessEmail && businessProfileObj._id && businessProfileObj.user && verifyidCheck;
                if (isValidProfile) {
                    const newProfile = {};
                    Object.keys(this.businessProfile).forEach(key => {
                        newProfile[key] = businessProfileObj[key] !== undefined && businessProfileObj[key] !== null ? businessProfileObj[key] : ''; //NOSONAR
                    });
                    await this.setBusinessProfile(newProfile);
                } else {
                    this.isProfileViewError = true;
                }
            } catch (e) {
                this.isProfileViewError = true;
            }
        } else {
            this.businessProfile = null;
            this.isProfileViewError = true;
        }
    }
    
    @action async addAuditTrail(status, profile, type, rest) {
        if (!profile || profile === undefined) { //NOSONAR
            const usr = storageUtil.getItem('usr');
            profile = usr.profile || null;
        }

        if (profile) {
            const data = {
                name: 'Loan Status Changed',
                status,
                profile,
                type,
                typeId: profile,
                ...rest
            };
            await AuditTrails.add(data);
        }
    }

    @action async loadStaticData() {
        const ret = await Statuses.getAll();
        if (ret && ret.data) {
            this.statusus = ret.data;
        }
    }

    // Add profile to user
    @action async joinProfileAndUser(profileID) {

        const data = {
            'profile': profileID
        };

        await User.edit(this.user._id, data);
        this.setLoading(false);
    }

}

const commonStore = new CommonStore();
export default commonStore;
