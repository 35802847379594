import React, { useState } from 'react';

const SageModalData = ({ data }) => {
	console.log(data);
	const [collapse, setCollapse] = useState('');
	const toggleCollapse = key => {
		if (key === collapse) {
			setCollapse('');
		} else {
			setCollapse(key);
		}
	};

	if (!data || !data.assets) {
		return <div className='alert'>No data available</div>
	}
	const {
		assets,
		purchaseOrder,
		recurringInvoice,
		supplier,
		supplierBankDetail,
		supplierInvoice,
	} = data;
	return (
		<>
			<div style={{ marginTop: '20px' }}>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<button
							className='btn btn-primary'
							type='button'
							onClick={() => toggleCollapse('supplier')}
						>
							Supplier
						</button>
						{collapse === 'supplier' ? (
							<div id='supplier'>
								<div className='table-responsive'>
									<table className='table'>
										<thead>
											<tr>
												<th>Name</th>
												<th>Category</th>
												<th>Contact Name</th>
												<th>Telephone</th>
												<th>Mobile</th>
												<th>Email</th>
												<th>Active</th>
												<th>Balance</th>
												<th>Credit Limit</th>
											</tr>
										</thead>
										<tbody>
											{supplier.Results.map((row, index) => (
												<tr key={`sage_${index}`}>
													<td>{row.Name}</td>
													<td>{`
                          Modified: ${row.Category.Modified},
                          Created: ${row.Category.Created}`}</td>
													<td>{row.ContactName}</td>
													<td>{row.Telephone}</td>
													<td>{row.Mobile}</td>
													<td>{row.Email}</td>
													<td>{row.Active}</td>
													<td>{row.Balance}</td>
													<td>{row.CreditLimit}</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						) : null}
						<button
							className='btn btn-primary'
							type='button'
							onClick={() => toggleCollapse('supplierBankDetail')}
						>
							Recurring Invoice
						</button>
						{collapse === 'supplierBankDetail' ? (
							<div id='supplierBankDetail'>
								<div className='table-responsive'>
									<table className='table'>
										<thead>
											<tr>
												<th>Bank Account Holder</th>
												<th>Bank Account Number</th>
												<th>Bank Name</th>
												<th>Bank Branch Code</th>
												<th>Bank Account Type</th>
											</tr>
										</thead>
										<tbody>
											{supplierBankDetail.Results.map((row, index) => (
												<tr key={`sage_${index}`}>
													<td>{row.BankAccountHolder}</td>
													<td>{row.BankAccountNumber}</td>
													<td>{row.BankBranchCode}</td>
													<td>{row.BankAccountType}</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						) : null}
						<button
							className='btn btn-primary'
							type='button'
							onClick={() => toggleCollapse('supplierInvoice')}
						>
							Recurring Invoice
						</button>
						{collapse === 'supplierInvoice' ? (
							<div id='supplierInvoice'>
								<div className='table-responsive'>
									<table className='table'>
										<thead>
											<tr>
												<th>Due Date</th>
												<th>Paid</th>
												<th>Supplier Name</th>
												<th>Supplier</th>
												<th>Telephone</th>
												<th>Mobile</th>
												<th>Email</th>
												<th>Active</th>
												<th>Balance</th>
												<th>Credit Limit</th>
											</tr>
										</thead>
										<tbody>
											{supplierInvoice.Results.map((row, index) => (
												<tr key={`sage_${index}`}>
													<td>{row.DueDate}</td>
													<td>{row.Paid}</td>
													<td>{row.SupplierName}</td>
													<td>{`${row.Supplier.name} ${row.Supplier.Category.Description} ${row.Supplier.Category.Modified} ${row.Supplier.Category.Created}`}</td>
													<td>{row.Telephone}</td>
													<td>{row.Mobile}</td>
													<td>{row.Email}</td>
													<td>{row.Active}</td>
													<td>{row.Balance}</td>
													<td>{row.CreditLimit}</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						) : null}
						<button
							className='btn btn-primary'
							type='button'
							onClick={() => toggleCollapse('assets')}
						>
							Assets
						</button>
						{collapse === 'assets' ? (
							<div id='assets'>
								<div className='table-responsive'>
									<table className='table'>
										<thead>
											<tr>
												<th>Description</th>
												<th>Category</th>
												<th>Location</th>
												<th>Date Purchased</th>
												<th>Serial Number</th>
												<th>Purchase Price</th>
												<th>Current Value</th>
												<th>Replacement Value</th>
											</tr>
										</thead>
										<tbody>
											{assets.Results.map((row, index) => (
												<tr key={`sage_${index}`}>
													<td>{row.Description}</td>
													<td>{row.Category.Description}</td>
													<td>{row.Location.Description}</td>
													<td>{row.Location.Description}</td>
													<td>
														{new Date(row.DatePurchased).toLocaleString()}
													</td>
													<td>{row.SerialNumber}</td>
													<td>{row.PurchasePrice}</td>
													<td>{row.CurrentValue}</td>
													<td>{row.ReplacementValue}</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						) : null}
						<button
							className='btn btn-primary'
							type='button'
							onClick={() => toggleCollapse('purchaseOrder')}
						>
							Purchase Order
						</button>
						{collapse === 'purchaseOrder' ? (
							<div id='purchaseOrder'>
								<div className='table-responsive'>
									<table className='table'>
										<thead>
											<tr>
												<th>DeliveryDate</th>
												<th>Status</th>
												<th>Supplier Name</th>
												<th>Supplier</th>
												<th>Telephone</th>
												<th>Mobile</th>
												<th>Email</th>
												<th>Active</th>
												<th>Balance</th>
												<th>Credit Limit</th>
											</tr>
										</thead>
										<tbody>
											{purchaseOrder.Results.map((row, index) => (
												<tr key={`sage_${index}`}>
													<td>{row.DeliveryDate}</td>
													<td>{row.Status}</td>
													<td>{row.SupplierName}</td>
													<td>{`${row.Supplier.name} ${row.Supplier.Category.Description} ${row.Supplier.Category.Modified} ${row.Supplier.Category.Created}`}</td>
													<td>{row.Telephone}</td>
													<td>{row.Mobile}</td>
													<td>{row.Email}</td>
													<td>{row.Active}</td>
													<td>{row.Balance}</td>
													<td>{row.CreditLimit}</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						) : null}
						<button
							className='btn btn-primary'
							type='button'
							onClick={() => toggleCollapse('recurringInvoice')}
						>
							Recurring Invoice
						</button>
						{collapse === 'recurringInvoice' ? (
							<div id='recurringInvoice'>
								<div className='table-responsive'>
									<table className='table'>
										<thead>
											<tr>
												<th>Schedule</th>
												<th>Recurring Profile</th>
												<th>Contact Name</th>
												<th>Telephone</th>
												<th>Mobile</th>
												<th>Email</th>
												<th>Active</th>
												<th>Balance</th>
												<th>Credit Limit</th>
											</tr>
										</thead>
										<tbody>
											{recurringInvoice.Results.map((row, index) => (
												<tr key={`sage_${index}`}>
													<td>{`
                           ScheduleFrequencyId: ${row.Schedule.ScheduleFrequencyId},
                           StartDate: ${row.Schedule.StartDate},
                           EndDate: ${row.Schedule.EndDate},
                           Active: ${row.Schedule.Active},
                           LastRunDate: ${row.Schedule.LastRunDate},
                           NextRunDate: ${row.Schedule.NextRunDate}`}</td>
													<td>{`CarbonCopyEmail: ${row.RecurringProfile.CarbonCopyEmail}`}</td>
													<td>{row.ContactName}</td>
													<td>{row.Telephone}</td>
													<td>{row.Mobile}</td>
													<td>{row.Email}</td>
													<td>{row.Active}</td>
													<td>{row.Balance}</td>
													<td>{row.CreditLimit}</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						) : null}
					</div>

			</div>
		</>
	);
};

export default SageModalData;
