import React from 'react'
import { Progress } from '@Comp'
import numeral from 'numeral';

const OverallStatus = ({ noOfBusinessProfiles, noOfBusinessProfilesComplete, noOfLoanApplications, noOfLoanApplicationsComplete }) => {
    return (
        <div className="row">
            <div className="col-md-6">
                <div className="card">
                    <div className="card-body">
                        <span className="status info" />
                        <span className="m-b-10 font-size-16 m-l-5">Business Profiles Verified</span>
                        <div className="float-right">
                            <b className=" font-size-18 text-dark">{(noOfBusinessProfiles > 0 && noOfBusinessProfilesComplete > 0) ? Math.round(noOfBusinessProfilesComplete / noOfBusinessProfiles * 100) : 0}%</b>

                        </div>
                        <div className="m-t-15 m-b-0">
                            <Progress multi className="mb-1 pbar">
                                <Progress bar color="b1" className="progress-bar-striped progress-sm  bg-info" value={Math.round(noOfBusinessProfilesComplete / noOfBusinessProfiles * 100)} />
                            </Progress>
                        </div>

                    </div>
                </div>
            </div>

            <div className="col-md-6">

                <div className="card">
                    <div className="card-body">
                        <span className="status gradient success" />
                        <span className="m-b-10 font-size-16 m-l-5">Loan Applications Completed</span>
                        <div className="float-right">
                            <b className=" font-size-18 text-dark">{(noOfLoanApplications > 0 && noOfLoanApplicationsComplete > 0) ? Math.round(noOfLoanApplicationsComplete / noOfLoanApplications * 100) : 0}%</b>
                        </div>

                        <div className="m-t-15 m-b-0">
                            <Progress multi className="mb-1 pbar">
                                <Progress bar color="b1" className="progress-bar-striped progress-sm  bg-gradient-success" value={Math.round(noOfLoanApplicationsComplete / noOfLoanApplications * 100)} />
                            </Progress>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
};

export default OverallStatus;