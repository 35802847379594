import React, { useState, useEffect } from 'react';
import { Form } from '@Comp';
import MaterialIcon from 'material-icons-react';
import DocumentUploader from '../dropzone';
import ViewUploadedFiles from '../ViewUploadeFiles';
import { inject } from 'mobx-react';
import validate from 'validate.js';
import { TextField } from '@material-ui/core';

const IS_REQUIRED = 'is required';
let formState = {
    isValid: false,
    values: {},
    touched: {},
    errors: {}
};
const Debtor = props => { //NOSONAR
    const {
        pastInvoice,
        onChange,
        onClick,
        index
    } = props;

    const handleRemoveDocument = () => {
        props.businessProfileStore.businessProfile.pastInvoices.debtorInvoices[
            props.index
        ].invoiceCopy = [];
    };
    const invoiceID = pastInvoice.id;
    const [cState, setcState] = useState('');

    const schema = {
        [`companyName-${invoiceID}`]: {
            presence: { allowEmpty: false, message: IS_REQUIRED }
        },
        [`invoiceAmount-${invoiceID}`]: {
            presence: { allowEmpty: false, message: IS_REQUIRED }
        },
        [`companyEmail-${invoiceID}`]: {
            presence: { allowEmpty: false, message: IS_REQUIRED },
            email: true
        }
    };

    const hasError = field => {
        let bool = false;
        if (formState.touched[field] && formState.errors[field]) {
            bool = true;
        }
        return bool;
    };
    const checkError = (field, id) => {
        const hasErrorf = hasError(field);
        if (hasErrorf) {
            const errors = { ...formState.errors };
            return errors[field][0].replace(id, '');
        }
        return null;
    };
    useEffect(() => {
        // eslint-disable-next-line
    }, [cState]);
    const handleChange = e => {
        e.persist();
        const { checked, value, type } = e.target;
        let name = value;
        if (type === 'checkbox') {
            name = checked;
        }
        formState = {
            ...formState,
            values: {
                ...formState.values,
                [e.target.name]: name
            },
            touched: {
                ...formState.touched,
                [e.target.name]: true
            }
        };
        const errors = validate(formState.values, schema);
        formState = {
            ...formState,
            isValid: errors ? false : true,//NOSONAR
            errors: errors || {}
        };
        setcState(value);
        onChange(e, invoiceID, index, formState.isValid);
    };

    const fileInfo = {
        files: [],
        path: '/public/upload',
        refId: pastInvoice.id,
        ref: 'debtorinvoice',
        source: 'content-manager',
        field: 'invoiceCopy'
    };
    return (
        <div>
            <Form className="m-t-15">
                <div className="removePastInvoice">
                    <span className="badge badge-danger">Remove Invoice</span>
                    {props.businessProfileStore.businessProfile.isEditable && <a
                        href=""
                        className="badge badge-danger"
                        onClick={e => onClick(e, invoiceID)}
                    >
                        <MaterialIcon icon="close" size={9} />
                    </a>
                    }
                </div>
                <br></br>

                <div className="form-group row">
                    <label className="col-sm-2 col-form-label control-label text-sm-right">
                        Company Name
                        </label>
                    <div className="col-sm-10">
                        <TextField
                            id={`companyName-${invoiceID}`}
                            name={`companyName-${invoiceID}`}
                            type="text"
                            className="form-control companyName"
                            placeholder=""
                            value={pastInvoice.companyName}
                            onChange={e => handleChange(e)}
                            error={hasError(`companyName-${invoiceID}`)}
                            helperText={
                                checkError(`companyName-${invoiceID}`, invoiceID)
                            }
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label control-label text-sm-right">
                        Contact Person Email
                        </label>
                    <div className="col-sm-10">
                        <TextField
                            id={`companyEmail-${invoiceID}`}
                            name={`companyEmail-${invoiceID}`}
                            type="email"
                            className="form-control companyEmail"
                            placeholder=""
                            value={pastInvoice.companyEmail}
                            onChange={e => handleChange(e)}
                            error={hasError(`companyEmail-${invoiceID}`)}
                            helperText={
                                checkError(`companyEmail-${invoiceID}`, invoiceID)
                            }
                        />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-2 col-form-label control-label text-sm-right">
                        Invoice Amount
                        </label>
                    <div className="col-sm-10">
                        <TextField
                            id={`invoiceAmount-${invoiceID}`}
                            name={`invoiceAmount-${invoiceID}`}
                            type="number"
                            className="form-control"
                            placeholder="R"
                            value={pastInvoice.invoiceAmount}
                            onChange={e => handleChange(e)}
                            error={hasError(`invoiceAmount-${invoiceID}`)}
                            helperText={
                                checkError(`invoiceAmount-${invoiceID}`, invoiceID)
                            }
                        />
                    </div>
                </div>
            </Form>
            <div className="row justify-conytent-end">
                <div className="col-1"></div>
                <div className="col-11">
                    {pastInvoice.invoiceCopy.length < 1 && pastInvoice._id && (
                        <DocumentUploader
                            fileData={fileInfo}
                            maxFiles={1}
                            multiple={false}
                            inputContent={'Upload Past Invoice'}
                        />
                    )}
                </div>
                <div className="w-100 d-flex justify-content-center my-2">
                    {pastInvoice.invoiceCopy.length > 0 && (
                        <ViewUploadedFiles
                            title="Past Invoice"
                            data={pastInvoice.invoiceCopy}
                            handleRemoveDocument={handleRemoveDocument}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
const DebtorState = inject('businessProfileStore')(Debtor);
export default DebtorState;

