import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from '@Comp';

@inject('viewStore', 'businessProfileStore', 'commonStore')
@observer
class TourComponents extends Component {
    constructor() {
        super();
        this.state = {
            isTourOpen: false};
    }
    closeTour = async () => {
        try {
            const data = await this.props.commonStore.handleTour(false, 12); //NOSONAR
            if (data) {
                this.props.commonStore.isTourOpen = false;
                this.props.commonStore.tourStep = 12;
            }
        } catch (e) {
            console.log(e); //NOSONAR
        }
    };
    stepsUpdate = async (step, close = false) => {
        if (close && this.props.commonStore.tourStep === 11) {
            await this.props.commonStore.handleTour(false, 12);
        }
        this.props.commonStore.isTourOpen = false;
        this.props.commonStore.tourStep = step;
    };

    render() {
        const { step, isMobileScreen } = this.props;
        if (step === 'one') {
            return (
                <div>
                    <h2 className= { isMobileScreen ? 'small' : ''} >
                        Welcome! We look forward to making your loan application process as
                        simple as possible. This wizard will show you step-by-step how to
                        get started.
                    </h2>
                    <strong className= { isMobileScreen ? 'small' : ''}>If you need to revisit the tour, or want to see additional helpful info, visit the react tour by clicking on the nisa logo in the right corner and select <em>"Help"</em> option. </strong>
                    <div className='d-flex mt-2'>
                        <Button onClick={this.closeTour} size={ isMobileScreen ? 'sm' : ''} className='ml-1'>
                            Skip the tutorial
                        </Button>
                    </div>
                </div>
            );
        }
        if (step === 'six') {
            return (
                <div>
                    <h2>
                        Go ahead and fill in your details. Don’t forget to hit save when you
                        are done!
                    </h2>
                    <div className='d-flex '>
                        <Button onClick={() => this.stepsUpdate(7)} className='ml-1'>
                            Next
                        </Button>
                    </div>
                </div>
            );
        }
        if (step === 'seven') {
            return (
                <div >
                    <h2 className={isMobileScreen ? 'small' : ''}>
                        If you would like us to autocomplete this part of the process.
                        Please click on autofill button. Or if you want to fill the data
                        manually click on below continue button and start filling your
                        details.
                    </h2>
                    <div className='d-flex '>
                        <Button onClick={() => this.stepsUpdate(8)} size={ isMobileScreen ? 'sm' : ''} className='ml-1'>
                            Continue
                        </Button>
                    </div>
                </div>
            );
        }
        if (step === 'eight') {
            return (
                <div >
                    <h2 className={isMobileScreen ? 'small' : ''}>
                        Now that your information has been stored, please upload the
                        corresponding documents of address and directors
                    </h2>
                    <div className='d-flex '>
                        <Button onClick={() => this.stepsUpdate(9)} size={ isMobileScreen ? 'sm' : ''} className='ml-1'>
                            Ok
                        </Button>
                    </div>
                </div>
            );
        }
        if (step === 'ten') {
            return (
                <div >
                    <h2 className={isMobileScreen ? 'small' : ''}>
                        This section is where we automate the verification of all the data
                        that you filled in your business profile. Click the + button to
                        connect the various verification plugins.{' '}
                    </h2>
                    <div className='d-flex '>
                        <Button onClick={() => this.stepsUpdate(11)} size={ isMobileScreen ? 'sm' : ''} className='ml-1'>
                            Ok
                        </Button>
                    </div>
                </div>
            );
        }
        if (step === 'eleven') {
            return (
                <div >
                    <h2 className={isMobileScreen ? 'small' : ''}>
                        Your business profile has reached 100%. Your dashboard and email
                        should now have a notification. All additional parts of the business
                        profile are now optional.
                    </h2>
                    <div className='d-flex '>
                        <Button onClick={() => this.stepsUpdate(12, true)} size={ isMobileScreen ? 'sm' : ''}  className='ml-1'>
                            Ok
                        </Button>
                    </div>
                </div>
            );
        }
        return <></>;
    }
}

export default TourComponents;
