import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Card, CardBody } from '@Comp';
import './style.scss';
import 'react-input-range/lib/css/index.css';
import LoanForm from './loan-form';
import LoanSummary from './loan-summary';
import DropZone from '../../business-profile-copy/dropzone';
import { HUNDRED, checkForEmpty } from '../../../constants/constants';

const ViewHeader = ({ isNew }) => (
	<div className="view-header">
		<header className="title text-white">
			{isNew && (
				<div>
					<h1 className="h4 text-uppercase">Edit Loan Application</h1>
					<p className="mb-0">Apply For a Loan to receive offers</p>
				</div>
			)}
			{!isNew && (
				<div>
					<h1 className="h4 text-uppercase">Apply For Loan</h1>
					<p className="mb-0">Apply For a Loan to receive offers</p>
				</div>
			)}
		</header>
	</div>
);

const ViewContent = ({ children }) => (
	<div className="view-content view-components">{children}</div>
);

@inject('commonStore', 'loanStore', 'businessProfileStore', 'viewStore')
@withRouter
@observer
export default class LoanApply extends Component {
	constructor(props) {
		super(props);
		this.state = { disabled: true, }
		// MobxState
		this.setGstate = this.props.viewStore.setLoanApplyState.bind(
			this.props.viewStore
		);
	}

	componentWillUnmount() {
		// this.setGstate({
		// 	id: null,
		// 	invoiceAmount: '',
		// 	invoiceBuyer: '',
		// 	contactPerson: '',
		// 	buyerEmail: '',
		// 	loanDescription: '',
		// 	buyerRelationship: 'supplyChain',
		// 	loanPercent: 20,
		// 	invoiceType: 'Purchase Order',
		// 	date: new Date()
		// });
	}

	doLoading = () => {
		this.props.commonStore.setLoading(true);
		let _this = this;
		// Clear
		setTimeout(() => {
			_this.props.commonStore.setLoading(false);
		}, 10 * 1000); //NOSONAR
	};

	async componentDidMount() {
		this.doLoading();
		const {
			history,
			loanStore,
			businessProfileStore,
			commonStore
		} = this.props;
		const profileID = businessProfileStore.businessProfile.id;
		const progress = await commonStore.businessProfile.percCompleted;
		if (profileID && progress >= HUNDRED) {
			loanStore
				.setUpLoansProfile(profileID)
				.then(ret => {
					commonStore.setLoading(false);
				})
				.catch(err => {
					commonStore.setLoading(false);
				});
		} else {
			commonStore.setLoading(false);
			businessProfileStore.setErr(
				'Incomplete business profile, please complete your business profile first.'
			);
			history.push('/home/business-profile');
		}
	}


	handleChange = e => {
		const target = e.target;
		//current input field being modified
		const value = target.value;
		const name = target.name;
		this.setGstate({
			[name]: value
		});
		const loanApplyStateClone = { ...this.props.viewStore.loanApplyState };
		delete loanApplyStateClone.id;
		const isRequired = checkForEmpty(loanApplyStateClone)
		//will return true if there is empty field
		if (isRequired && !this.state.disabled) {
			this.setState({ disabled: true });
		}
		if (!isRequired) {
			this.setState({ disabled: false });

		}
	};
	handleLoanPercent = value => {
		this.setGstate({
			loanPercent: value
		});
	};

	handleDateChange = date => {
		this.setGstate({
			startDate: date
		});
	};

	handleApplyForLoan = () => {
		const { loanStore, viewStore } = this.props;
		const { loanApplyState } = viewStore;
		if (loanApplyState.id) {
			// Update
			loanStore
				.updateLoanApplication(loanApplyState.id, loanApplyState)
				.then(ret => {
					console.log('loanStore.postLoanApplication', ret);
				})
				.catch(e => {
					console.error(e);
				});
		} else {
			loanStore
				.postLoanApplication(loanApplyState)

		}
	};
	uploadCallback = () => {
		const { viewStore, history } = this.props;
		const { loanApplyState } = viewStore;
		setTimeout(() => {
			history.push(`/home/loan/singleview/${loanApplyState.id}`);
		}, 1000);
	}

	render() {
		const { viewStore, history } = this.props;
		const { loanApplyState } = viewStore;
		const fileInfo = {
			files: [],
			path: '/public/upload',
			refId: loanApplyState.id,
			ref: 'loandetails',
			source: 'content-manager',
			field: 'invoice'
		};
		return (
			<div className="view">
				<ViewHeader isNew={loanApplyState.id && loanApplyState.id.length > 0} />

				<ViewContent>
					<Card className="mb-4">
						<CardBody>
							<h6 className="text-uppercase h6 card-title">
								Application Information
							</h6>
							<p>
								Here is where you supply your loan requirements. Please note
								that all information will be verified. If information is
								invalid, your request will be rejected.
							</p>
							<label className="badge badge-danger">
								Only Invoices Due Within 11 Months Are Accepted
							</label>
							<h6>Customise your loan application</h6>
							<LoanForm
								invoiceType={loanApplyState.invoiceType}
								invoiceAmount={loanApplyState.invoiceAmount}
								startDate={loanApplyState.startDate}
								invoiceBuyer={loanApplyState.invoiceBuyer}
								contactPerson={loanApplyState.contactPerson}
								buyerEmail={loanApplyState.buyerEmail}
								loanDescription={loanApplyState.loanDescription}
								buyerRelationship={loanApplyState.buyerRelationship}
								loanPercent={loanApplyState.loanPercent}
								handleLoanPercent={this.handleLoanPercent}
								handleDateChange={this.handleDateChange}
								handleChange={this.handleChange}
							/>
							<LoanSummary
								invoiceAmount={loanApplyState.invoiceAmount}
								invoiceBuyer={loanApplyState.invoiceBuyer}
								contactPerson={loanApplyState.contactPerson}
								buyerEmail={loanApplyState.buyerEmail}
								loanDescription={loanApplyState.loanDescription}
								loanPercent={loanApplyState.loanPercent}
							/>
							<div className="mx-3 mb-3">
								{loanApplyState.id && (<DropZone
									fileData={fileInfo}
									maxFiles={3}
									multiple={true}
									inputContent={'Upload Invoice/PO'}
									doNotDisable={true}
									callback={this.uploadCallback}
								/>
								)}
							</div>
							<div className="text-sm-right">
								<button
									className="btn btn-info"
									id="submitLoanApp"
									onClick={this.handleApplyForLoan}
									disabled={this.state.disabled || loanApplyState.id}
								>
									Proceed Application
								</button>
							</div>
							{loanApplyState.id && (
								<div className="text-sm-left">
									<button
										className="btn btn-danger"
										onClick={() => history.push('/home/loan/view')}
									>
										Back to All Loans
									</button>
								</div>
							)}
						</CardBody>
					</Card>
				</ViewContent>
			</div>
		);
	}
}
