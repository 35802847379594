import React,{useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {
    Form, Input, Label, FormGroup,
    Card, CardBody, Button, Row,FormText
} from '@Comp';
import { View } from '../../../shared/components';

import {
    TextField,
    Checkbox,
    Typography,
    FormHelperText
  } from "@material-ui/core";
  import { makeStyles } from "@material-ui/styles";

import images from '@assets';
import '../style.scss';
import {inject} from "mobx-react";
import validate from "validate.js";

import { history } from "../../../App";


const schema = {
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 8
    }
  },
  confirmPassword: {
    equality: "password",
    presence: { allowEmpty: false, message: "is required" }
  },

};

const ChangePassPage = ({commonStore}) =>{

    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
      });

  const [inputs, setInputs] = useState({
    password: "",
    confirmPassword: ""
  });


  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const [err, setErr] = useState(null);
  const useStyles = makeStyles(() => ({
    textField: {
      border: 2
    },
    policy: {
      marginTop: 2,
      display: "flex",
      alignItems: "center"
    }
  }));

  const handleChange = e => {
    const target = e.target; //current input field being modified
    const value = target.value;
    const name = target.name;
    setInputs(inputs => ({ ...inputs, [name]: value }));

    e.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [e.target.name]: e.target.value
      },
      touched: {
        ...formState.touched,
        [e.target.name]: true
      }
    }));
  };
  const classes = useStyles();
  
   const handleSubmit = (e) =>{
      e.preventDefault();
       
      //querystring 
      const path = history.location.search;
      const code = path.substring(path.indexOf('=')+1).trim();

      
      //  console.log(code);
      //  console.log(inputs.password);
       commonStore.setLoading(true);
       commonStore.resetPass(code,inputs.password).then((ret) => {
          setErr("Password successfully changed");
          commonStore.setLoading(false);
          history.push({
            pathname: "/login",
            state: { detail: "PasswordChanged" }
          });
        }).catch((e) => {
            console.error(e);
            setErr("Error updating password");
            commonStore.setLoading(false);
        });

   };
   const hasError = field =>
   formState.touched[field] && formState.errors[field] ? true : false;

    return(
        <View>
            <div className="view-content view-content-forgot view-pages view-session d-flex justify-content-center align-items-center flex-column">
            <Row>
                <Card className="mb-3 form-card">
                    <CardBody>
                        <header className="mb-4 text-center">
                            <img className="img-fluid" src={images.loginIcon} alt="" />
                            <p className="mt-4 small">Enter your new password.</p>
                        </header>
                        <Form onSubmit={handleSubmit}>
                        <FormGroup className="mb-4">
                        <TextField
                          type="password"
                          name="password"
                          placeholder="password minimum 8 characters"
                          value={inputs.password}
                          fullWidth={true}
                          onChange={handleChange}
                          error={hasError("password")}
                          helperText={
                            hasError("password") ? formState.errors.password[0] : null
                          }
                          className={classes.textField}
                        />
                        <FormText>Minimum length 8 characters</FormText>
                      </FormGroup>
                      <FormGroup className="mb-5">
                        <TextField
                          type="password"
                          name="confirmPassword"
                          placeholder="confirm password above"
                          value={inputs.confirmPassword}
                          fullWidth={true}
                          onChange={handleChange}
                          error={hasError("confirmPassword")}
                          helperText={
                            hasError("confirmPassword")
                              ? formState.errors.confirmPassword[0]
                              : null
                          }
                          className={classes.textField}
                        />
                        <p className="notification">{err}</p> 
                      </FormGroup>

                            <FormGroup className="text-right">
                                <Button color="info"  type="submit">Change Password</Button>
                            </FormGroup>
                        </Form>
                        <p>Already have an account? <Link to="/login">Login</Link></p>
                    </CardBody>
                </Card>
            </Row>
            </div>
        </View>
        )

};

const ChangePassword = inject("commonStore")(ChangePassPage);
export default ChangePassword;
