import React, { Component } from 'react';
import BusinessProfile from '../business-profile-copy';
import { inject, observer } from 'mobx-react';

@inject('commonStore', 'businessProfileStore')
@observer
class PublicProfile extends Component {
    constructor(){
        super();
        this.state = {
            userId : null};
        }
    async componentDidMount() {
        if (this.props.match && this.props.location) {
            const { userId } = this.props.match.params;
            if (userId) {
            this.props.commonStore.getPublicProfile = true;
            this.setState({userId});
            }
        }
    }
    render() {
        const {userId} = this.state;
        return (
            <div>
                {userId && !this.props.commonStore.isProfileViewError &&
                <BusinessProfile isViewable={true} id={userId}/>
    }
    {this.props.commonStore.isProfileViewError && <>
    <h1>Something went wrong , make sure you have entered right URL.</h1>
    </>}
            </div>
        );
    }
}

export default PublicProfile;
