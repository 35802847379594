import React, { Component } from 'react';
import { Button } from '@Comp';
import BusinessAddress from './business-address';
import CompanyDirectors from './company-directors/index';
import CompanyRegCert from './company-reg-cert';
import { inject, observer } from 'mobx-react';
import images from '../../../assets';
import swal from 'sweetalert';

@inject('businessProfileStore', 'verifyIdStore', 'commonStore')
@observer
class CompanyDetailsPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errorFields: {
				businessAddressFields: {},
				directorFields: {},
			},
			isVisible: true,
			fetchClick: false,
		};
		this.isVisible = true;
		this.fieldsToCheckBusinessAddress = [
			'unitNo',
			'streetName',
			'complete',
			'city',
			'province',
			'postalCode',
		];
		this.fieldsToCheckBusinessDirector = [
			'firstName',
			'lastName',
			'idNo',
			'email',
			'cellNo',
		];
	}
	handleSave = e => {
		e.preventDefault();
		const sectionsToUpdate = this.props.businessProfileStore.fieldsToUpdate;
		this.detailsToPost(sectionsToUpdate).then(() => {
			this.props.businessProfileStore.getUpdatedData();
			const { commonStore } = this.props;
			if (commonStore.tourStep === 8) {
				commonStore.isTourOpen = true;
			}
			this.setState({ isVisible: true });
		});
	};

	detailsToPost = async sectionsToUpdate => {
		if (sectionsToUpdate.businessAddressDirty) {
			if (this.props.businessProfileStore.businessAddress.companyDetails) {
				//update
				await this.props.businessProfileStore.postBusinessAddress(true);
			} else {
				//create new business address
				await this.props.businessProfileStore.postBusinessAddress(false);
			}
		}

		if (sectionsToUpdate.companyDirectorDirty) {
			for (
				let i = 0;
				i < this.props.businessProfileStore.companyDirectors.length;
				i++
			) {
				const director = this.props.businessProfileStore.companyDirectors[i];
				if (this.props.businessProfileStore.companyDirectors[i]._id) {
					//already exists, so just needs to be updated
					await this.saveCompanyDirectors(director, true);
				} else {
					//does not exists need to be added
					await this.saveCompanyDirectors(director, false);
				}
			}
		}
	};

	saveCompanyDirectors = async (director, directorExists) => {
		await this.props.businessProfileStore.postCompanyDirector(
			director,
			directorExists
		);
	};

	formDataChange = (filedsToValidate, type = '', isErrorExist = '') => {
		if (isErrorExist === 'error') {
			this.setState({ isVisible: true });
			return true;
		}
		if (this.state.isVisible === true) {
			this.setState({ isVisible: false });
		}
		if (type === 'director') {
			this.fieldsToCheckBusinessDirector.map(field => {
				if (
					filedsToValidate[field] === '' ||
					filedsToValidate[field] === null
				) {
					this.setState({ isVisible: true });
				}
				return field;
			});
		} else {
			this.fieldsToCheckBusinessAddress.map(column => {
				if (
					filedsToValidate[column] === '' ||
					filedsToValidate[column] === null
				) {
					this.setState({ isVisible: true });
				}
				return column;
			});
		}
		return false;
	};
	fetchAutoFillData = async () => {
		const { verifyIdStore, businessProfileStore, commonStore } = this.props;
		const { verifyid } = this.props.businessProfileStore.businessProfile;
		commonStore.isTourOpen = false; //close the tour
		commonStore.tourStep = 8; //close the tour
		if (!verifyid) {
			await swal({
				text: 'What is the total number of directors listed on the company including resigned directors ?',
				content: 'input',
				button: {
					text: 'Submit!',
					closeModal: true,
				},
			})
				.then(async count => {
					if (!count) throw null;
					const { data } = await verifyIdStore.postVerifyId(false, count);
					if (data.status && data.countError) {
						return swal(data.error);
					}
					await businessProfileStore.getUpdatedData();
					await this.props.businessProfileStore.autoFillDetails();
					this.setState({ fetchClick: true });
					return swal(
						`Auto-complete of Directors and Address successful.
							Please ensure that the autocomplete details are correct before saving the information`
					);
				})
				.catch(err => {
					console.log(JSON.stringify(err));
				});
		} else {
			await this.props.businessProfileStore.autoFillDetails();
			this.setState({ fetchClick: true });
			return swal(
				`Auto-complete of Directors and Address successful.
				Please ensure that the autocomplete details are correct before saving the information`
			);
		}
	};
	componentDidUpdate() {
		this.checkIfFileExists();
	}
	checkIfFileExists = () => {
		const { commonStore } = this.props;
		if (commonStore.tourStep !== 9 || commonStore.isTourOpen) {
			return false;
		}
		const {
			businessAddress,
			companyDirectors,
		} = this.props.businessProfileStore.businessProfile.companyDetails;
		let isFileExist = true;
		if (businessAddress && businessAddress.proofOfAddress.length === 0) {
			isFileExist = false;
		}
		if (companyDirectors && isFileExist) {
			const checkIfFile = companyDirectors.some(
				director => director.certifiedID.length === 0
			);
			isFileExist = !checkIfFile;
		}
		if (isFileExist && !commonStore.isTourOpen) {
			commonStore.isTourOpen = true;
		}
	};
	render() {
		const { isVerified, directorAutofilled } = this.props.businessProfileStore;
		const { businessAddressFields, directorFields } = this.state.errorFields;
		if (
			Object.keys(businessAddressFields).length > 0 ||
			Object.keys(directorFields).length > 0
		) {
			this.isVisible = true;
		}
		const { fetchClick } = this.state;

		return (
			<section>
				<div className='p-h-15 p-v-20'>
					<p>
						This information forms the basis of your profile. We require this
						information in order to get you started.
					</p>
					<div style={{ height: '49px' }}>
						<label className='badge badge-danger'>
							All Fields Required For Progress
						</label>
						<label className='badge badge-danger ml-2'>
							Only PDF file formats are supported
						</label>
						<Button
							className='float-right mb-0 seventh-step'
							outline={true}
							color='info'
							onClick={this.fetchAutoFillData}
						>
							<img
								src={images.verifyidLogo}
								alt='verifyID'
								style={{ maxWidth: '100px' }}
							/>
							Autofill Available Data
						</Button>
					</div>

					<h5 >
						{' '}
						{isVerified && (
							<span className='badge text-bold'>
								{' '}
								- {(isVerified.companyVerified && 'Verified') || 'Not Verified'}
							</span>
						)}
					</h5>
					<div className='p-h-10'>
						<BusinessAddress
							formDataChange={this.formDataChange}
							fetchClick={fetchClick}
							isDocumentUploadVisible = {this.state.isVisible}
						/>
						<CompanyDirectors formDataChange={this.formDataChange} />
						<CompanyRegCert isDocumentUploadVisible = {this.state.isVisible} />
					</div>
				</div>
				<div className='float-right text-sm-right'>
					{this.props.businessProfileStore.businessProfile.isEditable && (
						<Button
							color='info'
							onClick={this.handleSave}
							disabled={this.state.isVisible}
						>
							Save
						</Button>
					)}
				</div>
			</section>
		);
	}
}
export default CompanyDetailsPage;
