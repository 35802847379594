import axios from 'axios';
import { config } from '../constants';
import storageUtil from '../stores/storageUtil';

// Config
const base = config.BASE_URL;
const prefix = config.BASE_URL_PREFIX;
const prefixAuth = config.BASE_URL_AUTH;
const forgotPasswordUrl = config.FORGOT_PASSWORD_URL;
const apiRoute = `${base}${prefix}`;
const authRoute = `${base}${prefixAuth}`;
const frontEndURL = config.FrontEndURL;
const forgotPassUrl = `${frontEndURL}${forgotPasswordUrl}`;
const contentType = 'application/json';
const getAccessToken = () => {
  let loginToken = '';
  if(storageUtil.hasItem('tkn')){
    loginToken = storageUtil.getItem('tkn');
  }
return loginToken;
};

// All normal requests, authenticated
const requests = axios.create({
  baseURL: apiRoute,
  headers: {
    'Content-Type': contentType,
    Accept: contentType,
    Authorization: `Bearer ${getAccessToken()}` }});

requests.interceptors.response.use(undefined, err => {
  const error = err.response;
  // if error is 401
  if (
    error &&
    error.status === 401 && error.config && //NOSONAR
    !error.config.__isRetryRequest
  ) {
    console.error('[Plz login again]'); //NOSONAR
  }
});

//console.log(commonStore.getToken());
// Auth and Un-authenticated requests
const requestsAuth = axios.create({
  baseURL: authRoute,
  headers: {
    'Content-Type': contentType,
    Accept: contentType}});
//other Un-authenticated routes
const unAuthRequest = axios.create({
  baseURL: apiRoute,
  headers: {
    'Content-Type': contentType,
    Accept: contentType}});

// All normal requests, authenticated
const uploadRequests = axios.create({
  baseURL: apiRoute,
  headers: {
    Authorization: `Bearer ${getAccessToken()}`}});

const loadFilesRequests = axios.create({
  baseURL: apiRoute,
  headers: {
    Accept: 'application/pdf'}});

//# SERVICES
const defaultParams = { _limit: -1 };
// Removes return limit
const relationParams = { withRelations: true };
// Populate all relational data

/**
 * Auth API
{
  "identifier": "hi@mantisware.co.za",
  "password": "superSecure123"
}
 */
const Auth = {
  current: () =>
    requests.get(`/users/me`, Object.assign({}, defaultParams, relationParams)),
  login: data => requestsAuth.post(`/local`, data),
  // Gets the reset Code
  forgotPass: email =>
    requestsAuth.post(`/forgot-password`, { email, url: forgotPassUrl }),
  // Change Password with supplied reset Code
  resetPass: (code, pass) =>
    requestsAuth.post(`/reset-password`, {
      code,
      password: pass,
      passwordConfirmation: pass}),
  register: (username, email, password) =>
    requestsAuth.post(`/local/register`, { username, email, password }),
  save: user => requests.put(`/users/${user._id}`, { user })};

/**
 * Media API
{
  "files": [
    "string"
  ],
  "path": "/public/upload",
  "refId": "5a993616b8e66660e8baf45c",
  "ref": "user",
  "source": "users-permissions",
  "field": "avatar"
}
 */
const Media = {
  get: id => requests.get(`/upload/files/${id}`),
  getAll: () => requests.get(`/upload/files?_limit=-1`, defaultParams),
  add: data => requests.post(`/upload`, { data }),
  delete: id => requests.delete(`/upload/files/${id}`)};

/**
 * AccountantDetails API
[
  {
    "id": "string",
    "SAIPANo": "string",
    "IDNo": "string",
    "Email": "string",
    "ID": {
      "id": "string",
      "name": "string",
      "hash": "string",
      "sha256": "string",
      "ext": "string",
      "mime": "string",
      "size": "string",
      "url": "string",
      "provider": "string",
      "public_id": "string",
      "related": "string"
    }
  }
]
 */
const AccountantDetails = {
  get: id => requests.get(`/accountantdetails/${id}`),
  getAll: () => requests.get(`/accountantdetails?_limit=-1`, defaultParams),
  add: data => requests.post(`/accountantdetails`, data),
  edit: (id, data) => requests.put(`/accountantdetails/${id}`, data),
  delete: id => requests.delete(`/accountantdetails/${id}`),
  restore: id => requests.put(`/accountantdetails/restore/${id}`)};

/**
 * AnnualBalanceSheets API
[
  {
    "id": "string",
    "AccountsReceivable": 0,
    "AccountsPayable": 0,
    "Inventory": 0,
    "CashAndCashEquivalents": 0,
    "TotalCurrentLiabilities": 0
  }
]
 */
const AnnualBalanceSheets = {
  get: id => requests.get(`/annualbalancesheets/${id}`),
  getAll: () => requests.get(`/annualbalancesheets?_limit=-1`, defaultParams),
  add: data => requests.post(`/annualbalancesheets`, data),
  edit: (id, data) => requests.put(`/annualbalancesheets/${id}`, data),
  delete: id => requests.delete(`/annualbalancesheets/${id}`),
  restore: id => requests.put(`/annualbalancesheets/restore/${id}`)};

/**
 * AnnualIncomeStatements API
[
  {
    "id": "string",
    "TurnOver": 0,
    "CostOfSales": 0,
    "NetProfitAfterTax": 0
  }
]
 */
const AnnualIncomeStatements = {
  get: id => requests.get(`/annualincomestatements/${id}`),
  getAll: () =>
    requests.get(`/annualincomestatements?_limit=-1`, defaultParams),
  add: data => requests.post(`/annualincomestatements`, data),
  edit: (id, data) => requests.put(`/annualincomestatements/${id}`, data),
  delete: id => requests.delete(`/annualincomestatements/${id}`),
  restore: id => requests.put(`/annualincomestatements/restore/${id}`)};

/**
 * BankAccountBalances API
[
  {
    "id": "string",
    "Date": "string",
    "BankBalance": 0,
    "verified": true,
    "businessfinancials": [
      {
        "id": "string",
        "cipcinfo": "string",
        "annualbalancesheet": "string",
        "annualincomestatement": "string",
        "accountantdetail": "string",
        "bankaccountbalances": [
          "string"
        ],
        "FinancialDocs": [
          "string"
        ],
        "BankStatements": [
          "string"
        ],
        "FinancialYearEndMonth": "string",
        "verifieds": [
          "string"
        ],
        "progressstatus": "string",
        "CheckedBusinessFinancialData": true,
        "CheckedCashFlows": true,
        "profiles": [
          "string"
        ]
      }
    ]
  }
]
 */
const CashFlowRecords = {
  get: id => requests.get(`/cashflowrecords/${id}`),
  getAll: () => requests.get(`/cashflowrecords?_limit=-1`, defaultParams),
  add: data => requests.post(`/cashflowrecords`, data),
  edit: (id, data) => requests.put(`/cashflowrecords/${id}`, data),
  delete: id => requests.delete(`/cashflowrecords/${id}`),
  restore: id => requests.put(`/cashflowrecords/restore/${id}`)};

/**
 * BusinessAddresses API
 [
 {
    "id": "string",
    "UnitNo": "string",
    "StreetName": "string",
    "Suburb": "string",
    "City": "string",
    "Province": "string",
    "PostalCode": "string",
    "ProofAddressURL": "string"
  }
 ]
 */
const BusinessAddresses = {
  get: id => requests.get(`/businessaddresses/${id}`),
  getAll: () => requests.get(`/businessaddresses?_limit=-1`, defaultParams),
  // getAll: (url) => requests.get(url, defaultParams),
  add: data => requests.post(`/businessaddresses`, data),
  edit: (id, data) => requests.put(`/businessaddresses/${id}`, data),
  delete: id => requests.delete(`/businessaddresses/${id}`),
  restore: id => requests.put(`/businessaddresses/restore/${id}`)};

/**
 * BusinessFinancials API
{
  "cipcinfo": "string",
  "annualbalancesheet": "string",
  "annualincomestatement": "string",
  "accountantdetail": "string",
  "bankaccountbalances": [
    "string"
  ],
  "FinancialYearEndMonth": "string",
  "verifieds": [
    "string"
  ],
  "progressstatus": "string",
  "CheckedBusinessFinancialData": true,
  "CheckedCashFlows": true,
  "profiles": [
    "string"
  ]
}
 */
const BusinessFinancials = {
  get: id => requests.get(`/businessfinancials/${id}`),
  getAll: () => requests.get(`/businessfinancials?_limit=-1`, defaultParams),
  add: data => requests.post(`/businessfinancials`, data),
  edit: (id, data) => requests.put(`/businessfinancials/${id}`, data),
  delete: id => requests.delete(`/businessfinancials/${id}`),
  restore: id => requests.put(`/businessfinancials/restore/${id}`)};

/**
 * CompanyDetails API
[
  {
    "id": "string",
    "Name": "string",
    "RegistrationCertificatesURL": "Unknown Type: json",
    "CheckedCompanyDetails": true,
    "BusinessAddress": {
      "id": "string",
      "UnitNo": "string",
      "StreetName": "string",
      "Suburb": "string",
      "City": "string",
      "Province": "string",
      "PostalCode": "string",
      "ProofAddressURL": "string"
    },
    "CompanyDirectors": [
      {
        "id": "string",
        "FirstName": "string",
        "LastName": "string",
        "IDNo": "string",
        "Email": "string",
        "CellNo": "string",
        "IDURL": "string",
        "verified": true,
        "uploadID": "string",
        "companydetails": [
          "string"
        ]
      }
    ],
    "cipcinfo": {
      "id": "string",
      "NumOwners": 0,
      "AvgAge": 0,
      "BusinessAge": 0,
      "RegNo": "string"
    },
    "verifieds": [
      {
        "id": "string",
        "type": "string",
        "name": "string",
        "value": true,
        "companydetails": [
          "string"
        ],
        "businessfinancials": [
          "string"
        ],
        "loans": [
          "string"
        ]
      }
    ],
    "progressstatus": {
      "id": "string",
      "type": "string",
      "numberKeyFields": 0,
      "percentagePerField": 0,
      "currentProgressPercentage": 0,
      "percentageRequired": 0,
      "completed": true
    },
    "profiles": [
      {
        "id": "string",
        "BusinessName": "string",
        "BusinessEmail": "string",
        "ProfileVerified": true,
        "isVerified": true,
        "cipcinfo": "string",
        "companydetails": [
          "string"
        ],
        "businessfinancials": [
          "string"
        ],
        "corporatedebtors": [
          "string"
        ],
        "loans": [
          "string"
        ],
        "latestloanstatus": "string",
        "user": "string"
      }
    ]
  }
]
 */
const CompanyDetails = {
  get: id => requests.get(`/companydetails/${id}`),
  getAll: () => requests.get(`/companydetails?_limit=-1`, defaultParams),
  // getAll: (url) => requests.get(url, defaultParams),
  add: data => requests.post(`/companydetails`, data),
  edit: (id, data) => requests.put(`/companydetails/${id}`, data),
  delete: id => requests.delete(`/companydetails/${id}`),
  restore: id => requests.put(`/companydetails/restore/${id}`)};

/**
 * CompanyDirectors API
 [
 {
    "id": "string",
    "FirstName": "string",
    "LastName": "string",
    "IDNo": "string",
    "Email": "string",
    "CellNo": "string",
    "IDURL": "string",
    "verified": true,
    "uploadID": {
      "id": "string",
      "name": "string",
      "hash": "string",
      "sha256": "string",
      "ext": "string",
      "mime": "string",
      "size": "string",
      "url": "string",
      "provider": "string",
      "public_id": "string",
      "related": "string"
    },
    "companydetails": [
      {
        "id": "string",
        "Name": "string",
        "RegistrationCertificatesURL": "Unknown Type: json",
        "CheckedCompanyDetails": true,
        "BusinessAddress": "string",
        "CompanyDirectors": [
          "string"
        ],
        "cipcinfo": "string",
        "verifieds": [
          "string"
        ],
        "progressstatus": "string",
        "profiles": [
          "string"
        ]
      }
    ]
  }
 ]
 */
const CompanyDirectors = {
  get: id => requests.get(`/companydirectors/${id}`),
  getAll: () => requests.get(`/companydirectors?_limit=-1`, defaultParams),
  add: data => requests.post(`/companydirectors`, data),
  edit: (id, data) => requests.put(`/companydirectors/${id}`, data),
  delete: id => requests.delete(`/companydirectors/${id}`),
  restore: id => requests.put(`/companydirectors/restore/${id}`)};
/**
 * CorporateDebtors API
[
  {
    "id": "string",
    "cipcinfo": {
      "id": "string",
      "NumOwners": 0,
      "AvgAge": 0,
      "BusinessAge": 0,
      "RegNo": "string"
    },
    "debtors": [
      {
        "id": "string",
        "companyName": "string",
        "companyEmail": "string",
        "invoiceAmount": 0,
        "invoice": "string",
        "verified": true,
        "corporatedebtors": [
          "string"
        ]
      }
    ],
    "InvoiceStatements": [
      {
        "id": "string",
        "name": "string",
        "hash": "string",
        "sha256": "string",
        "ext": "string",
        "mime": "string",
        "size": "string",
        "url": "string",
        "provider": "string",
        "public_id": "string",
        "related": "string"
      }
    ],
    "NumInvoices": 0,
    "DebtorQuality": "string",
    "ScheduledSettlement": "string",
    "CheckedCorporateDebtors": true,
    "profiles": [
      {
        "id": "string",
        "BusinessName": "string",
        "BusinessEmail": "string",
        "ProfileVerified": true,
        "isVerified": true,
        "cipcinfo": "string",
        "companydetails": [
          "string"
        ],
        "businessfinancials": [
          "string"
        ],
        "corporatedebtors": [
          "string"
        ],
        "loans": [
          "string"
        ],
        "latestloanstatus": "string",
        "user": "string"
      }
    ]
  }
]
 */
const CorporateDebtors = {
  get: id => requests.get(`/corporatedebtors/${id}`),
  getAll: () => requests.get(`/corporatedebtors?_limit=-1`, defaultParams),
  add: data => requests.post(`/corporatedebtors`, { data }),
  edit: (id, data) => requests.put(`/corporatedebtors/${id}`, { data }),
  delete: id => requests.delete(`/corporatedebtors/${id}`),
  restore: id => requests.put(`/corporatedebtors/restore/${id}`)};

/**
 * Debtors API
[
  {
    "id": "string",
    "companyName": "string",
    "companyEmail": "string",
    "invoiceAmount": 0,
    "invoice": {
      "id": "string",
      "name": "string",
      "hash": "string",
      "sha256": "string",
      "ext": "string",
      "mime": "string",
      "size": "string",
      "url": "string",
      "provider": "string",
      "public_id": "string",
      "related": "string"
    },
    "verified": true,
    "corporatedebtors": [
      {
        "id": "string",
        "cipcinfo": "string",
        "debtors": [
          "string"
        ],
        "InvoiceStatements": [
          "string"
        ],
        "NumInvoices": 0,
        "DebtorQuality": "string",
        "ScheduledSettlement": "string",
        "CheckedCorporateDebtors": true,
        "profiles": [
          "string"
        ]
      }
    ]
  }
]
 */
const DebtorInvoice = {
  get: id => requests.get(`/debtorinvoice/${id}`),
  getAll: () => requests.get(`/debtorinvoice?_limit=-1`, defaultParams),
  add: data => requests.post(`/debtorinvoice`, data),
  edit: (id, data) => requests.put(`/debtorinvoice/${id}`, data),
  delete: id => requests.delete(`/debtorinvoice/${id}`),
  restore: id => requests.put(`/debtorinvoice/restore/${id}`)};

const DocumentLoad = {
  get: url => loadFilesRequests.get(url)};

/**
 * LatestLoanStatuses API
[
  {
    "id": "string",
    "AppliedForLoan": true,
    "LoanBeingVerified": true,
    "LoanVerified": true,
    "LoanAwarded": true,
    "HasPreviousLoan": true,
    "ApplyingForLoan": true,
    "CreatedLoan": true,
    "TermsOfLoanAccepted": true,
    "LoanApplicationInvalid": true
  }
]
 */
const LatestLoanStatuses = {
  get: id => requests.get(`/latestloanstatuses/${id}`),
  getAll: () => requests.get(`/latestloanstatuses?_limit=-1`, defaultParams),
  add: data => requests.post(`/latestloanstatuses`, { data }),
  edit: (id, data) => requests.put(`/latestloanstatuses/${id}`, { data }),
  delete: id => requests.delete(`/latestloanstatuses/${id}`),
  restore: id => requests.put(`/latestloanstatuses/restore/${id}`)};

/**
 * LoanDetails API
[
  {
    "id": "string",
    "factoring": true,
    "discounting": true,
    "loanPercent": 0,
    "loanAmount": 0,
    "totalRepayable": 0,
    "monthlyRepayable": 0,
    "interest": 0,
    "terms": 0,
    "invoice": {
      "id": "string",
      "name": "string",
      "hash": "string",
      "sha256": "string",
      "ext": "string",
      "mime": "string",
      "size": "string",
      "url": "string",
      "provider": "string",
      "public_id": "string",
      "related": "string"
    },
    "invoiceVerifiedInternally": true,
    "invoiceVerifiedExternally": true,
    "verified": true,
    "awarded": true,
    "submitted": true,
    "paidBackInFull": true,
    "dateAwarded": "string",
    "dateSubmitted": "string"
  }
]
 */
const LoanDetails = {
  get: id => requests.get(`/loandetails/${id}`),
  getDocumnet: hashId => requests.get(`/loandetails/getdocument/${hashId}`),
  getByLoansProfileId: loansProfileId =>
    requests.get(`/loandetails?_limit=-1&loansProfile=${loansProfileId}&offers=true`),
  getAll: () => requests.get(`/loandetails?_limit=-1`, defaultParams),
  getOfferedLoans: loansProfileId => requests.get(`/loandetails/getoffered?loansProfile=${loansProfileId}`, defaultParams),
  add: data => requests.post(`/loandetails`, data),
  edit: (id, data) => requests.put(`/loandetails/${id}`, data),
  accepted: (id, data) => requests.put(`/loandetails/accepted/${id}`, data),
  delete: id => requests.delete(`/loandetails/${id}`),
  restore: id => requests.put(`/loandetails/restore/${id}`)};

/**
 * Loans API
[
  {
    "id": "string",
    "cipcinfo": {
      "id": "string",
      "NumOwners": 0,
      "AvgAge": 0,
      "BusinessAge": 0,
      "RegNo": "string"
    },
    "InvoiceAmount": 0,
    "InvoiceDueDate": "string",
    "Retailer": "string",
    "loandetails": {
      "id": "string",
      "factoring": true,
      "discounting": true,
      "loanPercent": 0,
      "loanAmount": 0,
      "totalRepayable": 0,
      "monthlyRepayable": 0,
      "interest": 0,
      "terms": 0,
      "invoice": "string",
      "invoiceVerifiedInternally": true,
      "invoiceVerifiedExternally": true,
      "verified": true,
      "awarded": true,
      "submitted": true,
      "paidBackInFull": true,
      "dateAwarded": "string",
      "dateSubmitted": "string"
    },
    "verifieds": [
      {
        "id": "string",
        "type": "string",
        "name": "string",
        "value": true,
        "companydetails": [
          "string"
        ],
        "businessfinancials": [
          "string"
        ],
        "loans": [
          "string"
        ]
      }
    ],
    "progressstatus": {
      "id": "string",
      "type": "string",
      "numberKeyFields": 0,
      "percentagePerField": 0,
      "currentProgressPercentage": 0,
      "percentageRequired": 0,
      "completed": true
    },
    "CheckedLoanRequirements": true,
    "profiles": [
      {
        "id": "string",
        "BusinessName": "string",
        "BusinessEmail": "string",
        "ProfileVerified": true,
        "isVerified": true,
        "cipcinfo": "string",
        "companydetails": [
          "string"
        ],
        "businessfinancials": [
          "string"
        ],
        "corporatedebtors": [
          "string"
        ],
        "loans": [
          "string"
        ],
        "latestloanstatus": "string",
        "user": "string"
      }
    ]
  }
]
 */
const LoansProfile = {
  get: id => requests.get(`/loansprofiles/${id}`),
  getAll: () => requests.get(`/loansprofiles?_limit=-1`, defaultParams),
  add: data => requests.post(`/loansprofiles`, data),
  edit: (id, data) => requests.put(`/loansprofiles/${id}`, data),
  delete: id => requests.delete(`/loansprofiles/${id}`),
  restore: id => requests.put(`/loansprofiles/restore/${id}`)};

const PastInvoices = {
  get: id => requests.get(`/pastinvoices/${id}`),
  getAll: () => requests.get(`/pastinvoices?_limit=-1`, defaultParams),
  add: data => requests.post(`/pastinvoices`, data),
  edit: (id, data) => requests.put(`/pastinvoices/${id}`, data),
  delete: id => requests.delete(`/pastinvoices/${id}`),
  restore: id => requests.put(`/pastinvoices/restore/${id}`)};

/**
 * Profiles API
[
  {
    "id": "string",
    "BusinessName": "string",
    "BusinessEmail": "string",
    "ProfileVerified": true,
    "isVerified": true,
    "cipcinfo": {
      "id": "string",
      "NumOwners": 0,
      "AvgAge": 0,
      "BusinessAge": 0,
      "RegNo": "string"
    },
    "companydetails": [
      {
        "id": "string",
        "Name": "string",
        "RegistrationCertificatesURL": "Unknown Type: json",
        "CheckedCompanyDetails": true,
        "BusinessAddress": "string",
        "CompanyDirectors": [
          "string"
        ],
        "cipcinfo": "string",
        "verifieds": [
          "string"
        ],
        "progressstatus": "string",
        "profiles": [
          "string"
        ]
      }
    ],
    "businessfinancials": [
      {
        "id": "string",
        "cipcinfo": "string",
        "annualbalancesheet": "string",
        "annualincomestatement": "string",
        "accountantdetail": "string",
        "bankaccountbalances": [
          "string"
        ],
        "FinancialDocs": [
          "string"
        ],
        "BankStatements": [
          "string"
        ],
        "FinancialYearEndMonth": "string",
        "verifieds": [
          "string"
        ],
        "progressstatus": "string",
        "CheckedBusinessFinancialData": true,
        "CheckedCashFlows": true,
        "profiles": [
          "string"
        ]
      }
    ],
    "corporatedebtors": [
      {
        "id": "string",
        "cipcinfo": "string",
        "debtors": [
          "string"
        ],
        "InvoiceStatements": [
          "string"
        ],
        "NumInvoices": 0,
        "DebtorQuality": "string",
        "ScheduledSettlement": "string",
        "CheckedCorporateDebtors": true,
        "profiles": [
          "string"
        ]
      }
    ],
    "loans": [
      {
        "id": "string",
        "cipcinfo": "string",
        "InvoiceAmount": 0,
        "InvoiceDueDate": "string",
        "Retailer": "string",
        "loandetails": "string",
        "verifieds": [
          "string"
        ],
        "progressstatus": "string",
        "CheckedLoanRequirements": true,
        "profiles": [
          "string"
        ]
      }
    ],
    "latestloanstatus": {
      "id": "string",
      "AppliedForLoan": true,
      "LoanBeingVerified": true,
      "LoanVerified": true,
      "LoanAwarded": true,
      "HasPreviousLoan": true,
      "ApplyingForLoan": true,
      "CreatedLoan": true,
      "TermsOfLoanAccepted": true,
      "LoanApplicationInvalid": true
    },
    "user": {
      "id": "string",
      "username": "string",
      "email": "string",
      "provider": "string",
      "password": "string",
      "resetPasswordToken": "string",
      "confirmed": true,
      "blocked": true,
      "role": "string",
      "firstname": "string",
      "lastname": "string",
      "attributes": "Unknown Type: json",
      "profile": "string"
    }
  }
]
 */
const Profiles = {
  get: (id, params) =>requests.get(`/profiles/${id}${params && params.length > 0 ? `?populate=${params}` : ''}`),
  getPublic: (id, params) =>unAuthRequest.get(`/profiles/publicprofile/${id}${params && params.length > 0 ? `?populate=${params}` : ''}`),
  getAll: (query) => requests.get(`/profiles?${query || `_limit=10&_start=0`}`, defaultParams),
  getByUserId: userId => requests.get(`/profiles?_limit=1&user=${userId}`),
  add: data => unAuthRequest.post(`/profiles`, data),
  edit: (id, data) => requests.put(`/profiles/${id}`, data),
  delete: id => requests.delete(`/profiles/${id}`),
  restore: id => requests.put(`/profiles/restore/${id}`), 
  search: (searchTerm) => requests.get(`/profiles/search?name=${searchTerm}`)
};

/**
 * ProgressStatuses API
[
  {
    "id": "string",
    "type": "string",
    "numberKeyFields": 0,
    "percentagePerField": 0,
    "currentProgressPercentage": 0,
    "percentageRequired": 0,
    "completed": true
  }
]
 */
const ProgressStatuses = {
  get: id => requests.get(`/progressstatuses/${id}`),
  getAll: () => requests.get(`/progressstatuses?_limit=-1`, defaultParams),
  add: data => requests.post(`/progressstatuses`, { data }),
  edit: (id, data) => requests.put(`/progressstatuses/${id}`, { data }),
  delete: id => requests.delete(`/progressstatuses/${id}`),
  restore: id => requests.put(`/progressstatuses/restore/${id}`)};

const User = {
  edit: (id, data) => unAuthRequest.put(`/users/${id}`, data)};

const Upload = {
  get: id => requests.get(`/upload/${id}`),
  getAll: () => requests.get(`/upload?_limit=-1`, defaultParams),
  add: data => uploadRequests.post(`/upload/`, data),
  edit: (id, data) => requests.put(`/upload/${id}`, data),
  delete: id => requests.delete(`/upload/files/${id}`),
  restore: id => requests.put(`/upload/restore/${id}`)};

const OauthIntegrations = {
  setSage: data => requests.post(`/sagedata/connect`, data),
  setAuthData: data => requests.post(`/oauthtokens`, data),
  getAuthData: () => requests.get(`/oauthtokens`),
  getYodleeAccessToken: () => requests.get('/oauthtokens/yodlee-token'),
  fetchYodleeData: () => requests.get(`/oauthtokens/fetch-yodlee-data`),
  removeYodleedata: () => requests.delete(`/yodleedata/remove`),
  removeXerodata: () => requests.delete(`/xerodata/remove`),
  removeSagedata: () => requests.delete(`/sagedata/remove`),


  getSavedDiaDhaData:(B_Id)=> requests.get(`/digitalidentityverifies/getSavedDiaDhaData/${B_Id}`),
  getSavedCapturedData:(B_Id)=> requests.get(`/digitalidentityverifies/getSavedCapturedData/${B_Id}`),
  setDiaDhaData:(id_nos, B_Id)=> requests.post(`/digitalidentityverifies/setDiaDhaData/${B_Id}`, id_nos),
  setDirectorsCapturedImg:(data,B_Id)=> requests.post(`/digitalidentityverifies/setCapturedImg/${B_Id}`, data)
}

/**
 * Verifieds API
[
  {
    "id": "string",
    "type": "string",
    "name": "string",
    "value": true,
    "companydetails": [
      {
        "id": "string",
        "Name": "string",
        "RegistrationCertificatesURL": "Unknown Type: json",
        "CheckedCompanyDetails": true,
        "BusinessAddress": "string",
        "CompanyDirectors": [
          "string"
        ],
        "cipcinfo": "string",
        "verifieds": [
          "string"
        ],
        "progressstatus": "string",
        "profiles": [
          "string"
        ]
      }
    ],
    "businessfinancials": [
      {
        "id": "string",
        "cipcinfo": "string",
        "annualbalancesheet": "string",
        "annualincomestatement": "string",
        "accountantdetail": "string",
        "bankaccountbalances": [
          "string"
        ],
        "FinancialDocs": [
          "string"
        ],
        "BankStatements": [
          "string"
        ],
        "FinancialYearEndMonth": "string",
        "verifieds": [
          "string"
        ],
        "progressstatus": "string",
        "CheckedBusinessFinancialData": true,
        "CheckedCashFlows": true,
        "profiles": [
          "string"
        ]
      }
    ],
    "loans": [
      {
        "id": "string",
        "cipcinfo": "string",
        "InvoiceAmount": 0,
        "InvoiceDueDate": "string",
        "Retailer": "string",
        "loandetails": "string",
        "verifieds": [
          "string"
        ],
        "progressstatus": "string",
        "CheckedLoanRequirements": true,
        "profiles": [
          "string"
        ]
      }
    ]
  }
]
 */
const Verifieds = {
  get: id => requests.get(`/verifieds/${id}`),
  getAll: () => requests.get(`/verifieds?_limit=-1`, defaultParams),
  add: data => requests.post(`/verifieds`, { data }),
  edit: (id, data) => requests.put(`/verifieds/${id}`, { data }),
  delete: id => requests.delete(`/verifieds/${id}`),
  restore: id => requests.put(`/verifieds/restore/${id}`)};

/*
{
  "name": "string",
  "description": "string",
  "status": "string",
  "typeId": "string",
  "link": "string",
  "type": "string"
}
*/
const AuditTrails = {
  get: id => requests.get(`/audittrails/${id}`),
  getAll: () => requests.get(`/audittrails?_limit=-1`, defaultParams),
  getAllByDateSorted: () =>
    requests.get(`/audittrails`, { _limit: -1, _sort: 'createdAt:DESC' }),
  getAllByProfileSorted: profileId =>
    requests.get(
      `/audittrails?typeId=${profileId}`
    ),
  getAllByType: (type, id) =>
    requests.get(
      `/audittrails?_limit=-1&type=${type}&typeId=${id}`,
      defaultParams
    ),
  add: data => requests.post(`/audittrails`,  data ),
  edit: (id, data) => requests.put(`/audittrails/${id}`, { data }),
  delete: id => requests.delete(`/audittrails/${id}`),
  restore: id => requests.put(`/audittrails/restore/${id}`)};

const Statuses = {
  get: id => requests.get(`/statuses/${id}`),
  getByName: name => requests.get(`/statuses/name/${name}`),
  getAll: () => requests.get(`/statuses?_limit=-1`, defaultParams),
  add: data => requests.post(`/statuses`, { data }),
  edit: (id, data) => requests.put(`/statuses/${id}`, { data }),
  delete: id => requests.delete(`/statuses/${id}`),
  restore: id => requests.put(`/statuses/restore/${id}`)};

  const Offers = {
    get: id => requests.get(`/offers/${id}`),
    getByLoanId: id => requests.get(`/offers/loanid/${id}`),
    getAll: () => requests.get(`/offers?_limit=-1`, defaultParams),
    add: data => requests.post(`/offers`, data),
    edit: (id, data) => requests.put(`/offers/${id}`, data),
    delete: id => requests.delete(`/offers/${id}`),
    restore: id => requests.put(`/offers/restore/${id}`)};
  const Bankaccountdetails = {
    get: id => requests.get(`/bankaccountdetails/${id}`),
    getAll: () => requests.get(`/bankaccountdetails?_limit=-1`, defaultParams),
    add: data => requests.post(`/bankaccountdetails`, data ),
    edit: (id, data) => requests.put(`/bankaccountdetails/${id}`, data ),
    delete: id => requests.delete(`/bankaccountdetails/${id}`),
    restore: id => requests.put(`/bankaccountdetails/restore/${id}`)};

//api for verifyidDetails
const VerifyidDetails = {
  get: id => requests.get(`/verifyiddetails/${id}`),
  getAll: () => requests.get(`/verifyiddetails?_limit=-1`, defaultParams),
  add: data => requests.post(`/verifyiddetails`, data ),
  edit: (id, data) => requests.put(`/verifyiddetails/${id}`, data ),
  delete: id => requests.delete(`/verifyiddetails/${id}`),
  restore: id => requests.put(`/verifyiddetails/restore/${id}`)};

  //verify id api
const Verifyid = {
  get: id => requests.get(`/verifyids/${id}`),
  getAll: () => requests.get(`/verifyids?_limit=-1`, defaultParams),
  getCredit: () => requests.get(`/verifyids/credits?_limit=-1`, defaultParams),
  add: data => requests.post(`/verifyids`, data ),
  edit: (id, data) => requests.put(`/verifyids/${id}`, data ),
  delete: id => requests.delete(`/verifyids/${id}`),
  restore: id => requests.put(`/verifyids/restore/${id}`)};

//Email
const Email = {
  send: data => requests.post(`/email`, data)};

const startExternalVerification = userId => requests.get(`/bankaccountdetails/verify?id=${userId}`);
const getTransunionData = (reg_no, userId) => requests.post(`/transunions/${reg_no}?id=${userId}`);

export {
  Auth,
  AuditTrails,
  Media,
  AccountantDetails,
  BusinessAddresses,
  AnnualBalanceSheets,
  AnnualIncomeStatements,
  CashFlowRecords,
  BusinessFinancials,
  CompanyDetails,
  CorporateDebtors,
  DebtorInvoice,
  CompanyDirectors,
  LatestLoanStatuses,
  LoanDetails,
  LoansProfile,
  PastInvoices,
  Profiles,
  ProgressStatuses,
  User,
  Upload,
  Verifieds,
  apiRoute,
  DocumentLoad,
  Statuses,
  OauthIntegrations,
  Bankaccountdetails,
  VerifyidDetails,
  Verifyid,
  Email,
  Offers,
  startExternalVerification,
  getTransunionData
};
