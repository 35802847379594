import React, { Component } from 'react';
import { Card, CardBody, CardHeader, CardTitle, Button } from '@Comp';
import BalanceSheet from './balance-sheet';
import IncomeStatement from './income-statement';
import AccountantDetails from './accountant-details';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel
} from 'react-accessible-accordion';
import { inject, observer } from 'mobx-react';
import DocumentUploader from '../dropzone';
import ViewUploadedFiles from '../ViewUploadeFiles';
import {TEN} from '../../../constants/constants';
@inject('businessProfileStore')
@observer
class BusinessFinancialPage extends Component {
  constructor() {
    super();
    this.state = {
      disabled: true};
  }
  handleSave = e => {
    e.preventDefault();
    const sectionsToUpdate = this.props.businessProfileStore.fieldsToUpdate;
    this.detailsToPost(sectionsToUpdate).then(()=>{
      this.props.businessProfileStore.getUpdatedData();
      this.setState({disabled: true});
  });
  };

  detailsToPost = async sectionsToUpdate => {
    const businessFinancials = this.props.businessProfileStore.businessProfile
      .businessFinancials;

    if (sectionsToUpdate.annualBalanceSheetDirty) {
      if (businessFinancials.annualBalanceSheet) {
        //update
       await this.props.businessProfileStore.postAnnualBalanceSheet(true);
      } else {
        //create new business address
       await this.props.businessProfileStore.postAnnualBalanceSheet(false);
      }
    }

    if (sectionsToUpdate.annualIncomeStatementDirty) {
      if (businessFinancials.annualIncomeStatement) {
        //update
       await this.props.businessProfileStore.postAnnualIncomeStatement(true);
      } else {
        //create new business address
        await this.props.businessProfileStore.postAnnualIncomeStatement(false);
      }
    }
    if (sectionsToUpdate.accountantDetailsDirty) {
      if (businessFinancials.accountantDetails) {
        //update
      await  this.props.businessProfileStore.postAccountantDetails(true);
      } else {
        //create new business address
      await  this.props.businessProfileStore.postAccountantDetails(false);
      }
    }
  };
  handleRemoveDocument = (e, documentId) => {
    this.props.businessProfileStore.businessProfile.businessFinancials.annualFinancialDocs =
    this.props.businessProfileStore.businessProfile.businessFinancials.annualFinancialDocs.filter(
      document => document.id !== documentId
    );
  };

  formDataChange = bool => {
    this.setState({ disabled: bool });
  };

  render() {
    const {isVerified} = this.props.businessProfileStore;
    const fileInfo = {
      files: [],
      path: '/public/upload',
      refId: this.props.businessProfileStore.businessProfile.businessFinancials
        .id,
      ref: 'businessfinancials',
      source: 'content-manager',
      field: 'annualFinancialDocs'};
    return (
      <section>
        <div className="p-h-15 p-v-20">
          <p>
            The Financial health of a business is plays an important part in the
            credit scoring process. The data below will be used to calculate
            financial ratios that indicate the financial health of the business.
            <br /> Please ensure that the information provided by you (or your
            accountant) is accurate as incorrect information will affect your
            score upon verification.
          </p>
        </div>
        <div>
          <Card>
            <CardHeader className="border border-bottom">
              <CardTitle>
                <h5>Financial Data (Latest Financial Year End)</h5>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Accordion
                allowZeroExpanded={true}
                allowMultipleExpanded={true}
                preExpanded={[1]}
              >
                <AccordionItem uuid={1}>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Annual Balance Sheet Data {isVerified && <span className="badge text-bold"> - {(isVerified.businessFinancialsVerified && 'Verified')||'Not Verified'}</span>}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div className="card-body">
                      <div className="p-h-10">
                        <BalanceSheet formDataChange={this.formDataChange} />
                      </div>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid={2}>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Annual Income Statement Data {isVerified && <span className="badge text-bold"> - {(isVerified.businessFinancialsVerified && 'Verified')||'Not Verified'}</span>}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div className="card-body">
                      <div className="p-h-10">
                        <IncomeStatement formDataChange={this.formDataChange} />
                      </div>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid={3}>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Accountant Details {isVerified && <span className="badge text-bold"> - {(isVerified.businessFinancialsVerified && 'Verified')||'Not Verified'}</span>}
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <div className="card-body">
                      <div className="p-h-10">
                        <AccountantDetails
                          formDataChange={this.formDataChange}
                          isDocumentUploadVisible={this.state.disabled}
                        />
                      </div>
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </CardBody>
          </Card>
          <div className="row justify-content-end">
            <div className="col-12">
              {this.props.businessProfileStore.businessProfile
                .businessFinancials.annualFinancialDocs.length < TEN &&
              this.props.businessProfileStore.businessProfile
                .businessFinancials.id && this.state.disabled && (
                <DocumentUploader
                  fileData={fileInfo}
                  maxFiles={
                    TEN -
                    Number(
                      this.props.businessProfileStore.businessProfile
                        .businessFinancials.annualFinancialDocs.length
                    )
                  }
                  multiple={true}
                  inputContent={'Upload Payroll, Tax Pin and Statements'}
                />
              )}
            </div>
            <div className="w-100 d-flex justify-content-center my-2">
              {this.props.businessProfileStore.businessProfile
                .businessFinancials.annualFinancialDocs.length > 0 && (
                <ViewUploadedFiles
                  title="Financial Statements"
                  data={
                    this.props.businessProfileStore.businessProfile
                      .businessFinancials.annualFinancialDocs
                  }
                  handleRemoveDocument={this.handleRemoveDocument}
                />
              )}
            </div>
          </div>

          <div className="text-sm-right">
            {this.props.businessProfileStore.businessProfile.isEditable && <Button
              color="info"
              id="submitFinData"
              onClick={this.handleSave}
              disabled={this.state.disabled}
            >
              Save
            </Button> }
          </div>
        </div>
      </section>
    );
  }
}
export default BusinessFinancialPage;
