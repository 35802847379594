import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ScrollMenu from 'react-horizontal-scrolling-menu';
 
// Styles
import './HorizontalMenu.scss';

class HorizontalMenu extends Component {

    constructor(props){
        super(props);

        this.state = {
            menu: null
        }
    }

    componentDidMount(){
        const { items } = this.props;

        let menu = this.Menu(items);
        this.setState({menu});
    }

    MenuItem = ({ item, idx }) => {
        return <div id={idx} className={`menu-item ${item.selected ? "active" : ""}`} 
            onClick={() => {
                if(item.onClick){
                    item.onClick(item, idx);
                }
                let { items } = this.props;
                
                items.forEach((item) => {
                    item.selected = false;
                });

                items[idx].selected = true;

                let menu = this.Menu(items);
                this.setState({menu});
            }}>{item.title}</div>;
    };
    Menu = (items) =>
        items.map((item, idx) => {
            return <this.MenuItem item={item} idx={idx} />;
        });

    // onSelect = key => {
    //     console.log(`onSelect: ${key}`);
    //     this.setState({ selected: key });
    // };
    render() {
        
        let { menu } = this.state;
        let transition = 0.4;

        if(menu){
            return (
                <ScrollMenu
                    ref={el => (this.menu = el)}
                    data={menu}
                    //arrowLeft={ArrowLeft}
                    //arrowRight={ArrowRight}
                    //hideArrows={hideArrows}
                    //hideSingleArrow={hideSingleArrow}
                    transition={+transition}
                    //onUpdate={this.onUpdate}
                    //onSelect={this.onSelect}
                    //selected={selected}
                    //translate={translate}
                    alignCenter={false}
                    scrollToSelected={true}
                    dragging={true}
                    clickWhenDrag={false}
                    wheel={true}
                />
            )
        }else {
            return null;
        }
    }
}

HorizontalMenu.propTypes = {
    items: PropTypes.arrayOf(PropTypes.objectOf({
        title: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        selected: PropTypes.bool
    })).isRequired
}
HorizontalMenu.defaultProps = {}

export default HorizontalMenu;
