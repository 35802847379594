import React from 'react';
import PropTypes from 'prop-types';


import HorizontalMenu from './HorizontalMenu';

class Header extends React.Component {

    render(){

        let {title, description, DateItems} = this.props;

        return (
            <div>
                {(DateItems && DateItems.length > 0)?
                <HorizontalMenu className="" items={DateItems}/>:null}

                <div className="view-header d-flex align-items-center">
                    <header className="text-white">
                        <h1 className="h5 title text-uppercase">{title}</h1>
                        {(description)?
                        <div className="mb-0 subtitle text-nowrap">{description}</div>:null}
                    </header>
                </div>
            </div>
        );
    }
}

Header.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.object || PropTypes.string,
    Line_Title: PropTypes.string,
    Line_tinyChartData: PropTypes.arrayOf(PropTypes.object),
    Bar_Title: PropTypes.string,
    Bar_tinyChartData: PropTypes.arrayOf(PropTypes.object),
    DateItems: PropTypes.arrayOf(PropTypes.objectOf({
        title: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        selected: PropTypes.bool
    }))
}
Header.defaultProps = {}

export default Header;