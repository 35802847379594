import React from 'react';
import { Header } from '@Nisa';


let desc = () => {
    return <div>Administration Tasks &amp; General Overview</div>
};

export default () => (
    <Header 
        title={'Admin Dashboard'}
        description={desc()}
    />
);
