import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { OauthIntegrations } from "../../../services/requests";

const DiaModal = ({
  show,
  onHide,
  directorDetails,
  isDiaOpened,
  businessId,
  dirSavedCapturedImg,
}) => {
  const [activeDirector, setActiveDirector] = useState(0); // active director camera
  const [IsCaptured, setIsCaptured] = useState(false); // is captures or not for activedirector
  const [captured, setCaptured] = useState(new Array(directorDetails.length)); // local state for data
  let streaming = false;
  let width = 600;
  let height = width * 0.75;

  // runs when captured image data is in the dababase
  useEffect(() => {
    if (dirSavedCapturedImg) {
      setCaptured(dirSavedCapturedImg);
      setIsCaptured(true);
    }
  }, [dirSavedCapturedImg]);

  // runs when modal is open or close
  useEffect(() => {
    if (show && !video.srcObject) {
      playVideo();
    }
    if (show && video.srcObject) {
      stopVideo();
    }
  }, [show]);

  // to play video
  const playVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(function (stream) {
        video.srcObject = stream;
        video.play();
      })
      .catch(function (err) {
        console.log("An error occurred: " + err);
      });
  };

  // to stop video
  const stopVideo = () => {
    video.pause();
    let tracks = video.srcObject.getTracks();
    for (var i = 0; i < tracks.length; i++) {
      var track = tracks[i];
      track.stop();
    }
    video.srcObject = null;
  };

  // present video in ui
  const canplay = () => {
    if (!streaming) {
      video.setAttribute("width", width);
      video.setAttribute("height", height);
      canvas.setAttribute("width", 0);
      canvas.setAttribute("height", 0);
      streaming = true;
    }
  };

  // capture image and store to local state
  const captureImage = (e) => {
    e.preventDefault();
    takepicture();

    function takepicture() {
      var context = canvas.getContext("2d");
      canvas.width = width;
      canvas.height = height;
      context.drawImage(video, 0, 0, width, height);
      var data = canvas.toDataURL("image/png");

      canvas.height = 0;
      canvas.width = 0;
      video.setAttribute("height", 0);
      video.setAttribute("width", 0);
      photo.setAttribute("src", data);
      setTimeout(() => {
        setIsCaptured(true);
      }, 500);
      streaming = false;
      addPic();

      function addPic() {
        let tempCaptured = captured;
        tempCaptured[activeDirector] = {
          idNo: directorDetails[activeDirector].idNo,
          capturedImg: photo.getAttribute("src"),
        };
        setCaptured(tempCaptured);
      }
    }
  };

  // reset img when reset btn is clicked
  const resetImage = () => {
    canplay();
    setIsCaptured(false);
    let tempCaptured = captured;
    tempCaptured[activeDirector] = null;
    setCaptured(tempCaptured);
    photo.removeAttribute("src");
  };

  // when next is clicked, video is played and photo is removed from ui
  const resetVideoAndImg = () => {
    setIsCaptured(false);
    photo.removeAttribute("src");
    canplay();
  };

  // next btn clicks
  const onNext = () => {
    !captured[activeDirector + 1] && resetVideoAndImg();
    setActiveDirector(activeDirector + 1);
  };

  // previous btn clicks
  const onPrevious = () => {
    if (captured[activeDirector - 1]) {
      setIsCaptured(true);
      video.setAttribute("height", 0);
      video.setAttribute("width", 0);
    }
    setActiveDirector(activeDirector - 1);
  };

  // submit btn
  const onSubmit = async (e) => {
    e.preventDefault();
    await OauthIntegrations.setDirectorsCapturedImg(captured, businessId);
    onHide();
  };

  const styles = {
    cameraDivStyle: {
      background: "linear-gradient(to bottom, #7d7e7d 0%,#0e0e0e 100%)",
      width: 600,
      height: 450,
      margin: "auto",
      position: "relative",
    },
    imgbtns: {
      position: "absolute",
      top: "90%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    directorDetail: {
      marginBottom: 30,
      textAlign: "center",
    },
  };

  return (
    <Modal size="lg" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Verify Director Image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {directorDetails[activeDirector] && (
          <div>
            <div style={styles.directorDetail}>
              <b>
                {directorDetails[activeDirector].name} (Id Number -{" "}
                {directorDetails[activeDirector].idNo})
              </b>
            </div>
            <div id="camera" style={styles.cameraDivStyle}>
              <video
                style={
                  (isDiaOpened &&
                    captured[activeDirector] &&
                    captured[activeDirector].capturedImg) ||
                  (captured[activeDirector] &&
                    captured[activeDirector].capturedImg)
                    ? { display: "none" }
                    : { display: "block" }
                }
                onCanPlay={canplay}
                id="video"
              >
                Video stream not available.
              </video>
              <img
                id="photo"
                alt=""
                src={
                  captured[activeDirector] &&
                  captured[activeDirector].capturedImg
                }
              />
              {IsCaptured ? (
                <Button
                  style={styles.imgbtns}
                  variant="secondary"
                  onClick={resetImage}
                >
                  Reset
                </Button>
              ) : (
                <Button
                  style={styles.imgbtns}
                  variant="primary"
                  onClick={captureImage}
                >
                  Take photo
                </Button>
              )}
              <canvas id="canvas"></canvas>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        {activeDirector > 0 && (
          <Button variant="primary" onClick={onPrevious}>
            Previous
          </Button>
        )}
        {activeDirector < directorDetails.length - 1 && (
          <Button variant="primary" disabled={!IsCaptured} onClick={onNext}>
            Next
          </Button>
        )}
        {activeDirector === directorDetails.length - 1 && (
          <Button variant="primary" disabled={!IsCaptured} onClick={onSubmit}>
            Submit
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default DiaModal;
