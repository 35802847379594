import React from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';
import { inject } from 'mobx-react';
import config from '../../constants/config';
let fileUploadInfo = [];
const preview = props => {
	const removeFiles = (e, file) => {
		e.preventDefault();
		file.remove();
	};

	const { meta, files } = props;
	const { name } = meta;

	return (
		<div className='mt-4 border-bottom pb-1'>
			<label>{name}</label>
			<label className='badge badge-dark ml-3'>
				<a href='' onClick={event => removeFiles(event, files[0])}>
					X
				</a>
			</label>
		</div>
	);
};
function FileZone(props) {
	//NOSONAR
	const filesExist = props.uploadedFiles;
	fileUploadInfo = props.uploadedFiles;
	if (filesExist) {
		return (
			<Dropzone
				onChangeStatus={props.handleChangeStatus}
				onSubmit={props.handleSubmit}
				accept='image/*,audio/*,video/*,application/pdf'
				initialFiles={props.uploadedFiles}
				maxFiles={props.maxFiles}
				multiple={props.multiple}
				inputContent='Drop A File'
				disabled={props.disabled || false}
				PreviewComponent={preview}
				styles={{
					dropzone: {
						minHeight: 130,
						maxHeight: 200,
						margin: 10,
						border: '2px dashed #d4d4d4',
						overflow: 'auto',
					},
					submitButtonContainer: { marginTop: 10, padding: 0 },
				}}
			/>
		);
	} else {
		return (
			<Dropzone
				//getUploadParams={getUploadParams}
				onChangeStatus={props.handleChangeStatus}
				onSubmit={props.handleSubmit}
				accept='image/*,audio/*,video/*,application/pdf'
				maxFiles={props.maxFiles}
				multiple={props.multiple}
				inputContent={props.inputContent}
				PreviewComponent={preview}
				disabled={props.disabled || false}
				styles={{
					dropzone: {
						minHeight: 130,
						maxHeight: 200,
						border: '2px dashed #d4d4d4',
						textAlign: 'center',
						overflow: 'auto',
					},
					submitButtonContainer: { marginTop: 10, padding: 0 },
				}}
			/>
		);
	}
}
const MyUploader = props => {
	//NOSONAR
	const {
		//NOSONAR
		loadedFiles,
		fileData,
		maxFiles,
		multiple,
		inputContent,
		businessProfileStore,
		doNotDisable,
		callback,
	} = props;
	let disabled = !businessProfileStore.businessProfile.isEditable;
	if (doNotDisable) {
		disabled = false;
	}
	// specify upload params and url for your filed
	const getUploadParams = () => {
		return { url: 'https://httpbin.org/post' };
	};
	let uploadedFiles = null;
	if (loadedFiles) {
		uploadedFiles = loadedFiles;
	}
	// receives array of files that are done uploading when submit button is clicked
	const handleSubmit = async files => {
		await businessProfileStore.uploadFile2(fileData, files);
		typeof callback === 'function' && callback();
		//some notification to say file is uploaded
	};

	return (
		<FileZone
			uploadedFiles={uploadedFiles}
			handleSubmit={handleSubmit}
			maxFiles={maxFiles}
			multiple={multiple}
			inputContent={inputContent}
			businessProfileStore={businessProfileStore}
			getUploadParams={getUploadParams}
			disabled={disabled}
		/>
	);
};

const DocumentUploader = inject('businessProfileStore')(MyUploader);
export default DocumentUploader;
