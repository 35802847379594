import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Card, CardBody, CardTitle, Row, Col } from '@Comp';
import NoLoan from '../loans/view/no-loans';
import OpenLoanApplications from '../loans/view/open-loan-applications';
import './style.scss';
// icons
import OverallLoanStatus from './overall-loan-status';
import NotificationsTable from '../../components/NotificationsTable';

@inject('viewStore', 'businessProfileStore', 'commonStore')
@observer
class HomePage extends Component {
	componentWillMount() {
		const { viewStore } = this.props;
		viewStore.getNotifictions();
	}
	async componentDidMount() {
		await this.props.businessProfileStore.getUpdatedData();
		await this.props.businessProfileStore.setLoanDetails();
		await this.props.viewStore.refreshDashboardState();
	}
	render() {
		const { viewStore, businessProfileStore } = this.props;
		const {
			noOfLoans,
			noOfLoansApproved,
			totalFunds,
			totalFundsApproved,
			notifications,
		} = viewStore.dashboardState;
		let loanAppl = [];
		let offeredloan = [];
		if (
			businessProfileStore.myLoanApplications &&
			businessProfileStore.myLoanApplications.length > 0
		) {
			loanAppl = businessProfileStore.myLoanApplications;
		}
		if (
			businessProfileStore.offeredloanApplications &&
			businessProfileStore.offeredloanApplications.length > 0
		) {
			offeredloan = businessProfileStore.offeredloanApplications;
		}

		return (
			<div className='view-content view-dashboard second-step'>
				<OverallLoanStatus
					noOfLoans={noOfLoans}
					noOfLoansApproved={noOfLoansApproved}
					totalFunds={totalFunds}
					totalFundsApproved={totalFundsApproved}
				/>
				<Row>
					<Col xs='6'>
						{/* Notifications */}
						<Card style={{ maxHeight: '90em' }}>
							<CardBody className='table-responsive first-step'>
								<CardTitle className='text-uppercase h6'>
									Notifications
								</CardTitle>
								<NotificationsTable data={notifications} />
							</CardBody>
						</Card>
					</Col>
					<Col xs='6'>
						<Card>
							<CardBody className='table-responsive'>
								{/* <OverallLoanStatus/> */}
								{(((loanAppl && loanAppl.length > 0) ||
									(offeredloan && offeredloan.length > 0)) && (
									<OpenLoanApplications
										loanApplications={loanAppl}
										offeredLoanAppls={offeredloan}
									/>
								)) || <NoLoan />}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
		);
	}
}

export default HomePage;
