import React, { Component } from 'react';
import { Button, Label } from '@Comp';
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel
} from 'react-accessible-accordion';
import DirectorDetails from './director-details';
import { inject, observer } from 'mobx-react';

@inject('businessProfileStore')
@observer
class CompanyDirectors extends Component {
    handleRemoveDebtor = (e, directorId) => {
        this.props.businessProfileStore.handleRemove(directorId, 'director');
    };

    handleAddDirector = () => {
        this.props.businessProfileStore.addDirector();
    };

    handleChange = (e, id, index) => {
        const value = e.target.value;
        let name = e.target.name;
        const {
            companyDirectors,
            fieldsToUpdate
        } = this.props.businessProfileStore;
        name = name.replace(id, '');
        companyDirectors[index][name] = value;
        fieldsToUpdate.companyDirectorDirty = true;
    };

    sendError = (index, isErrorExist) => {
        const { companyDirectors } = this.props.businessProfileStore;
        this.props.formDataChange(
            companyDirectors[index],
            'director',
            isErrorExist
        );
    };

    render() {
        const companyDirectors = this.props.businessProfileStore.companyDirectors;
        return (
            <div>
                <Accordion allowZeroExpanded={true}>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Company Director Details
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <div className="p-h-10">
                                <p>
                                    Please Supply Details About All The Directors. Director
                                    Information Should Match Details On Company Registration
                                    Certificate
                                </p>
                                <div id="directors" className="companyInfo">
                                    {companyDirectors.map((companyDirector, index) => {
                                        return(
                                        <DirectorDetails
                                            companyDirector={companyDirector}
                                            onChange={this.handleChange}
                                            onClick={this.handleRemoveDebtor}
                                            key={`${Math.random()*100}`}
                                            index={index}
                                            sendError={this.sendError}
                                        />
                                    )})}
                                </div>
                                <div className="text-sm-right">
                                    <Label>
                                        <Button
                                            id="addDirector"
                                            name="addDirector"
                                            className="btn btn-danger addDirector companyDirectors"
                                            type="button"
                                            onClick={this.handleAddDirector}
                                        >
                                            Add Director
                                        </Button>
                                    </Label>
                                </div>
                            </div>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>
        );
    }
}

export default CompanyDirectors;
