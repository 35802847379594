import {
    observable,
    action
} from 'mobx';
import { LoanDetails, LoansProfile, Offers, Statuses } from '../services/requests';
import commonStore from './commonStore';
import businessProfileStore from './businessProfileStore';
import viewStore from './viewStore';
import storageUtil from './storageUtil';
class LoanStore {
    @observable loanDetails = {
        factoring: false,
        discounting: false,
        loanPercent: 0,
        loanAmount: 0,
        totalRepayable: 0,
        monthlyRepayable: 0,
        interest: 0,
        terms: 0,
        invoiceVerifiedInternally: false,
        invoiceVerifiedExternally: false,
        verificationFailed: false,
        awarded: false,
        paidBackInFull: false,
        awaitingOffers: false,
        awaitingVerification: true,
        rejectedAllOffers: false,
        dateAwarded: new Date(),
        dateSubmitted: new Date(),
        buyer: 'string',
        contactPerson: 'string',
        buyerEmail: 'string',
        loanDescription: 'string',
        buyerRelationship: 'string',
        invoiceDueDate: new Date(),
        invoiceAmount: 0,
        totalPaidBack: 0,
        loansProfile: null,
        offerStatus: 'pending',
        offers: [],
        acceptedOffer: null
    };

    @observable loanProfile = [];
    @observable openLoans = [];

    @action updateLoanDetails(loanDetails) {
        const loanData = this.loanDetails;
        if (loanDetails.invoiceType === 'Purchase Order') {
            loanData.factoring = true;
        } else {
            loanData.discounting = true;
        }
        loanData.invoiceAmount = loanDetails.invoiceAmount;
        loanData.contactPerson = loanDetails.contactPerson;
        loanData.buyerEmail = loanDetails.buyerEmail;
        loanData.loanDescription = loanDetails.loanDescription;
        loanData.invoiceDueDate = new Date(loanDetails.startDate);
        loanData.buyer = loanDetails.invoiceBuyer;
        loanData.buyerRelationship = loanDetails.buyerRelationship;
        loanData.loanPercent = loanDetails.loanPercent;
        loanData.loanAmount = (
            loanDetails.invoiceAmount *
            (loanDetails.loanPercent / 100)).toFixed(2);//NOSONAR
        loanData.loansProfile =
            businessProfileStore.businessProfile.loansProfile._id;
        return loanData;
    }

    @action async updateLoanApplication(id, loanDetails) {
        if (id) {
            const loanData = this.updateLoanDetails(loanDetails);
            commonStore.setLoading(true);
            await LoanDetails.edit(id, loanData);
            commonStore.setLoading(false);
        }

        await businessProfileStore.getUpdatedData();
    }

    @action async updateOfferAction(id, choice, offer, isLast) { //NOSONAR
        if (id) {
            let status = null;
            if (choice === 'accepted') {
                status = await Statuses.getByName('Financing offer accepted');
                if (status && status.data) {
                    status = status.data;
                }
                const obj = {
                    acceptedOffer: offer.id,
                    offerStatus: 'accepted', status: status.id
                };
                await LoanDetails.accepted(id, obj);
            } else {
                status = await Statuses.getByName('Financing offers received but rejected');
                if (status && status.data) {
                    status = status.data;
                }
                if (isLast === 1) {
                    await LoanDetails.edit(id, { status: status._id });
                }
                let status2 = await Statuses.getByName('Financing offer not accepted');
                if (status2 && status2.data) {
                    status2 = status2.data;
                } else {
                    commonStore.setLoading(false);
                    return;
                }
                await Offers.edit(offer.id, { status: status2._id });
                if (status) {
                    await commonStore.addAuditTrail(
                        status.id,
                        offer.smeprofile.id,
                        'loan',
                        {
                            loandetail: id,
                            offer: offer.id
                        }
                    );
                    await commonStore.addAuditTrail(
                        status.id,
                        offer.pofProfile.id,
                        'loan',
                        {
                            loandetail: id,
                            offer: offer.id
                        }
                    );
                }
            }
            commonStore.setLoading(false);
        }

        await businessProfileStore.getUpdatedData();
    }

    @action async postLoanApplication(loanDetails) {
        const loanData = this.updateLoanDetails(loanDetails);
        // Set Status
        await commonStore.loadStaticData();
        const statusArr = commonStore.statusus.filter(f => f.name === 'Awaiting Verification');
        let sts = null;
        if (statusArr[0]._id) {
            sts = statusArr[0]._id;
        }
        loanData.status =
            statusArr && statusArr.length > 0 && sts;
        loanData['offerStatus'] = 'pending';
        commonStore.setLoading(true);
        const response = await LoanDetails.add(loanData);
        if (response) {
            const user = storageUtil.getItem('usr') || false;
            if (user && user.profile) {
                await commonStore.addAuditTrail(
                    sts,
                    user.profile,
                    'loan',
                    { loandetail: response.data.id, smeProfile: user.profile }
                );
            }
            viewStore.setEditLoanDetails(response.data);
        }
        commonStore.setLoading(false);

        await businessProfileStore.getUpdatedData();
    }

    // @action setLoansProfile(){

    // }

    @action async createLoansProfile(profileID) {
        const loansProfile = {
            profile: profileID
        };
        commonStore.setLoading(true);
        await LoansProfile.add(loansProfile);
        commonStore.setLoading(false);
    }

    @action async setUpLoansProfile(profileID) {
        if (profileID && !commonStore.businessProfile.loansProfile) {
            await this.createLoansProfile(profileID);

            await businessProfileStore
                .getUpdatedData();
        }
    }


}

const loanStore = new LoanStore();
export default loanStore;
