
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
    Row,
    Col,
    Label,
    Modal,
} from '@Comp';

@inject('commonStore', 'viewStore', 'businessProfileStore')
@observer
class ApiBalancePage extends Component {
    async componentWillMount() {
        const { commonStore } = this.props;
        // Refresh Admin data
        await commonStore.isAdmin();
        await commonStore.loadStaticData();
    }

    getCreditData = () => {
        const { commonStore } = this.props;
        const { verifyidCredits } = commonStore.adminData;
        const Credits = verifyidCredits
        return Credits
    }

    render() {//NOSONAR
        const { commonStore } = this.props;
        const { verifyidCredits } = commonStore.adminData;
        const Credits = this.getCreditData();

        return (
            <Col xs="9" className='header'>
                <h2>VERIFYID BALANCE</h2>
                <Row >
                    <Col xs="8">
                        <Label className="credits">Credits Balance: {Credits}</Label>
                    </Col>
                </Row>
            </Col>
        )
    }
}


export default ApiBalancePage;
