
import React from 'react';
import { inject, observer } from 'mobx-react';
import numeral from 'numeral';

import {
    Row,
    Col,
    Button,
    Label,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from '@Comp';

const VerifyIDModal = inject('commonStore', 'viewStore')(observer(props => {
    const {
        viewStore,
        showVerifyidModal,
        toggleVerifyidModal,
        activeTabVerifyid,
        setTabVerifyid,
        modalClass,
        companyData,
        directorData,
        auditData,
    } = props;

    const viewDetail =
        viewStore.adminState && viewStore.adminState.businessProfile
            ? viewStore.adminState.businessProfile//NOSONAR
            : null;
    const verifyID = viewDetail && viewDetail.verifyid;
    const verifyidArray = (viewDetail && viewDetail.verifyid && viewDetail.verifyid.Directorinformation);

    const ConditionType = () => {
        if (Array.isArray(verifyidArray)) {
            return true;
        }
    };
    const checkArray = ConditionType();

    const directorsData = () => {
        return (
            <div>
                {verifyidArray
                    ? (
                        <div style={{ marginTop: '20px' }}>
                            {directorData && verifyID && checkArray ? (
                                <Col xs="8">
                                    {verifyidArray.map((data, idx) => (
                                        <div key={idx} >
                                            <Row className="detailRow">
                                                <Col xs="6">
                                                    <Label>FirstName:</Label>
                                                </Col>
                                                <Col xs="6">
                                                    <b>{data.FirstName}</b>
                                                </Col>
                                            </Row>
                                            <Row className="detailRow">
                                                <Col xs="6">
                                                    <Label>LastName:</Label>
                                                </Col>
                                                <Col xs="6">
                                                    <b>{data.LastName}</b>
                                                </Col>
                                            </Row>
                                            <Row className="detailRow">
                                                <Col xs="6">
                                                    <Label>ID Number:</Label>
                                                </Col>
                                                <Col xs="6">
                                                    <b>{data.IDNo}</b>
                                                </Col>
                                            </Row>
                                            <Row className="detailRow">
                                                <Col xs="6">
                                                    <Label>DirectorStatusCode:</Label>
                                                </Col>
                                                <Col xs="6">
                                                    <b>{data.DirectorStatusCode}</b>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                </Col>
                            ) : (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Col xs="8">
                                            <Row className="detailRow">
                                                <Col xs="6">
                                                    <Label>FirstName:</Label>
                                                </Col>
                                                <Col xs="6">
                                                    <b>{viewDetail.verifyid.Directorinformation.FirstName}</b>
                                                </Col>
                                            </Row>
                                            <Row className="detailRow">
                                                <Col xs="6">
                                                    <Label>LastName:</Label>
                                                </Col>
                                                <Col xs="6">
                                                    <b>{viewDetail.verifyid.Directorinformation.LastName}</b>
                                                </Col>
                                            </Row>
                                            <Row className="detailRow">
                                                <Col xs="6">
                                                    <Label>ID Number:</Label>
                                                </Col>
                                                <Col xs="6">
                                                    <b>{viewDetail.verifyid.Directorinformation.IDNo}</b>
                                                </Col>
                                            </Row>
                                            <Row className="detailRow">
                                                <Col xs="6">
                                                    <Label>DirectorStatusCode:</Label>
                                                </Col>
                                                <Col xs="6">
                                                    <b>{viewDetail.verifyid.Directorinformation.DirectorStatusCode}</b>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </div>
                                )}
                        </div>
                    ) : null}
            </div>
        );
    };
    const DirectorResults = directorsData();

    return (
        <div className="modalContainer">
            <Modal
                id={'accountModal'}
                isOpen={showVerifyidModal}
                size="lg"
                toggle={toggleVerifyidModal}
                className={modalClass}
            >
                <ModalHeader toggle={toggleVerifyidModal}>
                    Verifyid Data
                </ModalHeader>
                <ModalBody>
                    <>
                        <div>
                            <ul className="nav nav-pills">
                                <li className="nav-item" onClick={() => setTabVerifyid('companyinformation')}>
                                    <a className={`nav-link ${activeTabVerifyid === 'companyinformation' ? 'active' : ''}`} href="#">Companyinformation</a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${activeTabVerifyid === 'directorinformation' ? 'active' : ''}`}
                                        href="#" onClick={() => setTabVerifyid('directorinformation')}
                                    >Directorinformation
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link ${activeTabVerifyid === 'auditorinformation' ? 'active' : ''}`} href="#" onClick={() => setTabVerifyid('auditorinformation')}>Auditorinformation</a>
                                </li>
                            </ul>
                            {activeTabVerifyid === 'companyinformation'
                                ? (
                                    <div style={{ marginTop: '20px' }}>
                                        { companyData && verifyID ? (
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Col xs="8">
                                                    <Row className="detailRow">
                                                        <Col xs="6">
                                                            <Label>Company Name:</Label>
                                                        </Col>
                                                        <Col xs="6">
                                                            <b>{viewDetail.verifyid.Companyinformation.Company}</b>
                                                        </Col>
                                                    </Row>
                                                    <Row className="detailRow">
                                                        <Col xs="6">
                                                            <Label>Director Count:</Label>
                                                        </Col>
                                                        <Col xs="6">
                                                            <b>{numeral(viewDetail.verifyid.Companyinformation.DirectorCount).format('0')}</b>
                                                        </Col>
                                                    </Row>
                                                    <Row className="detailRow">
                                                        <Col xs="6">
                                                            <Label>Physical Address:</Label>
                                                        </Col>
                                                        <Col xs="6">
                                                            <b>{viewDetail.verifyid.Companyinformation.PhysicalAddress}</b>
                                                        </Col>
                                                    </Row>
                                                    <Row className="detailRow">
                                                        <Col xs="6">
                                                            <Label>Company Trading Age:</Label>
                                                        </Col>
                                                        <Col xs="6">
                                                            <b>{viewDetail.verifyid.Companyinformation.TradingAge}</b>
                                                        </Col>
                                                    </Row>
                                                    <Row className="detailRow">
                                                        <Col xs="6">
                                                            <Label>Company Trading Status:</Label>
                                                        </Col>
                                                        <Col xs="6">
                                                            <b>{viewDetail.verifyid.Companyinformation.TradingStatus}</b>
                                                        </Col>
                                                    </Row>
                                                    <Row className="detailRow">
                                                        <Col xs="6">
                                                            <Label>Tax Number:</Label>
                                                        </Col>
                                                        <Col xs="6">
                                                            <b>{viewDetail.verifyid.Companyinformation.TaxNumber}</b>
                                                        </Col>
                                                    </Row>
                                                    <Row className="detailRow">
                                                        <Col xs="6">
                                                            <Label>Company Registered Date:</Label>
                                                        </Col>
                                                        <Col xs="6">
                                                            <b>{viewDetail.verifyid.Companyinformation.RegisteredDate}</b>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </div>
                                        ) : (
                                                <div className="alert">No data Avaliable</div>
                                            )}
                                    </div>
                                ) : null}
                            {activeTabVerifyid === 'directorinformation'
                                ? (
                                    <div style={{ marginTop: '20px' }}>
                                        {directorData && verifyID ? (
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                {DirectorResults}
                                            </div>
                                        ) : (
                                                <div className="alert">No data Available</div>
                                            )}
                                    </div>
                                ) : null}
                            {activeTabVerifyid === 'auditorinformation'
                                ? (
                                    <div style={{ marginTop: '20px' }}>
                                        {auditData && verifyID ? (
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Col xs="8">
                                                    <Row className="detailRow">
                                                        <Col xs="6">
                                                            <Label>Saipa Number:</Label>
                                                        </Col>
                                                        <Col xs="6">
                                                            <b>{viewDetail.verifyid.Auditorinformation.SAIPA_NO}</b>
                                                        </Col>
                                                    </Row>

                                                    <Row className="detailRow">
                                                        <Col xs="6">
                                                            <Label>Auditor Name:</Label>
                                                        </Col>
                                                        <Col xs="6">
                                                            <b>{viewDetail.verifyid.Auditorinformation.Auditor_Name}</b>
                                                        </Col>
                                                    </Row>
                                                    <Row className="detailRow">
                                                        <Col xs="6">
                                                            <Label>Auditor Last Updated:</Label>
                                                        </Col>
                                                        <Col xs="6">
                                                            <b>{viewDetail.verifyid.Auditorinformation.Last_Updated_Date}</b>
                                                        </Col>
                                                    </Row>
                                                    <Row className="detailRow">
                                                        <Col xs="6">
                                                            <Label>Auditor Years With Company:</Label>
                                                        </Col>
                                                        <Col xs="6">
                                                            <b>{viewDetail.verifyid.Auditorinformation.Years_With_Auditor}</b>
                                                        </Col>
                                                    </Row>

                                                </Col>
                                            </div>
                                        ) : (
                                                <div className="alert">No data Avaliable</div>
                                            )}
                                    </div>
                                ) : null}
                        </div>
                    </>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleVerifyidModal}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}));

export default VerifyIDModal;
