import React, { useState, useEffect } from 'react';
import { Row, Col, Toast } from 'react-bootstrap';
const Example = ({ isShow = false, msg }) => {
	const [show, setShow] = useState(false);
	useEffect(() => {
		if (isShow) {
			setShow(true);
		}
	}, [isShow]);
	return (
		<Toast
			style={{
				position: 'absolute',
				bottom: 0,
				zIndex: 9,
				right: 0,
			}}
			show={show}
			delay={4000}
			autohide
			onClose={() => setShow(false)}
		className='bg-info'>
			<Toast.Header>
				<strong className='mr-auto'>Successful</strong>
			</Toast.Header>
			<Toast.Body className='text-white'>{msg}</Toast.Body>
		</Toast>
	);
};
export default Example;
