import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { View, Loading } from '../shared/components';

import { isMobile } from 'is-mobile';

import '../app.scss';

@inject('commonStore')
@withRouter
@observer
export default class AppContainer extends React.Component {
    render() {
       // let { commonStore } = this.props;

        return (
            <div className="app-wrapper dashboardContainer">
                <Loading/>
                {this.props.children}
            </div>
        )
    }
}
