import React from "react";
import { inject, observer } from "mobx-react";
import { ViewHeader, ViewContent } from "@Shared";
import { View } from "../../../../shared/components";

@inject("commonStore")
@observer
export default class Clean extends React.Component {
  handleClick = () => {
    const { push } = this.props.history;
    push("/home");
  };

  render() {
    // let { commonStore } = this.props;
    return (
      <View>
        <ViewHeader title="Clean" description="Some Description" />
        <ViewContent>
          <h6 className="text-uppercase mb-4">Some Heading</h6>
          <button onClick={this.handleClick}>Go Home</button>
        </ViewContent>
      </View>
    );
  }
}
