import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ auth, component: Component, render: Render, ...rest }) => { //NOSONAR
  return (
    <Route {...rest} render={props => (
      (auth === true)
        ? (Component)?<Component {...props} />: <Render {...props}/>
        : <Redirect from="*" to={{
            pathname: '/login',
            state: { from: props.location}}} />
    )} />
  );
};

export default PrivateRoute;
