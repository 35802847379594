import React, { Fragment, useEffect, useState } from "react";
import { getTransunionData } from "../services/requests";

const TransunionModalData = ({ data, businessProfile }) => {
  const [res, setRes] = useState(data);

  useEffect(() => {
    async function getTransData() {
      const userId = businessProfile.user._id;
      if (data === null) {
        let cipc = businessProfile.cipcRegNo;
        let reg_no = cipc.replace(/[^0-9]/g, "");
        // comment out the next line
        reg_no = 201726372607;
        let modulesresponse = await getTransunionData(reg_no, userId);
        if (modulesresponse) {
          setRes(modulesresponse.data);
        } else setRes(modulesresponse);
      }
    }
    getTransData();
  }, [data, businessProfile.cipcRegNo, businessProfile.user._id]);

  const [activeTabVerifyid, setTabVerifyid] = useState("companyinformation");

  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <ul className="nav nav-pills">
          <li
            className="nav-item"
            onClick={() => setTabVerifyid("companyinformation")}
          >
            <a
              className={`nav-link ${
                activeTabVerifyid === "companyinformation" ? "active" : ""
              }`}
              href="#"
            >
              Company Information
            </a>
          </li>
          <li
            className="nav-item"
            onClick={() => setTabVerifyid("businessDeedsSummary")}
          >
            <a
              className={`nav-link ${
                activeTabVerifyid === "businessDeedsSummary" ? "active" : ""
              }`}
              href="#"
            >
              Business Deeds Summary
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTabVerifyid === "directorinformation" ? "active" : ""
              }`}
              href="#"
              onClick={() => setTabVerifyid("directorinformation")}
            >
              Director information
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTabVerifyid === "bankcodes" ? "active" : ""
              }`}
              href="#"
              onClick={() => setTabVerifyid("bankcodes")}
            >
              Bank Codes
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTabVerifyid === "auditorinformation" ? "active" : ""
              }`}
              href="#"
              onClick={() => setTabVerifyid("auditorinformation")}
            >
              Auditor information
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${
                activeTabVerifyid === "additionalinformation" ? "active" : ""
              }`}
              href="#"
              onClick={() => setTabVerifyid("additionalinformation")}
            >
              Additional Information
            </a>
          </li>
        </ul>
        <div style={{ marginTop: "20px" }}></div>

        {res && res.modulesresponse ? (
          activeTabVerifyid === "companyinformation" ? (
            <div className="table-responsive">
              <table className="table">
                <tbody>
                  {Object.entries(res.modulesresponse["FirstResponse"])
                    .reverse()
                    .map(
                      ([k, v], i) =>
                        k !== "TicketNumber" && (
                          <tr key={i}>
                            <td>{k.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")}</td>
                            <td>{v}</td>
                          </tr>
                        )
                    )}
                  {Object.entries(res.modulesresponse["Header"]).map(
                    ([k, v], i) =>
                      typeof v !== "object" && (
                        <tr key={i}>
                          <td>{k.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")}</td>
                          <td>
                            {typeof v === "object"
                              ? ""
                              : !isNaN(v)
                              ? Number(v)
                              : v}
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </div>
          ) : activeTabVerifyid === "businessDeedsSummary" ? (
            <div className="table-responsive">
              <table className="table">
                <tbody>
                  {Object.entries(
                    res.modulesresponse["BusinessDeedsSummaryDA"][
                      "BusinessDeedsSummaryDA"
                    ]
                  ).map(
                    ([k, v], i) =>
                      k !== "MajorProduct" && (
                        <tr key={i}>
                          <td>{k.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")}</td>
                          <td>
                            {typeof v === "object"
                              ? ""
                              : !isNaN(v)
                              ? Number(v)
                              : v}
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </div>
          ) : activeTabVerifyid === "directorinformation" ? (
            <div className="table-responsive">
              <table className="table">
                <tbody>
                  {res.modulesresponse["Principals"]["Principal"].map(
                    (principal, i) => (
                      <Fragment key={i}>
                        <thead>
                          <tr>
                            <th>Principal Name</th>
                            <th>
                              {typeof principal["Forename1"] !== "object" &&
                                `${principal["Forename1"]} `}
                              {typeof principal["Forename2"] !== "object" &&
                                `${principal["Forename2"]} `}
                              {typeof principal["Forename3"] !== "object" &&
                                `${principal["Forename3"]} `}
                              {typeof principal["Surname"] !== "object" &&
                                `${principal["Surname"]} `}
                            </th>
                          </tr>
                        </thead>
                        <tr>
                          <td>DOB</td>
                          <td>{principal["DateOfBirth"]}</td>
                        </tr>
                        <tr>
                          <td>ID Number</td>
                          <td>{principal["IDNumber"]}</td>
                        </tr>
                        <tr>
                          <td>Date Started</td>
                          <td>{principal["DateStarted"]}</td>
                        </tr>
                        <tr>
                          <td>Confirmed by Registrar</td>
                          <td>{principal["ConfirmedByRegistrar"]}</td>
                        </tr>
                        <tr>
                          <td>Civil Court Record</td>
                          <td>{principal["CivilCourtRecord"]}</td>
                        </tr>
                      </Fragment>
                    )
                  )}
                </tbody>
              </table>
            </div>
          ) : activeTabVerifyid === "bankcodes" ? (
            <div className="table-responsive">
              <table className="table">
                <tbody>
                  {Object.entries(
                    res.modulesresponse["BankCodes"]["BankCodes"]
                  ).map(([k, v], i) =>
                    k !== "MajorProduct" && k !== "Comment" ? (
                      <tr key={i}>
                        <td>{k.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")}</td>
                        <td>
                          {typeof v === "object"
                            ? ""
                            : !isNaN(v)
                            ? Number(v)
                            : v}
                        </td>
                      </tr>
                    ) : (
                      k === "Comment" && (
                        <tr key={i}>
                          <td>Comments</td>
                          <td>{v["string"][0]}</td>
                        </tr>
                      )
                    )
                  )}
                </tbody>
              </table>
            </div>
          ) : activeTabVerifyid === "auditorinformation" ? (
            <></>
          ) : activeTabVerifyid === "additionalinformation" ? (
            <div className="table-responsive">
              <table className="table">
                <tbody>
                  <tr>
                    <td>Court Records</td>
                    <td>
                      {
                        res.modulesresponse["CourtRecords"]["CourtRecord"][
                          "Message"
                        ]
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>Band Description</td>
                    <td>{res.modulesresponse["SMEAssessment"]["BandDesc"]}</td>
                  </tr>
                  <tr>
                    <td>Odds Of Failure</td>
                    <td>
                      {res.modulesresponse["SMEAssessment"]["OddsOfFailure"]}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <></>
          )
        ) : res === undefined ? (
          <>Company Registration number is incorrect</>
        ) : (
          <>Loading...</>
        )}
      </div>
    </>
  );
};

export default TransunionModalData;

// const [collapse, setCollapse] = useState("");
// const toggleCollapse = (key) => {
//   if (key === collapse) {
//     setCollapse("");
//   } else {
//     setCollapse(key);
//   }
// };
// {res && res.modulesresponse ? (
//   <div style={{ display: "flex", flexDirection: "column" }}>
//     {/* Major Product */}

//     <button
//       className="btn btn-primary"
//       type="button"
//       onClick={() => toggleCollapse("majorProduct")}
//     >
//       Major Product
//     </button>
//     {collapse === "majorProduct" ? (
//       <div id="majorProducts">
//         <div className="table-responsive">
//           <table className="table">
//             <tbody>
//               <tr>
//                 <td>Major Product Code</td>
//                 <td>
//                   {
//                     res.modulesresponse["RegistrationDetailsExtended"][
//                       "MajorProduct"
//                     ]
//                   }
//                 </td>
//               </tr>
//             </tbody>
//           </table>
//         </div>
//       </div>
//     ) : null}

//     {/* BusinessDeedsSummaryDA */}

//     <button
//       className="btn btn-primary"
//       type="button"
//       onClick={() => toggleCollapse("BusinessDeedsSummaryDA")}
//     >
//       Business Deeds Summary
//     </button>
//     {collapse === "BusinessDeedsSummaryDA" ? (
//       <div id="BusinessDeedsSummaryDA">
//         <div className="table-responsive">
//           <table className="table">
//             <tbody>
//               {Object.entries(
//                 res.modulesresponse["BusinessDeedsSummaryDA"][
//                   "BusinessDeedsSummaryDA"
//                 ]
//               ).map(([k, v], i) => (
//                 <tr key={i}>
//                   <td>{k.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")}</td>
//                   <td>
//                     {typeof v === "object"
//                       ? ""
//                       : !isNaN(v)
//                       ? Number(v)
//                       : v}
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     ) : null}

//     {/* BusinessPrincipalDeedsSummaryP8 */}

//     <button
//       className="btn btn-primary"
//       type="button"
//       onClick={() => toggleCollapse("PrincipalDeedsSummaryP8")}
//     >
//       Principal Deeds Summary
//     </button>
//     {collapse === "PrincipalDeedsSummaryP8" ? (
//       <div id="PrincipalDeedsSummaryP8">
//         <div className="table-responsive">
//           <table className="table">
//             <tbody>
//               {res.modulesresponse["PrincipalDeedsSummaryP8"][
//                 "PrincipalDeedsSummaryP8"
//               ].map((principal, i) => (
//                 <>
//                   <thead key={i}>
//                     <tr>
//                       <th scope="col">{`Principal No ${i + 1}`}</th>
//                       <th scope="col">{principal.PrincipalName}</th>
//                     </tr>
//                   </thead>
//                   {Object.entries(principal).map(([k, v], j) => (
//                     <tr key={j}>
//                       <td>
//                         {k.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")}
//                       </td>
//                       <td>
//                         {typeof v === "object"
//                           ? ""
//                           : !isNaN(v)
//                           ? Number(v)
//                           : v}
//                       </td>
//                     </tr>
//                   ))}
//                 </>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     ) : null}

//     {/* BankCodesBC */}

//     <button
//       className="btn btn-primary"
//       type="button"
//       onClick={() => toggleCollapse("BankCodesBC")}
//     >
//       Bank Codes
//     </button>
//     {collapse === "BankCodesBC" ? (
//       <div id="BankCodesBC">
//         <div className="table-responsive">
//           <table className="table">
//             <tbody>
//               {Object.entries(
//                 res.modulesresponse["BankCodes"]["BankCodes"]
//               ).map(([k, v], j) =>
//                 k !== "Comment" ? (
//                   <tr key={j}>
//                     <td>{k.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")}</td>
//                     <td>
//                       {typeof v === "object"
//                         ? ""
//                         : !isNaN(v)
//                         ? Number(v)
//                         : v}
//                     </td>
//                   </tr>
//                 ) : (
//                   <tr key={j}>
//                     <td>{k.match(/[A-Z][a-z]+|[0-9]+/g).join(" ")}</td>
//                     <td>{v["string"][0]}</td>
//                   </tr>
//                 )
//               )}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     ) : null}
//   </div>
// ) : (
//   <div className="alert">No data available</div>
// )}
