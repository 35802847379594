/* eslint-disable react/jsx-no-duplicate-props */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Card, CardBody } from '@Comp';
import { withRouter } from 'react-router-dom';
import numeral from 'numeral';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import './styles.scss';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
@inject('viewStore', 'commonStore', 'businessProfileStore')
@withRouter
@observer
class OpenLoanApplications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allLoanAppls: null
        };
        this.options = {
            paginationSize: 5,
            pageStartIndex: 1,
            sizePerPageList: [5]
        };//NOSONAR
        this.statusSelectOptions = [{}, {}];
    }


    linkFormatter = (cell, row) => {
        const { history } = this.props;
        return (
            <div
                onClick={() => {
                    // Edit
                    history.push(`/home/loan/singleview/${row._id}`);
                }}
                className="text-primary cursor text-center"
            >
                {cell}
            </div>
        );
    };
    statusFormatter = (cell, row) => {
        const text = Object.values(this.statusSelectOptions[0]).find(option => option === cell);
        return (
            <div style={{ color: `${row.color || '#000'}` }}>
                {text}
            </div>
        );
    };
    statusFormatter2 = (cell, row) => {
        const text = Object.values(this.statusSelectOptions[1]).find(option => option === cell);
        return (
            <div style={{ color: `${row.color || '#000'}` }}>
                {text}
            </div>
        );
    };
    render() { //NOSONAR
        const { loanApplications } = this.props;
        const offeredLoanAppls = this.props.businessProfileStore && this.props.businessProfileStore.offeredloanApplications;
        let allLoanAppls = null;
        if (offeredLoanAppls || loanApplications) {
            loanApplications.reduce((acc, cur) => {
                acc[cur.status.smeTitle || cur.status.name] = cur.status.smeTitle || cur.status.name;
                return acc;
            }, this.statusSelectOptions[0]);
            allLoanAppls = [loanApplications, offeredLoanAppls].map(data => {
                return data.map(details1 => {
                    const details = { ...details1 };
                    if (details.offers.length > 0) {
                        if (details.offerStatus === 'accepted') {
                            const accepted = details.offers.find(offer => offer.id === details.acceptedOffer);
                            details.status = accepted.status;
                        } else {
                            const awaiting = details.offers.find(offer => offer.status.id === '5ec3dfc47db6ab2ca11d07b6' || offer.status.id === '5d9d9fcb875e3cbf48258855');
                            details.status = awaiting.status;
                        }
                    }
                    if (details.offers.length > 0) {
                        details.offers.reduce((acc, cur) => {
                            acc[cur.status.smeTitle || cur.status.name] = cur.status.smeTitle || cur.status.name;
                            return acc;
                        }, this.statusSelectOptions[1]);
                    }
                    return {
                        _id: details._id,
                        key: details.id,
                        buyer: details.buyer,
                        date: new Date(details.dateSubmitted || details.createdAt).toDateString(),
                        value: `R ${numeral(details.loanAmount || 0).format('0,0')}`,
                        status: details.status && (details.status.smeTitle || details.status.name),
                        color: details.status && details.status.color
                    };
                });
            });
        }
        const columns = [
            {
                text: 'Corporate Buyer',
                dataField: 'buyer',
                formatter: this.linkFormatter
            },
            {
                text: 'date',
                dataField: 'date'
            },
            {
                text: 'Value',
                dataField: 'value'
            },
            {
                text: 'Status',
                dataField: 'status',
                formatter: this.statusFormatter,
                filter: selectFilter({
                    options: this.statusSelectOptions[0]
                })
            }];

        const columns2 = [
            {
                text: 'Corporate Buyer',
                dataField: 'buyer',
                formatter: this.linkFormatter
            },
            {
                text: 'date',
                dataField: 'date'
            },
            {
                text: 'Value',
                dataField: 'value'
            },
            {
                text: 'Status',
                dataField: 'status',
                formatter: this.statusFormatter2,
                filter: selectFilter({
                    options: this.statusSelectOptions[1]
                })
            }];
        return (
            <>
                {Array.isArray(allLoanAppls) && (<div>
                    <Card className="mb-4">
                        <CardBody className="table-responsive">
                            <h3 className="mb-4 text-uppercase">
                                <span className="badge badge-info">My Loan Applications</span>
                            </h3>
                            <BootstrapTable
                                keyField="key"
                                columns={columns}
                                data={allLoanAppls && allLoanAppls[0]}
                                pagination={paginationFactory(this.options)}
                                filter={filterFactory()}
                            />
                        </CardBody>
                    </Card>

                    <Card className="mb-4">
                        <CardBody className="table-responsive">
                            <h3 className="mb-4 text-uppercase">
                                <span className="badge badge-info">Loan Applications with Offers</span>
                            </h3>
                            <BootstrapTable
                                keyField="key"
                                columns={columns2}
                                data={this.state.offers || []}
                                data={allLoanAppls && allLoanAppls[1]}
                                pagination={paginationFactory(this.options)}
                                filter={filterFactory()}
                            />
                        </CardBody>
                    </Card>
                </div>)}
            </>
        );
    }
}

export default OpenLoanApplications;
