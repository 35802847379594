import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Container, Row, Col } from "react-bootstrap";
import { config } from "../../../constants";
import images from "../../../assets";
import { Button, Icon } from "@material-ui/core";
import YodleeModal from "./yodleeModal";
import Verifyid from "../veifyid-data/verifyid";
import { OauthIntegrations } from "../../../services/requests";
import DiaModal from "./DiaModal";
import swal from "sweetalert";

@inject("verifyIdStore")
@inject("commonStore")
@inject("businessProfileStore")
@observer
class DataVerificationLink extends Component {
  constructor() {
    super();
    this.state = {
      sageData: {},
      showFastLinkModal: false,
      showSageOneModal: false,
      showDiaModal: false,
      isDiaOpened: false,
      directorDetails: [],
      dirSavedCapturedImg: null,
      isExtensionInstalled: true,
    };
  }

  componentDidMount() {
    let img = new Image();
    img.src = `chrome-extension://${config.NISADOCLOCK_EXTENSION_ID}/logos/logo_16.png`;
    img.onload = () => this.setState({ isExtensionInstalled: true });
    img.onerror = () => this.setState({ isExtensionInstalled: false });
  }

  onFastLinkClose = (error) => {
    if (error) {
      console.error("FastLink Error:", error);
      // Provide additional error handling or feedback as needed
      // For example, you might want to show a user-friendly message
      // this.props.showErrorMessage('An error occurred during the FastLink process.');
    }
    window.fastlink.close();
    this.setState({ showFastLinkModal: false });
    this.props.updateProviderData();
  };
  onSageOneClose = () => {
    this.setState({ showSageOneModal: false });
  };
  onShowDiaClose = () => {
    this.setState({ showDiaModal: false, isDiaOpened: true });
    video.pause();
    let tracks = video.srcObject.getTracks();
    for (var i = 0; i < tracks.length; i++) {
      var track = tracks[i];
      track.stop();
    }
    video.srcObject = null;
  };

  onFastLinkSuccess = async () => {
    this.setState({ showFastLinkModal: false });
    await OauthIntegrations.fetchYodleeData();
    this.props.updateProviderData();
  };

  removeSagedata = async () => {
    await OauthIntegrations.removeSagedata();
    this.props.updateProviderData();
  };

  removeXerodata = async () => {
    await OauthIntegrations.removeXerodata();
    this.props.updateProviderData();
  };

  removeYodleedata = async () => {
    await OauthIntegrations.removeYodleedata();
    this.props.updateProviderData();
  };

  goToAccount = async (
    accountName,
    clientId,
    redirectUri,
    scope,
    prefixUri
  ) => {
    if (accountName === "yodlee") {
      const { data = null } = await OauthIntegrations.getYodleeAccessToken();
      if (data && data.token.accessToken) {
        this.setState({ showFastLinkModal: true });
        const Thousand = 1000;
        setTimeout(() => {
          window.fastlink.open(
            {
              fastLinkURL:
                "https://fl4.sandbox.yodlee.com/authenticate/restserver/fastlink",
              accessToken: `Bearer ${data.token.accessToken}`,
              params: {
                configName: "Aggregation",
              },
              onSuccess: this.onFastLinkSuccess,
              // onError: this.onFastLinkClose,
              onError: this.onFastLinkClose.bind(this), // Error callback

              onExit: this.onFastLinkClose,
            },
            "container-fastlink"
          );
        }, Thousand);
      }
    }
    if (accountName === "xero") {
      const three = 3,
        thirtySix = 36;
      const randomString = Math.random().toString(thirtySix).substring(three);
      this.props.businessProfileStore.randomString = randomString;
      window.location = `${prefixUri}&client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}&state=${randomString}`;
    }
    if (accountName === "sageone") {
      this.setState({ showSageOneModal: true });
    }
    if (accountName === "dia") {
      const BusinessId = this.props.commonStore.businessProfile.id;
      let { data } = await OauthIntegrations.getSavedCapturedData(BusinessId);
      let { companyDirectors } = this.props.businessProfileStore;
      let directorDetails = companyDirectors.map((director) => {
        return {
          idNo: director.idNo,
          name: `${director.firstName} ${director.lastName}`,
        };
      });
      // comment out the next line
      directorDetails = [
        { idNo: 9605085341086, name: "Thando" },
        { idNo: 9709306081081, name: "Sinqobile" },
        { idNo: 980129542008, name: "shobhit" },
      ];
      this.setState({
        showDiaModal: true,
        directorDetails: directorDetails,
        dirSavedCapturedImg: data.dirSavedCapturedImg,
      });
    }
    return false;
  };

  updateSageData = async (obj) => {
    this.setState({ sageData: { ...obj } });
    await OauthIntegrations.setAuthData({
      ...obj,
      provider: "sageone",
    });
  };
  sageFormData = async (username, password) => {
    const data = await OauthIntegrations.setSage({ username, password });
    this.props.updateProviderData();
    this.onSageOneClose();
  };

  updateSageData = async (obj) => {
    //NOSONAR
    this.setState({ sageData: { ...obj } });
    await OauthIntegrations.setAuthData({
      ...obj,
      provider: "sageone",
    });
  };

  openExtensionPage = async () => {
    swal({
      title: "🌟 Coming Soon! 🌟",
      text: "We are working hard to bring you something amazing. Stay tuned for updates!",
      background: "#f3f4f6",
      confirmButtonText: "Got it!",
      confirmButtonColor: "#007bff",
    });

    // window.open("https://www.google.com", "_blank");
  };

  render() {
    const { providerAuthData } = this.props;
    const check = this.props.commonStore.businessProfile;
    const showAfterConnectCheck = () => {
      if (check && check.verifyid !== "") {
        return true;
      }
    };
    return (
      <div>
        <div className="p-h-15 p-v-20">
          <p>
            In order to verify the contents of your business profile and provide
            you loan , we reuire you to linking the following accounts to
            provide us with additional information about your business.
          </p>
        </div>
        <div className="tenth-step">
          <Container fluid={true}>
            <Row>
              <Col>
                <h3>Link Accounts</h3>
              </Col>
            </Row>
            {/* {!this.state.isExtensionInstalled && (
              <AccountList
                title="NISA DOCLOCK (EXTENSION)"
                img={images.nisaDoclockLogo}
                slug="NisaDoclock"
                actionFunction={this.openExtensionPage}
                actionName="add"
              />
            )} */}
            {providerAuthData &&
              providerAuthData.map((v) => {
                if (v.provider === "xero" && !v.hasValidToken) {
                  return (
                    <AccountList
                      title="XERO (ACCOUNTING)"
                      img={images.xeroLogo}
                      slug="xero"
                      actionFunction={this.goToAccount}
                      actionName="add"
                      key={v.provider}
                      {...v}
                    />
                  );
                }
                // if (v.provider === "sageone" && !v.hasValidToken) {
                //   return (
                //     <AccountList
                //       title="SAGE ONE (ACCOUNTING)"
                //       img={images.sageoneLogo}
                //       slug="sageone"
                //       actionFunction={this.goToAccount}
                //       actionName="add"
                //       key={v.provider}
                //       updateSageData={this.updateSageData}
                //       {...v}
                //     />
                //   );
                // }
                if (v.provider === "yodlee" && !v.hasValidToken) {
                  return (
                    <AccountList
                      title="YODLEE (BANK ACCOUNT)"
                      img={images.yodleeLogo}
                      slug="yodlee"
                      actionFunction={this.goToAccount}
                      actionName="add"
                      key={v.provider}
                      {...v}
                    />
                  );
                }
                if (v.provider === "DIA" && !v.hasValidToken) {
                  return (
                    <AccountList
                      title="DIA (FACE VERIFICATION)"
                      img={images.diaLogo}
                      slug="dia"
                      actionFunction={this.goToAccount}
                      actionName="add"
                      key={v.provider}
                      {...v}
                    />
                  );
                }
                return null;
              })}
            {showAfterConnectCheck() !== true ? <Verifyid /> : null}
            <h3>
              <hr />
              Existing Linked Accounts
            </h3>
            {providerAuthData &&
              providerAuthData.map((v) => {
                if (v.provider === "xero" && v.hasValidToken) {
                  return (
                    <AccountList
                      title="XERO (ACCOUNTING)"
                      img={images.xeroLogo}
                      slug="xero"
                      actionFunction={this.removeXerodata}
                      actionName="delete"
                      key={v.provider}
                      {...v}
                    />
                  );
                }
                if (v.provider === "sageone" && v.hasValidToken) {
                  return (
                    <AccountList
                      title="SAGE ONE (ACCOUNTING)"
                      img={images.sageoneLogo}
                      slug="sageone"
                      actionFunction={this.removeSagedata}
                      actionName="delete"
                      key={v.provider}
                      updateSageData={this.updateSageData}
                      {...v}
                    />
                  );
                }
                if (v.provider === "yodlee" && v.hasValidToken) {
                  return (
                    <AccountList
                      title="YODLEE (BANK ACCOUNT)"
                      img={images.yodleeLogo}
                      slug="yodlee"
                      actionFunction={this.removeYodleedata}
                      actionName="delete"
                      key={v.provider}
                      {...v}
                    />
                  );
                }
                if (v.provider === "DIA" && v.hasValidToken) {
                  return (
                    <AccountList
                      title="DIA (FACE VERIFICATION)"
                      img={images.diaLogo}
                      slug="dia"
                      actionFunction={this.goToAccount}
                      actionName="edit"
                      key={v.provider}
                      {...v}
                    />
                  );
                }
                return null;
              })}
            {showAfterConnectCheck() === true ? <Verifyid /> : null}
          </Container>
          {/* <div className="float-right text-sm-right">
               <button className="btn btn-info">Save</button>
            </div> */}
          <YodleeModal
            show={this.state.showFastLinkModal}
            onHide={this.onFastLinkClose}
          ></YodleeModal>
          <YodleeModal
            show={this.state.showSageOneModal}
            onHide={this.onSageOneClose}
            sageone={true}
            sageFormData={this.sageFormData}
          ></YodleeModal>
          <DiaModal
            show={this.state.showDiaModal}
            onHide={this.onShowDiaClose}
            isDiaOpened={this.state.isDiaOpened}
            businessId={this.props.commonStore.businessProfile.id}
            directorDetails={this.state.directorDetails}
            dirSavedCapturedImg={this.state.dirSavedCapturedImg}
          />
        </div>
      </div>
    );
  }
}

const AccountList = (props) => {
  //NOSONAR
  const {
    title,
    slug,
    actionFunction,
    actionName,
    img,
    clientId,
    redirectUri,
    scope,
    prefixUri,
  } = props;
  return (
    <Row className="mt-3 align-items-center">
      <Col md={{ span: 4 }}>{title}</Col>
      <Col md={{ span: 5 }} className="d-flex">
        <>
          <div className="w-50 text-center heightFix">
            <img src={img} alt="img" style={{ height: "100%" }} />
          </div>
          <Button
            color="primary"
            onClick={() =>
              actionFunction(slug, clientId, redirectUri, scope, prefixUri)
            }
          >
            <Icon color="primary">{actionName}</Icon>
          </Button>
        </>
      </Col>
    </Row>
  );
};

export default DataVerificationLink;
