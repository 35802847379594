import { observable, action } from 'mobx';
import commonStore from './commonStore';
import storageUtil from './storageUtil';
import viewStore from './viewStore';
import {
    BusinessAddresses,
    CompanyDetails,
    Profiles,
    CompanyDirectors,
    BusinessFinancials,
    AnnualBalanceSheets,
    AnnualIncomeStatements,
    AccountantDetails,
    CashFlowRecords,
    PastInvoices,
    DebtorInvoice,
    Upload,
    LoanDetails,
    LoansProfile,
    Bankaccountdetails,
    Statuses
} from '../services/requests';
import { TEN, FIFTEEN, FIVE, THIRTY } from '../constants/constants';
import { create, persist } from 'mobx-persist';

class BusinessProfile1 {
    @persist @observable randomString = '';
    @observable businessProfile = {
        id: null,
        businessName: null,
        businessEmail: null,
        profileVerified: false,
        complete: false,
        isIdle: true,
        percCompleted: 0,
        loansProfile: null,
        latestloanstatus: null,
        user: null,
        cipcRegNo: null,
        companyDetails: null,
        businessFinancials: null,
        pastInvoices: null,
        profileSetup: false,
        bankaccountdetail: '',
        isEditable: false
    };
    @observable profilePercenatge = 0;
    @observable validationErrors = {
        businessAddress: {}
    };

    @observable companyData = '';
    @observable businessFinancialData = '';
    @observable pastInvoiceData = '';

    @observable fieldsToUpdate = {
        businessAddressDirty: false,
        companyDirectorDirty: false,
        annualBalanceSheetDirty: false,
        annualIncomeStatementDirty: false,
        accountantDetailsDirty: false,
        deleteDirectorDirty: false,
        bankAccountDetailsDirty: false,
        cashFlowDirty: false
    };

    @observable businessAddress = '';
    @observable isBusinessAddressExist = false;
    @observable directorAutofilled = false;
    @observable companyDirectors = [
        {
            firstName: '',
            lastName: '',
            idNo: '',
            email: '',
            cellNo: '',
            certifiedID: [],
            companyDetails: null,
            id: 1
        }];
    //Financial Declarations
    @observable incomeStatement = {
        turnOver: '',
        costOfSales: '',
        netProfitAfterTax: '',
        businessFinancial: null,
        id: null
    };

    @observable accountantDetails = {
        saipaNo: '',
        idNo: '',
        email: '',
        supportDocument: [],
        businessFinancials: null,
        _id: null
    };

    @observable cashFlowRecords = [];

    @observable balanceSheet = {
        accountsReceivable: '',
        cashAndCashEquivalents: '',
        accountsPayable: '',
        inventory: '',
        totalCurrentLiabilities: '',
        // businessFinancials: null,
        id: null
    };
    @observable pastInvoices = [
        {
            companyName: '',
            companyEmail: '',
            invoiceAmount: '',
            invoiceCopy: [],
            id: 1,
            pastInvoices: null
        }];
    @observable myLoanApplications = [];
    @observable loanApplications = [];
    @observable offeredloanApplications = [];
    @observable bankAccountDetails = {
        accountNumber: '',
        accountType: '',
        branchCode: '',
        bankName: '',
        id: '',
        confirmationLetter: '',
        _id: ''
    };
    @observable isVerified = false;
    //Actions
    @action async getUpdatedData(id) {//NOSONAR
        const profileID = id || storageUtil.getItem('usr').profile;

        commonStore.setLoading(true);
        await commonStore.getBusinessProfile(profileID);
        if (commonStore.isProfileViewError) {
            commonStore.setLoading(false);
            return false;
        }
        const {
            companyDetails,
            businessFinancials,
            pastInvoices,
            bankaccountdetail
        } = this.businessProfile;
        if (companyDetails && companyDetails.companyDirectors && companyDetails.companyDirectors.length > 0) {
            this.companyDirectors = companyDetails.companyDirectors;
        } else {
            this.companyDirectors = [
                {
                    firstName: '',
                    lastName: '',
                    idNo: '',
                    email: '',
                    cellNo: '',
                    certifiedID: [],
                    companyDetails: null,
                    id: 1
                }]
        }

        if (companyDetails &&
            companyDetails.businessAddress) {
            this.isBusinessAddressExist = true;
        }
        this.setBusinessFinancials(businessFinancials);
        this.setbankAccountDetails(bankaccountdetail, this.bankAccountDetails);
        if (pastInvoices && pastInvoices.debtorInvoices && pastInvoices.debtorInvoices.length > 0) {
            const availableDebtorInvoices = pastInvoices.debtorInvoices.filter((di)=> !di.deleted)
            if(availableDebtorInvoices && availableDebtorInvoices.length){
                this.pastInvoices = availableDebtorInvoices;
            } else {
                this.pastInvoices = [
                    {
                        companyName: '',
                        companyEmail: '',
                        invoiceAmount: '',
                        invoiceCopy: [],
                        id: 1,
                        pastInvoices: null
                    }]
            }
        } else {
            this.pastInvoices = [
                {
                    companyName: '',
                    companyEmail: '',
                    invoiceAmount: '',
                    invoiceCopy: [],
                    id: 1,
                    pastInvoices: null
                }]
        }

        this.progressBarStatus();
        commonStore.setLoading(false);
    }

    @action async setLoanDetails() {
        if (
            this.businessProfile.loansProfile &&
            this.businessProfile.loansProfile._id
        ) {
            const appls = await this.getLoanDetails(this.businessProfile.loansProfile._id);
            if (Array.isArray(appls) && appls.length > 0) {
                const appls2 = [...appls];
                this.loanApplications = appls;
                this.myLoanApplications = appls.filter(application => Array.isArray(application.offers) && application.offers.length === 0);
                this.offeredloanApplications = appls2.filter(application => Array.isArray(application.offers) && application.offers.length > 0);
            }
        }
    }

    @action async setbankAccountDetails(bankaccountdetail, localbankAccountDetail) {
        if (bankaccountdetail && bankaccountdetail._id) {
            const data = Object.keys(localbankAccountDetail).reduce((acc, cur) => {
                acc[cur] = bankaccountdetail[cur];
                return acc;
            }, {});
            this.bankAccountDetails = data;
        }else{
            this.bankAccountDetails ={
                accountNumber: '',
                accountType: '',
                branchCode: '',
                bankName: '',
                id: '',
                confirmationLetter: '',
                _id: ''
            }
        }
    }
  //fetch verify id for autofilling
@action async autoFillDetails(){
    const {Directorinformation , Companyinformation} = this.businessProfile.verifyid;
    if(Object.keys(Directorinformation).length > 0){
    let localDirectors = [];
    for(let i=0;i<Object.keys(Directorinformation).length;i++){
        if(Directorinformation[i]['DirectorStatusCode'] === 'Active') { 
            const cloneOriginal = {...this.companyDirectors[0]}
            cloneOriginal.firstName=Directorinformation[i]['FirstName'];
            cloneOriginal.cellNo=Directorinformation[i]['CellNo'];
            cloneOriginal.lastName=Directorinformation[i]['LastName'];
            cloneOriginal.idNo=Directorinformation[i]['IDNo'];
            cloneOriginal.id = i+1;
            localDirectors.push(cloneOriginal);
        }
    }
    //addrees autofill 
    if(Companyinformation.PhysicalAddress){
        const address = Companyinformation.PhysicalAddress.split(',');
        const l = address.length;
        const localBusinessAddress = {
            unitNo: '',
            streetName: address[l-5],
            suburb: address[l-4],
            city: address[l-3],
            province: address[l-2],
            postalCode: address[l-1],
        }
    this.businessProfile.companyDetails['businessAddress'] = localBusinessAddress;
    this.fieldsToUpdate.businessAddressDirty = true;
    }
    this.companyDirectors = localDirectors;
    this.fieldsToUpdate.companyDirectorDirty = true;
    this.directorAutofilled = true;

    

    }
}

    //check verified or not business profile 
    @action async isProfileVerified() {//NOSONAR
        if (this.businessProfile.id) {
            const {
                companyDetails,
                businessFinancials,
                pastInvoices,
            } = this.businessProfile;
            const companyVerified = companyDetails['verified'] || false;
            const pastInvoicesVerified = pastInvoices['verified'] || false;
            const businessFinancialsVerified =
                businessFinancials['verified'] || false;
            this.isVerified = {
                companyVerified,
                pastInvoicesVerified,
                businessFinancialsVerified,
            };
            return false;
        }
    }

    //switch profile to verified
    @action async setProfileVerified(profileID, verified) {
        if (profileID) {
            const result = await Profiles.edit(profileID, {
                profileVerified: verified
            });
            return {
                id: result.data.id,
                profileVerified : result.data.profileVerified
            }
        }
    }
    //change business profile editable
    @action async toggleProfileEditable(profileID, booleanValue = true) {
        commonStore.setLoading(true);
        if (profileID) {
            await Profiles.edit(profileID, {
                isEditable: booleanValue
            });
            viewStore.adminState.businessProfile['isEditable'] = booleanValue;
        }
        commonStore.setLoading(false);
    }

    //Set Status of loan
    @action async setLoansApplicationStatus(applID, statusId) {
        if (applID) {
            commonStore.setLoading(true);
            const statusData = await Statuses.get(statusId);
            await LoanDetails.edit(applID, { status: statusId });
            await commonStore.addAuditTrail(
                statusId,
                viewStore.adminState.businessProfile._id,
                'loan',
                { loandetail: applID, smeProfile: viewStore.adminState.businessProfile._id }
            );
            if (statusData && statusData.data && statusData.data.name === 'Verified') {
                const awaitingId = '5d9d986e875e3cbf48258854';
                await LoanDetails.edit(applID, { status: awaitingId });
                await commonStore.addAuditTrail(
                    awaitingId,
                    viewStore.adminState.businessProfile._id,
                    'loan',
                    { loandetail: applID, smeProfile: viewStore.adminState.businessProfile._id }
                );
            }
            if (viewStore.adminState.loanAppl) {
                viewStore.adminState.notfications = null;
                const ret = await LoanDetails.get(applID);
                if (ret && ret.data) {
                    viewStore.adminState.loanAppl = ret.data;
                    const index = viewStore.adminState.businessProfile.loansProfile.loanApplications.findIndex(row => row._id === applID);
                    viewStore.adminState.businessProfile.loansProfile.loanApplications[index] = ret.data;
                }
            }
            commonStore.setLoading(false);
        }
    }
    @action async setCompanyDetailsVerified(id, verified) {
        if (id) {
            await CompanyDetails.edit(id, { verified });
            await commonStore.isAdmin();
        }
    }

    @action async setPastInvoicesVerified(id, verified) {
        if (id) {
            await PastInvoices.edit(id, { verified });
            await commonStore.isAdmin();
        }
    }

    @action async setBusinessFinancialsVerified(id, verified) {
        if (id) {
            await BusinessFinancials.edit(id, { verified });
            await commonStore.isAdmin();
        }
    }

    @action async setLoansProfileVerified(id, verified) {
        if (id) {
            await LoansProfile.edit(id, { verified });
            await commonStore.isAdmin();
        }
    }
    @action setErr(msg) {
        this.errMsg = msg;
    }

    @action async getLoanDetails(loanProfileId) {
        const details = await LoanDetails.getByLoansProfileId(loanProfileId);
        return details.data || null;
    }
    //set business Financial stuffs
    @action setBusinessFinancials(businessFinancials) {
        if (businessFinancials) {
            if (businessFinancials.annualBalanceSheet && Object.keys(businessFinancials.annualBalanceSheet).length > 0) {
                delete businessFinancials.annualBalanceSheet.businessFinancials;
                this.balanceSheet = businessFinancials.annualBalanceSheet;
            } else{
                this.balanceSheet = {
                    accountsReceivable: '',
                    cashAndCashEquivalents: '',
                    accountsPayable: '',
                    inventory: '',
                    totalCurrentLiabilities: '',
                    // businessFinancials: null,
                    id: null
                };
            }
            if (businessFinancials.annualIncomeStatement && Object.keys(businessFinancials.annualIncomeStatement).length > 0) {
                delete businessFinancials.annualIncomeStatement.businessFinancial;
                this.incomeStatement = businessFinancials.annualIncomeStatement;
            } else{
                this.incomeStatement = {
                    turnOver: '',
                    costOfSales: '',
                    netProfitAfterTax: '',
                    businessFinancial: null,
                    id: null
                };
            }
            if (businessFinancials.accountantDetails && Object.keys(businessFinancials.accountantDetails).length > 0) {
                this.accountantDetails = businessFinancials.accountantDetails;
            } else{
                this.accountantDetails =  {
                    saipaNo: '',
                    idNo: '',
                    email: '',
                    supportDocument: [],
                    businessFinancials: null,
                    _id: null
                };
            }
            if (businessFinancials.cashflowRecords && businessFinancials.cashflowRecords.length > 0  && Object.keys(businessFinancials.accountantDetails).length > 0) {
                this.cashFlowRecords = businessFinancials.cashflowRecords;
            } else{
                this.cashFlowRecords = []
            }
        }
    }
    //Add dummy director to array
    @action addDirector() {
        const newDirector = {
            firstName: '',
            lastName: '',
            idNo: '',
            email: '',
            cellNo: '',
            certifiedID: [],
            companyDetails: null,
            id: this.companyDirectors.length + 1
        };

        this.companyDirectors.push(newDirector);
    }
    //handle remove Directors + pastinvoce

    @action async handleRemove(removeId, removeType) {
        if (removeType === 'director') {
            const deleteData = this.companyDirectors.filter(
                director => director.id === removeId
            );
            this.companyDirectors = this.companyDirectors.filter(
                directors => directors.id !== removeId
            );
            if (deleteData[0].id) {
                await CompanyDirectors.delete(deleteData[0].id);
            }
        }
        if (removeType === 'pastInvoice') {
            const deleteData = this.pastInvoices.filter(
                pastInvoice => pastInvoice.id === removeId
            );
            this.pastInvoices = this.pastInvoices.filter(
                pastInvoice => pastInvoice.id !== removeId
            );
            if(!this.pastInvoices.length){
                this.pastInvoices = [
                    {
                        companyName: '',
                        companyEmail: '',
                        invoiceAmount: '',
                        invoiceCopy: [],
                        id: 1,
                        pastInvoices: null
                    }]
            }

            if (deleteData[0].id) {
                await DebtorInvoice.delete(deleteData[0].id);
            }
        }

        if (removeType === 'Document') {
            await Upload.delete(removeId);
        }
    }
    //set CompanyDetails , businessAdress , CompanyDirectors
    @action async setUpProfileSections(profileID) {
        //create company details
        this.companyData = await this.createCompanyDetails(profileID);
        this.businessFinancialData = await this.createBusinessFinancials(profileID);
        this.pastInvoiceData = await this.createPastInvoices(profileID);
        await this.createbankAccountDetails(profileID);
    }

    @action async createCompanyDetails(profileID) {
        const companyDetails = {};
        const retObj = await CompanyDetails.add(companyDetails);
        const companyObj = retObj.data;
        if (companyObj && companyObj.id) {
            await Profiles.edit(profileID, {
                companyDetails: companyObj.id
            });
        }
    }

    @action async createbankAccountDetails(profileID) {
        const obj = {};
        const retObj = await Bankaccountdetails.add(obj);
        const companyObj = retObj && retObj.data;
        if (companyObj && companyObj.id) {
            await Profiles.edit(profileID, {
                bankaccountdetail: companyObj.id
            });
        }
    }

    @action async createBusinessFinancials(profileID) {
        const businessFinancials = {};
        const retObj = await BusinessFinancials.add(businessFinancials);
        const businessFinancialsObj = retObj.data;
        if (businessFinancialsObj && businessFinancialsObj.id) {
            await Profiles.edit(profileID, {
                businessFinancials: businessFinancialsObj.id
            });
        }
    }

    @action async createPastInvoices(profileID) {
        const pastInvoices = {};
        const retObj = await PastInvoices.add(pastInvoices);
        const pastInvoicesObj = retObj.data;
        if (pastInvoicesObj && pastInvoicesObj.id) {
            const resp = await Profiles.edit(profileID, {
                pastInvoices: pastInvoicesObj.id
            });
            if (resp) {
                const data = {
                    ...this.businessProfile,
                    companyDetails: resp.data.companyDetails,
                    businessFinancials: resp.data.businessFinancials,
                    pastInvoices: resp.data.pastInvoices
                };
                this.businessProfile = data;
            }
        }
    }

    @action async postBusinessAddress(addressExists) {
        this.businessAddress.companyDetails = this.businessProfile.companyDetails.id;
        commonStore.setLoading(true);
        const {
            businessAddress
        } = this.businessProfile.companyDetails;

        if (addressExists && this.isBusinessAddressExist) {
            //update address

            await BusinessAddresses.edit(businessAddress.id, this.businessAddress);
        } else {

            //create new businessAddress
            const retObj = await BusinessAddresses.add(this.businessAddress);
            const businessAddressObj = retObj.data;
            if (businessAddressObj && businessAddressObj.id) {
                if (this.businessProfile.companyDetails._id) {
                    await CompanyDetails.edit(this.businessProfile.companyDetails._id, {
                        businessAddress: businessAddressObj.id
                    });
                } else {
                    await CompanyDetails.add({
                        businessAddress: businessAddressObj.id
                    });
                }
            }
        }
        commonStore.setLoading(false);
    }

    @action async postCompanyDirector(data, directorExists) {
        data.companyDetails = this.businessProfile.companyDetails._id;
        data.verified = false;

        if (directorExists) {
            commonStore.setLoading(true);
            await CompanyDirectors.edit(data.id, data);
            commonStore.setLoading(false);
        } else {
            delete data.id;
            commonStore.setLoading(true);
            await CompanyDirectors.add(data);
            commonStore.setLoading(false);
        }
    }

    //Financial Actions
    @action async postAnnualBalanceSheet(balanceSheetExists) {
        commonStore.setLoading(true);
        if (balanceSheetExists && this.fieldsToUpdate.annualBalanceSheetDirty) {
            //update balance sheet
            await AnnualBalanceSheets.edit(this.balanceSheet._id, this.balanceSheet);
        } else {
            //create new balanceSheet
            const retObj = await AnnualBalanceSheets.add(this.balanceSheet);
            const balanceSheetObj = retObj.data;
            if (balanceSheetObj && balanceSheetObj.id) {
                await BusinessFinancials.edit(
                    this.businessProfile.businessFinancials._id,
                    {
                        annualBalanceSheet: balanceSheetObj.id
                    }
                );
            }
        }
        this.fieldsToUpdate.annualBalanceSheetDirty = false;
        commonStore.setLoading(false);
    }

    @action async postAnnualIncomeStatement(incomeStatementExists) {
        commonStore.setLoading(true);
        if (incomeStatementExists) {
            //update income statement
            await AnnualIncomeStatements.edit(
                this.incomeStatement._id,
                this.incomeStatement
            );
        } else {
            //create new income statement
            const retObj = await AnnualIncomeStatements.add(this.incomeStatement);
            const incomeStatementObj = retObj.data;
            if (incomeStatementObj && incomeStatementObj.id) {
                await BusinessFinancials.edit(
                    this.businessProfile.businessFinancials._id,
                    {
                        annualIncomeStatement: incomeStatementObj.id
                    }
                );
            }
        }
        this.fieldsToUpdate.annualIncomeStatementDirty = false;
        commonStore.setLoading(false);
    }

    @action async postAccountantDetails(accountantDetailsExists) {
        this.accountantDetails.businessFinancials = this.businessProfile.businessFinancials._id;
        commonStore.setLoading(true);
        if (accountantDetailsExists) {
            //update accountant details
            await AccountantDetails.edit(
                this.accountantDetails._id,
                this.accountantDetails
            );
        } else {
            const accountantDetails = { ...this.accountantDetails };
            delete accountantDetails._id;
            //create new accountant details
            const retObj = await AccountantDetails.add(accountantDetails);
            const accountantObj = retObj.data;
            if (accountantObj && accountantObj._id) {
                await BusinessFinancials.edit(
                    this.businessProfile.businessFinancials._id,
                    {
                        accountantDetails: accountantObj._id
                    }
                );
            }
        }
        this.fieldsToUpdate.accountantDetailsDirty = false;
        commonStore.setLoading(false);
    }
    @action async postCashFlowRecords(cashFlowList) {
        commonStore.setLoading(true);
        this.fieldsToUpdate.cashFlowDirty = false;
        for (let i = 0; i < cashFlowList.length; i++) {
            const cashFlow = cashFlowList[i];
            if (cashFlow.amount !== '' && cashFlow.dirty) {
                const cashFlowRefactor = {
                    bankBalance: cashFlow.amount,
                    verified: false,
                    businessFinancials: this.businessProfile.businessFinancials._id,
                    monthYear: cashFlow.month
                };
                if (cashFlow.id) {
                    //already exists, so just needs to be updated
                    await CashFlowRecords.edit(cashFlow.id, cashFlowRefactor);
                } else {
                    //does not exists need to be added
                    await CashFlowRecords.add(cashFlowRefactor);
                }
            }
        }
        commonStore.setLoading(false);
    }

    @action async postBankAccountDetails(accountDetails) {
        delete accountDetails.bankStatements;
        if (this.bankAccountDetails._id) {
            commonStore.setLoading(true);
            await Bankaccountdetails.edit(this.bankAccountDetails._id, accountDetails);
            commonStore.setLoading(false);
            this.fieldsToUpdate.bankAccountDetailsDirty = false;

        } else{
            this.bankAccountDetails ={
                accountNumber: '',
                accountType: '',
                branchCode: '',
                bankName: '',
                id: '',
                confirmationLetter: '',
                _id: ''
            }
        }
    }

    @action async postPastInvoice() {
        commonStore.setLoading(true);
        for (let i = 0; i < this.pastInvoices.length; i++) {
            const pastInvoice = this.pastInvoices[i];
            const invoice = {
                companyName: pastInvoice.companyName,
                companyEmail: pastInvoice.companyEmail,
                invoiceAmount: pastInvoice.invoiceAmount,
                verified: false,
                pastInvoices: this.businessProfile.pastInvoices._id
            };
            if (pastInvoice.dirty) {
                if (pastInvoice._id) {
                    //already exists, so just needs to be updated
                    await DebtorInvoice.edit(pastInvoice.id, invoice);
                } else {
                    //does not exists need to be added
                    await DebtorInvoice.add(invoice);
                }
            }
        }

        commonStore.setLoading(false);
    }

    @action addPastInvoice() {
        const newPastInvoice = Object.keys(this.pastInvoices[0]).reduce(
            (acc, cur) => {
                acc[cur] = '';
                if (cur === 'id') {
                    acc[cur] = this.pastInvoices.length + 1;
                }
                if (cur === 'pastInvoices') {
                    acc[cur] = null;
                }
                return acc;
            },
            {}
        );
        this.pastInvoices.push(newPastInvoice);
    }

    //Upload Files
    @action async uploadFile2(fileData, files) {
        try {
            commonStore.setLoading(true);
    
            const dog = files.map(async uploadedFile => {
                const formData = new FormData();
                const fileInfo = {
                    files: uploadedFile.file,
                    refId: fileData.refId,
                    ref: fileData.ref,
                    source: fileData.source,
                    field: fileData.field
                };
    
                for (const key in fileInfo) {
                    if (key !== '') {
                        formData.append(key, fileInfo[key]);
                    }
                }
                await Upload.add(formData);
            });
            const res = await Promise.all(dog);
            if (res) {
                await this.getUpdatedData();
                await commonStore.setMsg(`${files.length} files uploaded successfully.`)
            }
        } catch (error) {
            commonStore.setLoading(false);
            console.log("error", error);
        }
    
    }

    //Profile progress
    @action async progressBarStatus() { //NOSONAR
        const businessAddressStatus = this.categoryProgressStatus(
            this.businessProfile.companyDetails && this.businessProfile.companyDetails.businessAddress,
            TEN
        );
        const companyDirectorStatus = this.categoryProgressStatus(
            this.companyDirectors,
            FIFTEEN
        ); // 10%
        const annualBalanceSheetStatus = this.categoryProgressStatus(
            this.balanceSheet,
            TEN
        ); // TEN%
        const annualIncomeStatus = this.categoryProgressStatus(
            this.incomeStatement,
            TEN
        ); // TEN%
        const accountantDetailsStatus = this.categoryProgressStatus(
            this.accountantDetails,
            FIFTEEN
        ); // 10%
        const cashFlowRecordsStatus = this.categoryProgressStatus(
            this.cashFlowRecords,
            THIRTY - TEN
        ); // 30-10%
        const bankAccountDetail = this.categoryProgressStatus(
            this.bankAccountDetails,
            TEN
        ); // 10
        let totalProgress =
            businessAddressStatus +
            companyDirectorStatus +
            annualBalanceSheetStatus +
            annualIncomeStatus +
            accountantDetailsStatus +
            cashFlowRecordsStatus +
            bankAccountDetail;
        const bankStatements =
            this.businessProfile.businessFinancials && this.businessProfile.businessFinancials.bankStatements;
        const companyRegDocs =
            this.businessProfile.companyDetails && this.businessProfile.companyDetails.companyRegDocs;

        if (companyRegDocs && companyRegDocs.length > 0) {
            totalProgress += FIVE;
        }
        if (bankStatements && bankStatements.length > 0) {
            totalProgress += FIVE;
        }
        if (this.businessProfile.percCompleted !== Math.round(totalProgress)) {
            const profileID = storageUtil.getItem('usr').profile;
            if (profileID) {
                const HUNDRED = 100;
                let complete = false;
                this.businessProfile.complete = false;
                if (Math.round(totalProgress) === HUNDRED) {
                    complete = true;
                    this.businessProfile.complete = true;
                    await commonStore.addAuditTrail(
                        '5d9d96ca875e3cbf4825884f',
                        profileID,
                        'profile',
                        { smeProfile: profileID }
                    );
                    if(commonStore.tourStep < 12){
                    commonStore.isTourOpen = true;
                    }
                }
                await Profiles.edit(profileID, {
                    percCompleted: Math.round(totalProgress),
                    complete
                });
            }
        }
        this.profilePercenatge = Math.round(totalProgress);
    }

    categoryProgressStatus = (categoryData, progressContribution) => {
        let currentProgress = 0;
        if (categoryData) {
            if (Array.isArray(categoryData)) {
                // CompanyDirectors
                if (categoryData.length > 1) {
                    progressContribution =
                        progressContribution / categoryData.length;
                }
                categoryData.forEach(obj => {
                    if (!obj.verified) {
                        delete obj.verified;
                    }

                    delete obj.__v;
                    delete obj.deleted;
                    const totalKeys = Object.keys(obj).length;
                    const perItemContribution = progressContribution / totalKeys;
                    currentProgress += this.progressBarHelper(obj, perItemContribution);
                });
            } else {
                delete categoryData.__v;
                delete categoryData.deleted;
                delete categoryData.complete;
                const totalKeys = Object.keys(categoryData).length;
                const perItemContribution = progressContribution / totalKeys;
                currentProgress = this.progressBarHelper(
                    categoryData,
                    perItemContribution
                );
            }
        }

        return currentProgress;
    };

    progressBarHelper = (categoryData, perItemContribution) => {
        let currentProgress = 0;
        Object.keys(categoryData).forEach(key => {
            const itemValue = categoryData[key];
            if (itemValue) {
                if (itemValue !== '' && !Array.isArray(itemValue)) {
                    currentProgress += perItemContribution;
                }

                if (Array.isArray(itemValue) && itemValue.length > 0) {
                    currentProgress += perItemContribution;
                }
            }
        });
        return currentProgress;
    };
}

const businessProfileStore = new BusinessProfile1();
const hydrate = create({
    storage: localStorage
});
export default businessProfileStore;
const result = hydrate('some', businessProfileStore);
const rehydrate = result.rehydrate;

