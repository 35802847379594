import React,{useState} from 'react';
import {Link} from 'react-router-dom';
import {
    Form, Input, Label, FormGroup,
    Card, CardBody, Button, Row
} from '@Comp';
import { View } from '../../../shared/components';

import images from '@assets';
import '../style.scss';
import {inject} from "mobx-react";

const ForgetPassPage = ({commonStore}) =>{

   const [businessEmailAddress, setBusinessEmailAddress] = useState('');
   const [err, setErr] = useState(null);
   const handleSubmit = (e) =>{
       e.preventDefault();
       commonStore.setLoading(true);
       commonStore.forgetPassword(businessEmailAddress).then((ret) => {
            commonStore.setLoading(false);
            setErr("Email With Reset Password Details Successfully Sent");
        }).catch((e) => {
            commonStore.setLoading(false);
            setErr("System Error -  Contact Admin.");
        });

   };

    return(
        <View>
            <div className="view-content view-content-forgot view-pages view-session d-flex justify-content-center align-items-center flex-column">
            <Row className="no-gutters">
                <Card className="mb-3 form-card">
                    <CardBody>
                        <header className="mb-4 text-center">
                            <img className="img-fluid" src={images.loginIcon} alt="" />
                            <p className="mt-4 small">Enter your email address and we'll send you the instructions for resetting the password.</p>
                        </header>
                        <Form onSubmit={handleSubmit}>
                            <FormGroup className="mb-4">
                                <Label>Business Email Address</Label>
                                <Input
                                    type="email"
                                    name="email"
                                    value={businessEmailAddress}
                                    placeholder="yourmail@xyz.com"
                                    onChange={(e)=>setBusinessEmailAddress(e.target.value)}
                                />
                            </FormGroup>
                            <p className="notification">{err}</p> 
                            <FormGroup className="text-right">
                                <Button color="info"  type="submit">Reset Password</Button>
                            </FormGroup>
                        </Form>
                        <p>Already have an account? <Link to="/login">Login</Link></p>
                    </CardBody>
                </Card>
            </Row>
            </div>
        </View>
        )

};

const ForgetPassword = inject("commonStore")(ForgetPassPage);
export default ForgetPassword;
