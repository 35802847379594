import React, { useState, useEffect } from "react";
import { Redirect, Link as RouterLink } from "react-router-dom";
// import { } from 'react-router';
import {
  Form,
  Input,
  Label,
  FormGroup,
  Button,
  FormText,
  Card,
  CardBody,
  Row
} from "@Comp";

import images from "@assets";

import { FaTwitter, FaFacebook, FaGoogle } from "react-icons/fa";

import "../style.scss";
import "../app.scss";
import { inject } from "mobx-react";
import validate from "validate.js";

import {
  TextField,
  Checkbox,
  Typography,
  Link,
  FormHelperText
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { history } from "../../../App";

const schema = {
  businessName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 32
    }
  },
  cipcRegNo: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 32,
      minimum: 10
    },
    cipcValidate: true
  },

  businessEmailAddress: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 8
    }
  },
  confirmPassword: {
    equality: "password",
    presence: { allowEmpty: false, message: "is required" }
  },
  policy: {
    presence: { allowEmpty: false, message: "is required" },
    checked: true
  }
};

const Registration = ({ commonStore }) => {
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  const [err, setErr] = useState(null);

  const [inputs, setInputs] = useState({
    businessName: "",
    businessEmailAddress: "",
    cipcRegNo: "",
    password: "",
    confirmPassword: ""
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const useStyles = makeStyles(() => ({
    textField: {
      border: 2
    },
    policy: {
      marginTop: 2,
      display: "flex",
      alignItems: "center"
    }
  }));
  const classes = useStyles();

  const [agreedToTerms, updateAgreedToTerms] = useState(false);
  const socialMediaLogin = false;

  const handleChange = e => {
    const target = e.target; //current input field being modified
    const value = target.value;
    const name = target.name;
    setInputs(inputs => ({ ...inputs, [name]: value }));

    e.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value
      },
      touched: {
        ...formState.touched,
        [e.target.name]: true
      }
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    commonStore
      .register(inputs.businessEmailAddress, inputs.password)
      .then(() => {
        commonStore
          .addBusinessProfile(
            inputs.businessName,
            inputs.businessEmailAddress,
            inputs.cipcRegNo
          )
          .then(response => {
            commonStore.setLoading(false);
            history.push({
              pathname: "/login",
              state: { detail: "AccountCreated" }
            });
          })
          .catch(function(e) {
            if (e.response) {
              commonStore
                .blockAccount()
                .then((ret) => {
                  console.log('[blockAccount]', ret);
                  setErr(
                    "Fraudulent activity suspected as CIPC already exists please contact system administrator at info@nisafinance.com"
                  );
                  //commonStore.setLoading(false);
                })
                .catch((e) => {
                  console.error('[blockAccount]',e);
                  setErr(
                    "Fraudulent activity suspected as CIPC already exists please contact system administrator at info@nisafinance.com"
                  );
                  //commonStore.setLoading(false);
                });
            }
          });
      })
      .catch(function(e) {
        if (e.response) {
          commonStore.setLoading(false);
          setErr(e.response.data.message);
        }
      });
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <div className="view">
      <div className="view-content view-content-reg view-pages view-session d-flex justify-content-center align-items-center flex-column">
        <Row className="no-gutters">
          <div className="col-md-8 mb-7 d-none d-md-block">
            <img className="img-fluid" src={images.loginIcon} alt="" />
            <div className="m-t-15 m-l-20">
              <h1 className="font-weight-light font-size-35 text-white">
                Take Your Business To The Next Level
              </h1>
              <p className="text-white width-70 text-opacity m-t-25 font-size-16">
                By giving you access to the most affordable invoice financing,
                we will help you manage your cash-flow and grow your business to
                the next level.
              </p>
              <div className="m-t-60">
                <a href="" className="text-white text-link m-r-15">
                  Term &amp; Conditions
                </a>
                <a href="" className="text-white text-link">
                  Privacy &amp; Policy
                </a>
              </div>
            </div>
          </div>
          <Card className="col-md-4 mb-3 form-card no-gutters regCard align-self-center">
            <CardBody>
              <header className="mb-5">
                {/* Logo */}
                <p className="lead">Create Your account</p>
              </header>
              <Form onSubmit={handleSubmit}>
                <FormGroup className="mb-4">
                  {
                    //<Label>Business Name</Label>
                  }
                  <TextField
                    type="text"
                    name="businessName"
                    placeholder="Business Name"
                    value={inputs.businessName}
                    fullWidth={true}
                    onChange={handleChange}
                    error={hasError("businessName")}
                    helperText={
                      hasError("businessName")
                        ? formState.errors.businessName[0]
                        : null
                    }
                    className={classes.textField}
                  />
                </FormGroup>
                <FormGroup className="mb-4">
                  {
                    //<Label>Business Email Address</Label>
                  }
                  <TextField
                    type="email"
                    name="businessEmailAddress"
                    placeholder="Email Address"
                    value={inputs.businessEmailAddress}
                    fullWidth={true}
                    onChange={handleChange}
                    error={hasError("businessEmailAddress")}
                    helperText={
                      hasError("businessEmailAddress")
                        ? formState.errors.businessEmailAddress[0]
                        : null
                    }
                    className={classes.textField}
                  />
                </FormGroup>
                <FormGroup className="mb-4">
                  {
                    //<Label>CIPC Registration Number</Label>
                  }
                  <TextField
                    type="text"
                    name="cipcRegNo"
                    placeholder="CIPC Reg number - k2017/0092/93"
                    value={inputs.cipcRegNo}
                    fullWidth={true}
                    onChange={handleChange}
                    error={hasError("cipcRegNo")}
                    helperText={
                      hasError("cipcRegNo")
                        ? formState.errors.cipcRegNo[0]
                        : null
                    }
                    className={classes.textField}
                  />
                </FormGroup>
                <FormGroup className="mb-4">
                  <TextField
                    type="password"
                    name="password"
                    placeholder="password minimum 8 characters"
                    value={inputs.password}
                    fullWidth={true}
                    onChange={handleChange}
                    error={hasError("password")}
                    helperText={
                      hasError("password") ? formState.errors.password[0] : null
                    }
                    className={classes.textField}
                  />
                  <FormText>Minimum length 8 characters</FormText>
                </FormGroup>
                <FormGroup className="mb-5">
                  <TextField
                    type="password"
                    name="confirmPassword"
                    placeholder="confirm password above"
                    value={inputs.confirmPassword}
                    fullWidth={true}
                    onChange={handleChange}
                    error={hasError("confirmPassword")}
                    helperText={
                      hasError("confirmPassword")
                        ? formState.errors.confirmPassword[0]
                        : null
                    }
                    className={classes.textField}
                  />
                </FormGroup>
                <FormGroup>
                  <div className={classes.policy}>
                    <Checkbox
                      checked={formState.values.policy || false}
                      className={classes.policyCheckbox}
                      color="primary"
                      name="policy"
                      onChange={handleChange}
                    />
                    <Typography
                      className={classes.policyText}
                      color="textSecondary"
                      variant="body1"
                    >
                      I have read the{" "}
                      <Link
                        color="primary"
                        component={RouterLink}
                        to="#"
                        underline="always"
                        variant="body1"
                      >
                        Terms and Conditions
                      </Link>
                    </Typography>
                  </div>
                  {hasError("policy") && (
                    <FormHelperText error>
                      {formState.errors.policy[0]}
                    </FormHelperText>
                  )}
                </FormGroup>
                <FormGroup className="text-right">
                  <Button
                    color="info"
                    block
                    size="lg"
                    className={formState.isValid ? null : "disabled"}
                    type="submit"
                    disabled={!formState.isValid}
                  >
                    Create New Account
                  </Button>
                </FormGroup>
                <FormGroup>
                  <Typography
                    className={classes.policyText}
                    color="textSecondary"
                    variant="body2"
                  >
                    Already have an account?{" "}
                    <Link
                      color="primary"
                      component={RouterLink}
                      to="/login"
                      underline="always"
                      variant="body2"
                    >
                      Log In
                    </Link>
                  </Typography>
                </FormGroup>
                <FormGroup className="mb-4">
                  {err && err.length > 0 ? (
                    <Label className="err">{err}</Label>
                  ) : null}
                </FormGroup>
              </Form>
              {socialMediaLogin ? (
                <div>
                  <p className="font-weight-bold">or sign up with...</p>
                  <ul className="list-unstyled social-auth">
                    <li>
                      <a href="#na">
                        <FaTwitter size={22} />
                      </a>
                    </li>
                    <li>
                      <a href="#na">
                        <FaFacebook size={22} />
                      </a>
                    </li>
                    <li>
                      <a href="#na">
                        <FaGoogle size={22} />
                      </a>
                    </li>
                  </ul>
                </div>
              ) : null}
            </CardBody>
          </Card>
        </Row>
      </div>
    </div>
  );
};

const Register = inject("commonStore")(Registration);
export default Register;
