
import { observable, action } from 'mobx';
import {
    Email
} from '../services/requests';
import storageUtil from './storageUtil';
import businessProfileStore from './businessProfileStore';

class EmailStore {
    @observable businessProfile = storageUtil.getItem('businessProfile') || {
        id: null,
        businessName: null,
        businessEmail: null,
        profileVerified: false,
        isIdle: true,
        loansProfile: null,
        latestloanstatus: null,
        user: null,
        cipcRegNo: null,
        companyDetails: null,
        businessFinancials: null,
        pastInvoices: null,
        profileSetup: false
    };

    @observable email = {
        from: '',
        to: '',
        reply_to: '',
        subject: '',
        html: ''
    };
    //action to be triggered for email after register was done we pass the message to the admin
    @action postEmail() {
        const email = {
            from: 'non-reply@nisafinance.com',
            to: 'info@nisafinance.com',
            reply_to: this.businessProfile.businessName,
            subject: 'Message from a client',
            html: `HI admin there is a new member that registered on the platform and the user is just logged in. The business name is
            ${businessProfileStore.businessProfile.businessName} with this email ${businessProfileStore.businessProfile.businessEmail}
             and the  company registration number is ${businessProfileStore.businessProfile.cipcRegNo}`
        };
        return Email.send(email);
    }
}

const emailStore = new EmailStore();
export default emailStore;