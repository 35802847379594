import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Nav, Header, Loading } from '../shared/components';
import { Snackbar } from '@material-ui/core';
import { isMobile } from 'is-mobile';
import Tour from 'reactour';
import TourComponents from '../components/TourComponents';
import '../app.scss';

@inject('commonStore')
@withRouter
@observer
export default class DashboardContainer extends React.Component {
	constructor() {
		super();
		this.state = { current: 0, isMobileScreen: window.innerWidth < 480 };
  
	}
	
  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize(); 
  }

  handleResize = () => {
    const newIsMobileScreen = window.innerWidth < 480;
    if (newIsMobileScreen !== this.state.isMobileScreen) {
      this.setState({
        isMobileScreen: newIsMobileScreen,
      });  

    }
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

	toggleNav = e => {
		e.preventDefault();

		let { commonStore } = this.props;
		commonStore.setNavMini(!commonStore.navMini);
	};
	hideNav = e => {
		e.stopPropagation();
		e.preventDefault();

		let { commonStore } = this.props;
		commonStore.setNavMini(false);
	};
	close = () => {
		this.props.commonStore.open = false;
	};
	closeTour = async () => {
		try {
			const data = await this.props.commonStore.handleTour(false, 12);
			if (data) {
				this.props.commonStore.isTourOpen = false;
				this.props.commonStore.tourStep = 12;
			}
		} catch (e) {
			console.log(e);
		}
	};
  updateSteps = (step) => {
    if (this.props.location.pathname === '/home/business-profile' && step <= 3) {
        this.props.commonStore.setTourStep(step);
        this.props.history.push('/home');
    }
    if (step === 4) {
        this.props.commonStore.setTourStep(step);
        this.props.history.push('/home/business-profile');
    }
  };
  
	render() {
    const {isMobileScreen} = this.state
		const { commonStore } = this.props;
		const { navMini, open, msg, tourStep, isTourOpen } = commonStore;

    const steps = [
      {
        content: <TourComponents isMobileScreen={isMobileScreen} step='one' />,
        style: isMobileScreen ? { maxWidth: '350px' } : { maxWidth : "500px"}, 
      },
      {
        selector: '.second-step',
        content: (
          <h2>
            This is your dashboard, here you will find notifications and your
            high-level statistics on the platform
          </h2>
        ),
        position: isMobileScreen ? 'center' : [100,350],      
      },
      {
        selector: '.third-step',
        content: (
          <h2 className={isMobileScreen ? 'small' : ''}>
            This is your navigation bar. <br />
            You are currently on the <strong>dashboard tab</strong>.<br />
            <strong>Loan applications </strong>is where you find the current status
            of all your loan applications.
            <br />
            <strong>Apply for loan</strong> , is where you can apply for multiple
            loans.
            <br />
            <strong>Business profile</strong> is where you manage all the data
            pertaining to your business.
          </h2>
        ),
        action: () => this.updateSteps(3),
        style: { maxWidth: isMobileScreen? '350px' : '500px' },
        position: isMobileScreen ? 'center' : [295,10], 
      },
      {
        selector: '.fourth-step',
        content: (
          <h2 className={isMobileScreen ? 'small' : ''}>
            Before applying for a loan, we need to get your business profile
            completed. Please click on BUSINESS PROFILE
          </h2>
        ),
        action: () => this.updateSteps(4),
        style: { maxWidth : isMobileScreen ? "280px" : '' },
        position: isMobileScreen ? 'bottom' : [290,300]
      },
      {
        selector: '.fifth-step',
        content: (
          <h2>This progress bar indicates how far you are in the process.</h2>
        ),
      },
      {
        selector: '.sixth-step',
        action: () => this.updateSteps(6),
        content: data => <TourComponents step='six' data={data} />,
        position: isMobileScreen ? [50,390] : [225, 300],
        style: { maxWidth: '283px' },
        stepInteraction: false,
    
      },
      {
        selector: '.seventh-step',
        content: data => <TourComponents  isMobileScreen={isMobileScreen}  step='seven' data={data} />,
        
      },
      {
        selector: '.eightth-step',
        content: data => <TourComponents isMobileScreen={isMobileScreen} step='eight' data={data} />,
      },
      {
        selector: '.ninth-step',
        content: (
          <h2>
            Click on the <strong>Data Verification Links</strong> to continue.
          </h2>
        ),
      },
      {
        selector: '.tenth-step',
        content: data => <TourComponents isMobileScreen={isMobileScreen} step='ten' data={data} />,
        style: { maxWidth: isMobileScreen? '350px' : '600px' },
        position: isMobileScreen ? '' : [562, 90],

      },
      {
        selector: '.eleventh-step',
        content: data => <TourComponents isMobileScreen={isMobileScreen} step='eleven' data={data} />,
      },
    ];
    
		return (
			<div className='app-wrapper dashboardContainer first-step'>
				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					open={open}
					autoHideDuration={5000}
					message={msg}
					onClose={this.close}
				/>
				<Loading />
				<Nav mini={navMini} toggleNav={this.toggleNav} />
				<div
					className={`content-container ${navMini ? 'full' : ''} ${
						isMobile() ? 'mobile' : ''
					}`}
				>
					{/* dropshadow for mobile nav triggering */}
					<div
						className='menu-dropshadow'
						style={{ display: navMini ? 'block' : 'none' }}
						onClick={this.hideNav}
					></div>
					<Header toggleNav={this.toggleNav} />

					{this.props.children}
				</div>
				<Tour
					steps={steps}
					isOpen={isTourOpen}
					showCloseButton={true}
					startAt={tourStep - 1}
					goToStep={tourStep - 1}
					onRequestClose={this.closeTour}
					closeWithMask={true}
					disableKeyboardNavigation={['esc']}
					disableInteraction={false}
				/>
			</div>
		);
	}
}
