import React from 'react';
import {Link} from 'react-router-dom';

import '../style.scss';

 const Page404 =
    () => (

    <div className="view-content view-pages view-error d-flex justify-content-center align-items-center flex-column">

        <h1 className="display-404">4<span>0</span>4</h1>
        <h4>Page Not Found!!</h4>
        <p className="text-muted">The page you're looking doesn't exist. Go to <Link to="/home">homepage.</Link></p>
    </div>

    )
export default Page404