import React from 'react';

export default (props) => (
    <div className="view-header">
        <header className="title text-white">
            <h1 className="h4 text-uppercase">{props.title}</h1>
            {(props.description)?
            <p className="mb-0">{props.description}</p>:null}
        </header>
    </div>
);