import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { NavLink as Link } from 'react-router-dom';
import { Collapse } from '@Comp';
import { isMobile } from 'is-mobile';


// icons
import { FaUikit, FaChartPie, FaTable, FaArrowDown, FaCalendar, FaSignOutAlt, FaMoneyBill, FaRegMoneyBillAlt } from 'react-icons/fa';

import {
    MdDashboard, MdForum, MdWidgets, MdPages, MdMail, MdNotifications, MdPieChartOutlined,
    MdChat, MdMoneyOff, MdSettings
} from 'react-icons/md';

import ScrollArea from '../scrollbar';

import images from '@assets';

import './style.scss';

@inject('commonStore', 'businessProfileStore')
@observer
class NavHead extends Component {

    render() {
        const { user, businessProfile } = this.props.commonStore;

        return (
            <header className="nav-head">
                {/* Logo */}
                <img className='avatar' src={images.NisaFinLogoTransparent} alt="avatar" />
                <div className='userDetails'>
                    {businessProfile ?
                        <div>
                            <div className='username'>{businessProfile.businessName}</div>
                            <div className='userhandle'>{businessProfile.cipcRegNo}</div>
                            <div className='useremail'>{businessProfile.businessEmail}</div>
                        </div>
                        : null}
                </div>

                {/* <div className={`toggle-mnu ${props.mini ? 'active': ''}`} onClick={props.toggleNav}>
                    <img src={images.back_white} alt='mnu'/>
                </div> */}
            </header>
        );
    }
}


@inject('commonStore')
@observer
class NavList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: 0
        }

        this.handleClick = this.handleClick.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleNavigateTo = this.handleNavigateTo.bind(this);

        this.doLoading = this.doLoading.bind(this);
    }
    componentDidMount() {
        let { commonStore } = this.props;
        if (commonStore.adminData.isAdmin && !commonStore.adminData.hasAdminData) {
            // Refresh Admin Data
            commonStore.getAdminData();
        }
    }
    handleClick = (index, e, collapse) => {
        let { commonStore } = this.props;
        let c = e.currentTarget.className;
        e.preventDefault();
        if (e.stopPropagation) {
            e.stopPropagation();
        }
        this.setState({
            selected: (c.indexOf('selected') >= 0) ? '' : index
        });
        const stepExist = e.currentTarget.classList.contains('fourth-step');
        if(stepExist && commonStore.tourStep < 4){
            commonStore.tourStep = 5;
        }
        if(!collapse){
            commonStore.setNavMini(false);
        }
    }
    handleOpen = (index, e) => {
        let { commonStore } = this.props;
        if (e.stopPropagation) {
            e.stopPropagation();
        }
        this.setState({
            selected: index
        });

        commonStore.setNavMini(false);
    }
    handleNavigateTo = (path) => {
        this.props.history.push(path);
    }

    doLoading = () => {
        this.props.commonStore.setLoading(true);
        let _this = this;
        // Clear
        setTimeout(() => {
            _this.props.commonStore.setLoading(false);
        }, 10 * 1000);
    }


    render() {

        let { commonStore } = this.props;
        const dev = false; // SET TO FALSE to disable dev-env!
        const isAdmin = commonStore.adminData.isAdmin;
        const hasAdminData = commonStore.adminData.hasAdminData;

        return (
            <ScrollArea className="nav-list-container" horizontal={false} verticalScrollbarStyle={{ width: '0px', marginLeft: '10px' }}>
                <ul className="list-unstyled nav-list clearfix">
                    {/* <li><div className="nav-list-title">Views</div></li> */}
                    <div className='topSpace'></div>
                    <li onClick={(e) => this.handleClick(0, e)} className={(this.state.selected === 0) ? 'selected' : ''}>
                        <Link to="/home" activeclassname="active">
                            <MdDashboard size="18" className="icon-dashboard" />
                            <span className="mnuName">Dashboard</span>
                        </Link>
                    </li>
                    {/* {(isAdmin && hasAdminData)?
                <li onClick={(e) => this.handleClick(0, e)} className={(this.state.selected === 0) ? 'selected': ''}>
                    <Link to="/home/admin" activeClassName="active">
                        <FaRegMoneyBillAlt size="18" className="icon-user"/>
                        <span className="mnuName">Admin Dashboard</span>
                    </Link>
                </li>:null} */}
                    <li onClick={(e) => this.handleClick(0, e)} className={(this.state.selected === 0) ? 'selected' : ''}>
                        <Link to="/home/loan/view" activeClassName="active">
                            <FaMoneyBill size="18" className="icon-dashboard" />
                            <span className="mnuName">Loan Applications</span>
                        </Link>
                    </li>
                    <li onClick={(e) => this.handleClick(0, e)} className={(this.state.selected === 0) ? 'selected' : ''}>
                        <Link to="/home/loan/apply" activeClassName="active">
                            <FaRegMoneyBillAlt size="18" className="icon-dashboard" />
                            <span className="mnuName">Apply for Loan</span>
                        </Link>
                    </li>
                    {/*<li onClick={(e) => this.handleClick(0, e)} className={(this.state.selected === 0) ? 'selected': ''}>
                    <Link to="/home/loan/offers" activeClassName="active">
                        <FaRegMoneyBillAlt size="18" className="icon-dashboard"/>
                        <span className="mnuName">Loan Offers</span>
                    </Link>
            </li>*/}

                <li onClick={(e) => this.handleClick(0, e)} className={(this.state.selected === 0) ? 'selected fourth-step': 'fourth-step'}>
                    <Link to="/home/business-profile"  activeClassName="active">
                        <FaRegMoneyBillAlt size="18" className="icon-user"/>
                        <span className="mnuName">Business Profile </span>
                    </Link>
                </li>
                
                {/*<!--<li onClick={(e) => this.handleClick(0, e)} className={(this.state.selected === 0) ? 'selected': ''}>
                    <Link to="/logout" activeClassName="active">
                        <FaSignOutAlt size="18" className="icon-dashboard"/>
                        <span className="mnuName">Sign Out</span>
                    </Link>
                </li>*/}
                    {(isAdmin && hasAdminData) ?
                        <li onClick={(e) => this.handleClick(1, e, true)} className={(this.state.selected === 1) ? 'selected' : ''}>
                            <a href='#'>
                                <FaUikit size="18" />
                                <span className="name">Admin</span>
                                <FaArrowDown size="14" className="icon-down" />
                            </a>
                            <Collapse isOpen={this.state.selected === 1 ? true : false} onClick={(e) => this.handleOpen(1, e)}>
                                <ul className="inner-drop list-unstyled">
                                    <li>
                                        <Link to="/home/admin" activeClassName="active">
                                            <FaRegMoneyBillAlt size="18" className="icon-user" />
                                            <span className="mnuName">Dashboard</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/home/admin-apibalance" activeClassName="active">
                                            <FaRegMoneyBillAlt size="18" className="icon-user" />
                                            <span className="mnuName">Api Balance</span>
                                        </Link>
                                    </li>
                                </ul>
                            </Collapse>
                        </li> : null}
                    {(dev) ?
                        <div>
                            <br />
                            <li onClick={(e) => this.handleClick(1, e, true)} className={(this.state.selected === 1) ? 'selected' : ''}>
                                <a href='#'>
                                    <FaUikit size="18" />
                                    <span className="name">Pages</span>
                                    <FaArrowDown size="14" className="icon-down" />
                                </a>
                                <Collapse isOpen={this.state.selected === 1 ? true : false} onClick={(e) => this.handleOpen(1, e)}>
                                    <ul className="inner-drop list-unstyled">
                                        <li><Link to="/home/loan/offers" activeClassName="active">Loan Offers</Link></li>
                                        <li><Link to="/home/clean" activeClassName="active">Clean</Link></li>
                                        <li><Link to="/signin" activeClassName="active">Login</Link></li>
                                        <li><Link to="/register" activeClassName="active">Register</Link></li>
                                        <li><Link to="/forget" activeClassName="active">Forget Pass</Link></li>
                                        <li><Link to="/404" activeClassName="active">404</Link></li>
                                        <li><Link to="/walkthrough" activeClassName="active">Walkthrough</Link></li>
                                        <li><a href='#' onClick={this.doLoading}>Test Loading</a></li>
                                    </ul>
                                </Collapse>
                            </li>
                            <br />
                            <br />
                            <li><div className="nav-list-title">DEV Components</div></li>
                            <li onClick={(e) => this.handleClick(2, e)} className={(this.state.selected === 2) ? 'selected' : ''}>
                                <Link to="/home/widgets" activeClassName="active">
                                    <MdWidgets size="18" />
                                    <span className="name">Widgets</span>
                        &emsp;<span className="badge text-uppercase" style={{ 'background': '#7C4DFF' }}>10</span>
                                </Link>
                            </li>
                            <li onClick={(e) => this.handleClick(3, e, true)} className={(this.state.selected === 3) ? 'selected' : ''}>
                                <a href='#'>
                                    <FaUikit size="18" />
                                    <span className="name">Elements</span>
                                    <FaArrowDown size="14" className="icon-down" />
                                </a>
                                <Collapse isOpen={this.state.selected === 3 ? true : false} onClick={(e) => this.handleOpen(3, e)}>
                                    <ul className="inner-drop list-unstyled">
                                        <li><Link to="/home/ui/buttons" activeClassName="active">Buttons</Link></li>
                                        <li><Link to="/home/ui/typography" activeClassName="active">Typography</Link></li>
                                        <li><Link to="/home/ui/cards" activeClassName="active">Cards</Link></li>
                                        <li><Link to="/home/ui/modals" activeClassName="active">Modals</Link></li>
                                        <li><Link to="/home/ui/notification" activeClassName="active">Notification</Link></li>
                                        <li><Link to="/home/ui/extras" activeClassName="active">Extras</Link></li>
                                    </ul>
                                </Collapse>
                            </li>
                            <li onClick={(e) => this.handleClick(4, e, true)} className={(this.state.selected === 4) ? 'selected' : ''}>
                                <a href='#'>
                                    <MdForum size="18" />
                                    <span className="name">Forms</span>
                                    <FaArrowDown size="14" className="icon-down" />
                                </a>
                                <Collapse isOpen={(this.state.selected === 4) ? true : false} onClick={(e) => this.handleOpen(4, e)}>
                                    <ul className="inner-drop list-unstyled">
                                        <li><Link to="/home/forms/general" activeClassName="active">General</Link></li>
                                        <li><Link to="/home/forms/advanced" activeClassName="active">Advanced</Link></li>
                                    </ul>
                                </Collapse>
                            </li>
                            <li onClick={(e) => this.handleClick(5, e)} className={(this.state.selected === 5) ? 'selected' : ''}>
                                <Link to="/home/charts" activeClassName="active">
                                    <FaChartPie size="18" />
                                    <span className="name">Charts</span>
                        &emsp;<span className="badge badge-primary badge-chart text-uppercase">mixed</span>
                                </Link>
                            </li>
                            <li onClick={(e) => this.handleClick(6, e)} className={(this.state.selected === 6) ? 'selected' : ''}>
                                <Link to="/home/tables" activeClassName="active">
                                    <FaTable size="18" /><span className="name">Tables</span>
                                </Link>
                            </li>
                        </div> : null}
                </ul>
                {/* end scroll-area */}
            </ScrollArea>
        )
    }
}




export default (props) => (
    <nav className={`site-nav third-step ${props.mini ? 'mini' : ''} ${isMobile()?'mobile':''}`}>
        <NavHead/>
        <NavList/>

    </nav>
);
