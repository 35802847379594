import React from 'react';
import ViewHeader from './view-header';
import ViewContent from './view-content';

import { View } from '../../shared/components'; 

// styling
import './style.scss';


export default () => (
    <View>
        <ViewHeader/>
        <ViewContent/>
    </View>
);
