import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
//import { Button } from '@Comp';
import { Row, Col } from 'react-bootstrap';
import images from '../../../assets';
import './verifyid.scss';
import { Button, Icon } from '@material-ui/core';

@inject('verifyIdStore')
@inject('commonStore')
@inject('businessProfileStore')
@observer
class VerifyId extends Component {
    state = {
        ErrorMessage: '',
    };

    componentDidMount() {
        const { businessProfileStore } = this.props;
        const data = businessProfileStore.verifyid
        if (data !== '') {
            const id  = businessProfileStore?.businessProfile?.id
            businessProfileStore.getUpdatedData(id).then(() => {
                this.setState({ isLoaded: true });
            });
        } else {
            businessProfileStore.getUpdatedData();
            if (this.props.commonStore.isProfileViewError) {
                return false;
            }
            this.setState({ isLoaded: true });
        }
        return true;
    }
    //verify post
    handleSaveVerification = async () => {
        const check = await this.props.commonStore.businessProfile;
        //check credits
        const { commonStore } = this.props;
        const { verifyidCredits } = commonStore.adminData;
        const Credits = verifyidCredits     
        if ((check && check.verifyid !== '') && Credits >= 28) {
            await this.setState(() => ({ ErrorMessage: 'Verification is successfully verified' }));
        } 
        // else {
        //     await this.setState(() => ({ ErrorMessage: 'Verification Failed due to system 103/500 Please contact support@nisafinance.com' }));
        // }
        const verification = this.props.verifyIdStore;
        if (verification) {
            //already exists, so just needs to be updated
            this.saveVerifications(verification, true);
        } else {
            //does not exists need to be added
            this.saveVerifications(verification, false);
        }
    };

    //verify delete
    handleDeleteVerifyid = () => {
        const deleteVerify = this.props.verifyIdStore;
        if (deleteVerify) {
            //already exists, so just needs to be deleted
            this.deleteVerifyIdData(deleteVerify, true);
        } else {
            //does not exists need to remain the same
            this.deleteVerifyIdData(deleteVerify, false);
        }
    }

    saveVerifications = verificationExists => {
        const { verifyIdStore } = this.props;
        const businessProfileStore = this.props.businessProfileStore;
        verifyIdStore
            .postVerifyId(verificationExists)
            .then(() => {
                businessProfileStore
                    .getUpdatedData()
                    .then(rett => {
                        console.log('businessProfileStore.getUpdatedData', rett); //NOSONAR
                    })
                    .catch(e => {
                        console.error(e); //NOSONAR
                    });
            })
            .catch(e => {
                console.error(e); //NOSONAR
            });
    };

    deleteVerifyIdData = (dataExists) => {
        const { verifyIdStore } = this.props;
        const businessProfileStore = this.props.businessProfileStore;
        verifyIdStore
            .deleteVerifyId(dataExists)
            .then(() => {
                businessProfileStore
                    .getUpdatedData()
                    .then(rett => {
                        console.log('businessProfileStore.getUpdatedData', rett); //NOSONAR
                    })
                    .catch(e => {
                        console.error(e); //NOSONAR
                    });
            })
            .catch(e => {
                console.error(e); //NOSONAR
            });
    }

    // handleDeleteVerifyid = async () => {
    //     const businessProfileStore = this.props.businessProfileStore;
    //     await this.props.verifyIdStore.deleteVerifyId();
    //     await businessProfileStore.getUpdatedData();
    // };
    render() {
        const title = 'VERIFYID (PERSONAL DETAILS)';
        const img = images.verifyidLogo;
        const check = this.props.commonStore.businessProfile;
        const dataCheck = () => {
            if (check && check.verifyid !== '') {
                return true;
            }
        };
        const result = dataCheck();
        return (
            <Row className='mt-3 align-items-center'>
                <Col md={{ span: 4 }}>{title}</Col>
                <Col md={{ span: 5 }} className='d-flex'>
                    <>
                        <div className='w-50 text-center heightFix'>
                            <img src={img} alt='img' className='heightFix' />
                        </div>

                        <Button
                            color='primary'
                            onClick={() => {
                                result
                                    ? this.handleDeleteVerifyid()
                                    : this.handleSaveVerification();
                            }}
                        >
                            {result ? (
                                <Icon color='primary'>delete</Icon>
                            ) : (
                                    <Icon color='primary'>add</Icon>
                                )}
                        </Button>
                    </>
                </Col>
                <Col>
                    {this.state.ErrorMessage && <p>{this.state.ErrorMessage}</p>}
                </Col>
            </Row>
        );
    }
}

export default VerifyId;
