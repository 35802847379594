import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import {
    UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Button} from '@Comp';
import {Modal} from 'react-bootstrap';
// icons
import {FaLock,FaShare,FaCheck} from 'react-icons/fa';
import {MdFace,MdHelp} from 'react-icons/md';
import images from '@assets';
// style
import './style.scss';
import config from '../../../constants/config';

@inject('commonStore')
@withRouter
@observer
export default class header extends React.Component {
    constructor(props){
        super(props);
        this.state={
        isModalVisible:false,
        checked:false}
    }
    logout = () => {
        const {commonStore, history } = this.props;
        commonStore.logout();
        history.push('/login');
    };
	handleModal = (e, bool = false) => {
		this.setState({ isModalVisible: bool });
    };
    openModal = e =>{
       e.preventDefault();
            this.handleModal('e',true);
    }
    copyUrl = () => {
        const { commonStore } = this.props;
        const url = `${config.FrontEndURL}/user/${commonStore.businessProfile.id}`;
        navigator.clipboard.writeText(url);
        this.setState({checked:true})
    }
    startTour = () => {
        const { commonStore ,history} = this.props;
        commonStore.isTourOpen = true;
        commonStore.tourStep = 1;
        history.push('/home');

    }
    render() {
        const { commonStore } = this.props;
        const {checked, isModalVisible} = this.state;
        return (
            <header className="site-head d-flex align-items-center justify-content-between">
                <div className="wrap mr-4">
                    <img src={images.menu_white} alt='mnu' onClick={this.props.toggleNav} style={{cursor: 'pointer', width: '18px', height: '18px'}}/>
                </div>
                <div className="right-elems ml-auto d-flex">
                    <div className="wrap profile">
                        <UncontrolledDropdown>
                            <DropdownToggle tag="div">
                                <img src={images.NisaFinLogoTransparent} alt="avatar"/>
                            </DropdownToggle>
                            <DropdownMenu right style={{minWidth: '12rem'}}>
                                <DropdownItem header>Settings & Support</DropdownItem>
                                <DropdownItem divider/>
                                <DropdownItem><MdFace size="16"/>&emsp;<Link to="/home/profile">Profile</Link></DropdownItem>
                                <DropdownItem onClick={(e)=>this.openModal(e)}><FaShare size="16"/>&emsp;<span>Share profile</span></DropdownItem>
                                <DropdownItem onClick={()=>this.startTour()}><MdHelp size="16"/>&emsp;<Link to="/home/">Help</Link></DropdownItem>
                                <div className="text-right ml-3 mr-3 mt-2"><Button block color="success" size="sm" onClick={this.logout}><FaLock size="15"/>&emsp;Logout</Button></div>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </div>
                <Modal size='lg' show={isModalVisible} onHide={this.handleModal}>
					<Modal.Header closeButton>
						<Modal.Title>Profile's Public URL</Modal.Title>
					</Modal.Header>

					<Modal.Body>
							<div className="d-flex justify-content-between align-items-center mb-5" style={{border:'1px dashed grey'}}>
                            <div className="pl-2">{`${config.FrontEndURL}/user/${commonStore.businessProfile && commonStore.businessProfile.id}`}</div>
                                <Button className='m-0' onClick={this.copyUrl}>
                                   {checked && <FaCheck size="16"/>} &emsp; Copy</Button>
							</div>
					</Modal.Body>
				</Modal>
            </header>
        );
    }
}