import React from 'react';
import {Progress}  from '@Comp';
import numeral from 'numeral';

const OverallLoanStatus = ({noOfLoans, noOfLoansApproved, totalFunds, totalFundsApproved}) =>{ //NOSONAR
    const totalLoansCalc = (noOfLoansApproved > 0 && noOfLoans > 0)?Math.round(noOfLoansApproved/noOfLoans*100):0; //NOSONAR
    const totalFundsCalc = (totalFundsApproved > 0 && totalFunds > 0)?Math.round(totalFundsApproved/totalFunds*100):0;//NOSONAR
    return(
        <div className="row">
            <div className="col-md-6">
                <div className="card">
                    <div className="card-body">
                        <span className="status info"/>
                        <span className="m-b-10 font-size-16 m-l-5">Loans Recieved</span>
                        <div className="float-right">
                            <b className=" font-size-18 text-dark">{totalLoansCalc}%</b>

                        </div>
                        <div className="m-t-15 m-b-0">
                            <Progress multi className="mb-1 pbar">
                                <Progress bar color="b1" className="progress-bar-striped progress-sm  bg-info" value={totalLoansCalc}/>
                            </Progress>
                        </div>

                    </div>
                </div>
            </div>

            <div className="col-md-6">

                <div className="card">
                    <div className="card-body">
                        
                        <div className="m-l-4">
                            <span className="status gradient success"/>
                            <span className="m-b-10 font-size-16 m-l-5">Total Funds</span>
                            <div style={{display:"flex", justifyContent:"space-between"}}>
                                <b className="m-l-4 font-size-18 text-dark"> Requested: R{numeral(totalFunds).format('0,0')} </b>
                                <b className="m-l-4 font-size-18 text-dark"> Received: R{numeral(totalFundsApproved).format('0,0')}</b>
                                {/* <span> ZAR</span> */}
                            </div>
                        </div>

                        <div  className="m-t-14 m-b-0">
                            <Progress multi className="mb-1 pbar">
                                <Progress bar color="b1" className="progress-bar-striped progress-sm  bg-gradient-success" value={Math.round(totalFundsCalc)}/>
                            </Progress>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
};

export default OverallLoanStatus;
