import React, { Component } from "react";
import './style.scss'; 

const LoanSummary = ({ invoiceAmount, invoiceBuyer, contactPerson, buyerEmail, loanDescription, loanPercent }) => {
    const loanAmount = (invoiceAmount * (loanPercent / 100)).toFixed(2);
    invoiceAmount = (invoiceAmount * 1).toFixed(2);

    return (
        <div className="col-md-12 discounting">
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Loan Application Summary</h4>
                    <div className="card-toolbar">
                        <ul>
                            <li>
                                <a className="text-gray" href="#">
                                    <i className="mdi mdi-dots-vertical font-size-20"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="border bottom">
                    <div className="card-body p-v-15">
                        <div className="row align-items-center">
                            <div className="col-sm">
                                <p className="m-b-0">Loan Amount</p>
                                <h2 className="font-weight-light m-b-0 font-size-28" id="LoanAmount">R {loanAmount}</h2>
                            </div>

                        </div>
                    </div>
                </div>
                {/* <div className="card-body">
                    <ul className="list-unstyled d-flex flex-column p-h-10">
                        <li className="m-b-20">
                            <h5>Details</h5>
                        </li>
                        <li className="p-b-10 m-b-10 border bottom">
                            <span className="text-semibold text-dark font-size-15">Corporate Buyer</span>
                            <span className="float-right" id="corpBuyer">{invoiceBuyer}</span>
                        </li>
                        <li className="p-b-10 m-b-10">
                            <span className="text-semibold text-dark font-size-15">Total Value of Invoice/PO</span>
                            <span className="float-right" id="totValueInvPO">R {invoiceAmount}</span>
                        </li>
                        <li className="p-b-10 m-b-10">
                            <span className="text-semibold text-dark font-size-15">Buyer Telephone Number</span>
                            <span className="float-right" id="totValueInvPO">{contactPerson}</span>
                        </li> 
                        <li className="p-b-10 m-b-10">
                            <span className="text-semibold text-dark font-size-15">Buyer Email</span>
                            <span className="float-right" id="totValueInvPO">{buyerEmail}</span>
                        </li>
                        <li className="p-b-10 m-b-10">
                            <span className="text-semibold text-dark font-size-15">Loan Description</span>
                            <span className="float-right" id="totValueInvPO">{loanDescription}</span>
                        </li>
                    </ul>

                </div> */}
                <div className="card-body">
                    <div className="details-container">
                        <h5>Details</h5>
                        <dl className="details-list">
                            <div className="detail-item">
                                <dt>Corporate Buyer</dt>
                                <dd id="corpBuyer">{invoiceBuyer}</dd>
                            </div>
                            <div className="detail-item">
                                <dt>Total Value of Invoice/PO</dt>
                                <dd id="totValueInvPO">R {invoiceAmount}</dd>
                            </div>
                            <div className="detail-item">
                                <dt>Buyer Telephone Number</dt>
                                <dd>{contactPerson}</dd>
                            </div>
                            <div className="detail-item">
                                <dt>Buyer Email</dt>
                                <dd>{buyerEmail}</dd>
                            </div>
                            <div className="detail-item">
                                <dt>Loan Description</dt>
                                <dd>{loanDescription}</dd>
                            </div>
                        </dl>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default LoanSummary;