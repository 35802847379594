import React, { Component } from 'react';
import config from '../../constants/config';
import { inject, observer } from 'mobx-react';
import { Modal, Badge, ListGroup } from 'react-bootstrap';
import { Button } from '@Comp';

@inject('businessProfileStore')
@observer
class ViewUploadeFiles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false};
    }
    removeFiles = (e, documentId) => {
        e.preventDefault();
        this.props.handleRemoveDocument(e, documentId);
        this.props.businessProfileStore.handleRemove(documentId, 'Document').then(()=>{
            this.props.businessProfileStore.getUpdatedData();
        });
    };
    handleModal = (e, bool = false) => {
        this.setState({ show: bool });
    };

    // downloadImage = (imageUrl, fileName) =>{
    //     fetch(imageUrl)
    //       .then((response) => response.blob())
    //       .then((blob) => {
    //         const url = window.URL.createObjectURL(blob);
    //         const a = document.createElement("a");
    //         a.style.display = "none";
    //         a.href = url;
    //         a.download = fileName || "downloaded-image.jpg";
    //         document.body.appendChild(a);
    //         a.click();
    //         window.URL.revokeObjectURL(url);
    //         document.body.removeChild(a);
    //       })
    //       .catch(() => alert("Failed to download image"));
    //   }
    
    render() {
        const { title, data , removable , btnTitle} = this.props;
        let len = 0;
        if (Array.isArray(data)) {
            len = data.length;
        }
        return (
            <>
                <Modal size="lg" show={this.state.show} onHide={this.handleModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <ListGroup>
                            {this.props.data.map(document => {
                                return (
                                    <div key={document._id}>
                                        <ListGroup.Item>
                                            <a
                                                href={`${config.BASE_URL}${document.url}`}                                                
                                                className="text-primary"
                                                rel="noopener noreferrer"
                                                target="_blank"
                                                download={document.name}
                                                // onClick={()=>this.downloadImage(`${config.BASE_URL}${document.url}`, document.name)}
                                            >
                                                {document.name}
                                            </a>

                                            {this.props.businessProfileStore.businessProfile.isEditable && !removable &&
                                              <label className="badge badge-dark ml-3">
                                                <a
                                                    href=""
                                                    onClick={event =>
                                                        this.removeFiles(event, document._id)
                                                    }
                                                >
                                                    X
                                                </a>
                                            </label>
                            }
                                        </ListGroup.Item>
                                    </div>
                                );
                            })}
                        </ListGroup>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.handleModal()}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Button
                    color="info"
                    onClick={e => this.handleModal(e, true)}
                    className="float-right "
                >
                    {btnTitle || 'View Uploaded Files'}
                    <Badge variant="secondary" className="text-white ml-1">
                        {len}
                    </Badge>
                </Button>
            </>
        );
    }
}

export default ViewUploadeFiles;
