import React, { Component } from 'react';
import { View } from '../../shared/components';
import { Card, CardBody } from '@Comp';

const ViewHeader = () => ( //NOSONAR
    <div className="view-header">
        <header className="title text-white">
            <h1 className="h4 text-uppercase">Profile</h1>
            <p className="mb-0">Manage your profile</p>
        </header>
    </div>
);

const ViewContent = ({ children }) => (  //NOSONAR
    <div className="view-content view-components">{children}</div>
);
class Profile extends Component {
    render() {
        return (
            <View>
            <ViewHeader title='Loans' description='Some Description' />
            <ViewContent>
                <Card className='mb-4'>
                    <CardBody className='table-responsive'>
                    </CardBody>
                </Card>
            </ViewContent>
        </View>
        );
    }
}

export default Profile;
