import React, { Component } from 'react';
import { TextField } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import DocumentUploader from '../dropzone';
import ViewUploadedFiles from '../ViewUploadeFiles';
import validate from 'validate.js';
import {accountantSchema} from '../ValidationSchemas';
@inject('businessProfileStore','commonStore')
@observer
class AccountantDetails extends Component {
  constructor(props) {
    super(props);
    this.formState = {
      isValid: false,
      values: {},
      touched: {},
      errors: {}};
      this.state = {touced:''};
  }
  handleChange = e => {
    const { value, name } = e.target;
    const {
      accountantDetails,
      fieldsToUpdate
    } = this.props.businessProfileStore;
    accountantDetails[name] = value;
    this.setState({touched:name});
    this.formState={
      ...this.formState,
      values: {
        ...this.formState.values,
        [name]: value},
      touched: {
        ...this.formState.touched,
        [name]: true}};
    const errors = validate(this.formState.values, accountantSchema);
    this.formState={
      ...this.formState,
      isValid: errors ? false : true,//NOSONAR
      errors: errors || {}};
  if(this.formState.isValid){
    fieldsToUpdate.accountantDetailsDirty = true;
    this.props.formDataChange(false);
    }
  };
   hasError = field =>{
    let bool = false;
    if(this.formState.touched[field] && this.formState.errors[field]){
        bool = true;
    }
    return bool;
};

  handleRemoveDocument = () => {
    this.props.businessProfileStore.accountantDetails.supportDocument = [];
  };

  checkError = field => {
    const hasError = this.hasError(field);
    if(hasError){
      if(field === 'idNo'){
         return this.formState.errors[field][0].replace('Id','Cell');
      }
      return this.formState.errors[field][0];
    }
    return null;
  }
   render() {
    const { businessProfileStore } = this.props;
    const accountantDetails = businessProfileStore.accountantDetails;
    const fileInfo = {
      files: [],
      path: '/public/upload',
      refId: businessProfileStore.accountantDetails._id,
      ref: 'accountantdetails',
      source: 'content-manager',
      field: 'supportDocument' };
    return (
      <section>
        <form
          method="post"
          action=""
          className="m-t-15"
          encType="multipart/form-data"
        >
          <div className="form-group row">
            <label className="col-sm-2 col-form-label control-label text-sm-right">
              SAICA/SAIPA No.
            </label>
            <div className="col-sm-10">
              <TextField
                id="saipaNo"
                name="saipaNo"
                type="text"
                className="form-control accountantDetails"
                placeholder=""
                value={accountantDetails.saipaNo}
                onChange={this.handleChange}
                error={this.hasError('saipaNo')}
                helperText={
                  this.checkError('saipaNo')
                }
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label control-label text-sm-right">
              Cell No.
            </label>
            <div className="col-sm-10">
              <TextField
                id="idNo"
                name="idNo"
                type="number"
                className="form-control accountantDetails"
                placeholder=""
                minLength="13"
                maxLength="13"
                value={accountantDetails.idNo}
                onChange={this.handleChange}
                error={this.hasError('idNo')}
                helperText={
                  this.checkError('idNo')
                }
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label control-label text-sm-right">
              Email
            </label>
            <div className="col-sm-10">
              <TextField
                id="email"
                name="email"
                type="email"
                className="form-control accountantDetails"
                placeholder=""
                value={accountantDetails.email}
                onChange={this.handleChange}
                error={this.hasError('email')}
                helperText={
                  this.checkError('email')
                }
              />
            </div>
          </div>
        </form>
        <div className="row justify-content-end">
          <div className="col-4"></div>
          <div className="col-10">
            {accountantDetails.supportDocument.length < 1 &&
            accountantDetails._id && this.props.isDocumentUploadVisible && (
              <DocumentUploader
                fileData={fileInfo}
                maxFiles={1 - Number(accountantDetails.supportDocument.length)}
                multiple={false}
                inputContent={'Upload Supporting Document'}
              />
            )}
          </div>
          <div className="w-100 d-flex justify-content-center my-2">
            {accountantDetails.supportDocument.length > 0 && (
              <ViewUploadedFiles
                title="Supporting Document"
                data={accountantDetails.supportDocument}
                handleRemoveDocument={this.handleRemoveDocument}
              />
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default AccountantDetails;
