import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Card, CardBody } from '@Comp';
import { View } from '../../../shared/components';
import NoLoan from './no-loans';
import '../app.scss';
import OpenLoanApplications from './open-loan-applications';

const ViewHeader = () => ( //NOSONAR
    <div className="view-header">
        <header className="title text-white">
            <h1 className="h4 text-uppercase">Loans</h1>
            <p className="mb-0">View all you loans and pending loans</p>
        </header>
    </div>
);

const ViewContent = ({ children }) => (  //NOSONAR
    <div className="view-content view-components">{children}</div>
);
@inject('businessProfileStore', 'loanStore', 'commonStore')
@observer
class Loans extends Component {
	async componentDidMount() {
		const { profilePercenatge } = this.props.businessProfileStore;
		if (profilePercenatge === 0) {
			this.props.commonStore.setLoading(true);
			await this.props.businessProfileStore.getUpdatedData();
			await this.props.businessProfileStore.setLoanDetails();
			this.props.commonStore.setLoading(false);
		}
	}
	render() {
		const { businessProfileStore } = this.props;
		let loanAppl = [];
		let offeredloan = [];
		if (
			businessProfileStore.myLoanApplications &&
			businessProfileStore.myLoanApplications.length > 0
		) {
			loanAppl = businessProfileStore.myLoanApplications;
		}
		if (
			businessProfileStore.offeredloanApplications &&
			businessProfileStore.offeredloanApplications.length > 0
		) {
			offeredloan = businessProfileStore.offeredloanApplications;
		}
		return (
			<View>
				<ViewHeader title='Loans' description='Some Description' />
				<ViewContent>
					<Card className='mb-4'>
						<CardBody className='table-responsive'>
							{/* <OverallLoanStatus/> */}
							{(((loanAppl && loanAppl.length > 0) ||
								(offeredloan && offeredloan.length > 0)) && (
								<OpenLoanApplications
									loanApplications={loanAppl}
									offeredLoanAppls={offeredloan}
								/>
							)) || <NoLoan />}
						</CardBody>
					</Card>
				</ViewContent>
			</View>
		);
	}
}

export default Loans;
