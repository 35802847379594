import React, { Component } from 'react';
import Debtor from './debtor';
import { inject, observer } from 'mobx-react';
@inject('businessProfileStore','commonStore')
@observer
class PastInvoices extends Component {
    constructor() {
        super();
        this.state = {
            disabled: true};
    }
    handleAddPastInvoices = () => {
        this.props.businessProfileStore.addPastInvoice();
    };
    handleSavePastInvoice = () => {
        this.props.businessProfileStore.postPastInvoice().then(()=>{
            this.props.businessProfileStore.getUpdatedData();
            this.setState({ disabled: true });
        });
    };

    handleChange = (e, invoiceID, index , isError) => {
        const value = e.target.value;
        let name = e.target.name;
        name = name.replace(`-${invoiceID}`, '');
        const copyData = [...this.props.businessProfileStore.pastInvoices];
        copyData[index][name] = value;
        copyData[index]['dirty'] = true;
            if(isError){
                this.props.businessProfileStore.pastInvoices = copyData;
                this.setState({ disabled: false });
            }else{
                this.setState({ disabled: true });
            }
    };

    handleRemovePastInvoice = (e, invoiceID) => {
        e.preventDefault();
        this.props.businessProfileStore.handleRemove(invoiceID, 'pastInvoice');
    };
    render() {
        const { businessProfileStore } = this.props;
    const {isVerified} = this.props.businessProfileStore;
        const pastInvoice = businessProfileStore.pastInvoices;

        return (
            <div className="p-h-15 p-v-20">
                <p>
                    Showing us that you have other corporate debtors with outstanding
                    Invoices/Purchase Orders improves your credit-score as you are more
                    likely to meet your loan obligation.
                </p>
                <div>
                    <h5>Past Invoice Details {isVerified && <span className="badge text-bold"> - {(isVerified.businessFinancialsVerified && 'Verified')||'Not Verified'}</span>}</h5>
                    <div id="corpGroup" className="p-h-10">
                        {pastInvoice.map((debtorInvoice, index) => (
                            <Debtor
                                pastInvoice={debtorInvoice}
                                onChange={this.handleChange}
                                onClick={this.handleRemovePastInvoice}
                                key={`pastInvoiceDetails-${debtorInvoice.id}`}
                                index={index}
                            />
                        ))}
                    </div>

                    <div className="text-sm-right">
                        <button
                            id="addDebtor"
                            className="btn btn-info addDebtor"
                            onClick={this.handleAddPastInvoices}
                        >
                            Add Buyer
                        </button>
                        {this.props.businessProfileStore.businessProfile.isEditable &&
                        <button
                            className="btn btn-success"
                            id="saveDebtors"
                            onClick={this.handleSavePastInvoice}
                            disabled={this.state.disabled}
                        >
                            Save
                        </button>}
                    </div>
                </div>
            </div>
        );
    }
}

export default PastInvoices;
