
import commonStore from './commonStore';
import businessProfileStore from './businessProfileStore';
import loanStore from './loanStore';
import viewStore from './viewStore';
import verifyIdStore from './verifyIdStore';
import emailStore from './emailStore';

const stores = {
    commonStore,
    businessProfileStore,
    loanStore,
    viewStore,
    verifyIdStore,
    emailStore
};

export default stores;
