import React from 'react';
import {
    Card, CardBody
} from '@Comp';

export default ({children}) => (
    <div className="view-content view-components">
        <Card>
            <CardBody>
                {children}
            </CardBody>
        </Card>
    </div>
);