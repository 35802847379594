import React, { useState, useEffect } from 'react';
import { Form } from '@Comp';
import MaterialIcon from 'material-icons-react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import validate from 'validate.js';
import DocumentUploader from '../../dropzone';
import ViewUploadedFiles from '../../ViewUploadeFiles';
import { inject } from 'mobx-react';
const IS_REQUIRED = 'is required';

const useStyles = makeStyles({
  formControl: {
    flex: 1,
    marginBottom: '0px',
    borderBottom: '1px solid',
    padding: '2px'
  }
});
const DirectorDetails = ({ //NOSONAR
  onChange,
  onClick,
  index,
  businessProfileStore,
  sendError
}) => {//NOSONAR
  const classes = useStyles();
  const companyDirector = businessProfileStore.companyDirectors[index];
  const directorID = companyDirector.id;
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });
  const schema = {
    [`firstName${directorID}`]: {
      presence: { allowEmpty: false, message: IS_REQUIRED }
    },
    [`lastName${directorID}`]: {
      presence: { allowEmpty: false, message: IS_REQUIRED }
    },
    [`idNo${directorID}`]: {
      presence: { allowEmpty: false, message: IS_REQUIRED },
      length: {
        maximum: 13
      },
      format: {
        //South african id regex , ref: https://mybroadband.co.za/news/security/303812-what-your-south-african-id-number-means-and-what-it-reveals-about-you.html
        pattern: /(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/ //NOSONAR
      }
    },
    [`email${directorID}`]: {
      presence: { allowEmpty: false, message: IS_REQUIRED },
      email: true
    },
    [`cellNo${directorID}`]: {
      presence: { allowEmpty: false, message: IS_REQUIRED },
      length: {
        maximum: 10,
        minimum: 10
      }
    }
  };

  const checkError = () => {
    const errors = validate(formState.values, schema);
    let bool = true;
    if (typeof errors !== 'undefined' && errors) {
      bool = false;
    }
    setFormState(formStateData => ({
      ...formStateData,
      isValid: bool,
      errors: errors || {}
    }));
  };

  useEffect(() => {
    let didUnmount = false;
    if (!didUnmount) {
      checkError();
    }

    return function cleanup() {
      //unsubscribe from external stuff
      didUnmount = true;
      return null;
    };
    // eslint-disable-next-line
  }, [formState.touched,businessProfileStore.directorAutofilled]);

  const handleChange = e => {
    e.persist();
    const { checked, value, type } = e.target;
    let name = value;
    if (type === 'checkbox') {
      name = checked;
    }
    setFormState(formStateData => ({
      ...formStateData,
      values: {
        ...formStateData.values,
        [e.target.name]: name
      },
      touched: {
        ...formStateData.touched,
        [e.target.name]: true
      }
    }));
    onChange(e, directorID, index);
  };

  const hasError = field => {
    let bool = false;
    if (formState.touched[field] && formState.errors[field]) {
      bool = true;
    }
    return bool;
  };
  const errorMsg = (field, id) => {
    if (Object.keys(formState.touched).length !== 0) {
      if (hasError(field)) {
        sendError(index, 'error');

        return formState.errors[field][0].replace(id, '');
      } else {
        sendError(index, '');
      }
    }
    return null;
  };

  const handleRemoveDocument = () => {
    businessProfileStore.companyDirectors[index] = {
      ...businessProfileStore.companyDirectors[index],
      certifiedID: []
    };
  };

  const fileInfo = {
    files: [],
    path: '/public/upload',
    refId: companyDirector._id,
    ref: 'companydirectors',
    source: 'content-manager',
    field: 'certifiedID'
  };

  const onRemoveClick = e => {
    e.preventDefault();
    onClick(e, directorID);
  };
  return (
    <div key={directorID}>
      <Form className="m-t-15" name={`directorDetails- ${directorID}`}>
        <div className="removeDirector">
          <span className="badge badge-danger">Remove Director</span>
          {businessProfileStore.businessProfile.isEditable && <a
            href=""
            className="badge badge-danger ml-1"
            onClick={e => onRemoveClick(e)}
          >
            <MaterialIcon icon="close" size={9} />
          </a>
          }
        </div>
        <br></br>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label control-label text-sm-right ">
            First Name
          </label>
          <div className="input-group col-sm-10">
            <div className="col-sm-0">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <MaterialIcon icon="account_box" size={17} />
                </span>
              </div>
            </div>
            <TextField
              id={`firstName${directorID}`}
              name={`firstName${directorID}`}
              type="text"
              className={`${classes.formControl} pl-2`}
              placeholder=""
              value={companyDirector.firstName}
              onChange={handleChange}
              error={hasError(`firstName${directorID}`)}
              helperText={errorMsg(`firstName${directorID}`, directorID)}
              InputProps={{ disableUnderline: true }}
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label control-label text-sm-right ">
            Last Name
          </label>
          <div className="input-group col-sm-10">
            <div className="col-sm-0">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <MaterialIcon icon="account_box" size={17} />
                </span>
              </div>
            </div>
            <TextField
              id={`lastName${directorID}`}
              name={`lastName${directorID}`}
              type="text"
              className={`${classes.formControl} pl-2`}
              placeholder=""
              value={companyDirector.lastName}
              onChange={handleChange}
              error={hasError(`lastName${directorID}`)}
              helperText={errorMsg(`lastName${directorID}`, directorID)}
              InputProps={{ disableUnderline: true }}
            />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-2 col-form-label control-label text-sm-right ">
            ID No.
          </label>
          <div className="input-group col-sm-10">
            <div className="col-sm-0">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <MaterialIcon icon="book" size={17} />
                </span>
              </div>
            </div>
            <TextField
              id={`idNo${directorID}`}
              name={`idNo${directorID}`}
              type="number"
              className={`${classes.formControl} pl-2`}
              placeholder=""
              minLength="13"
              maxLength="13"
              value={companyDirector.idNo}
              onChange={handleChange}
              error={hasError(`idNo${directorID}`)}
              helperText={errorMsg(`idNo${directorID}`, directorID)}
              InputProps={{ disableUnderline: true }}
            />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label control-label text-sm-right ">
            Email
          </label>

          <div className="input-group col-sm-10">
            <div className="col-sm-0">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <MaterialIcon icon="email" size={17} />
                </span>
              </div>
            </div>
            <TextField
              id={`email${directorID}`}
              name={`email${directorID}`}
              type="email"
              className={`${classes.formControl} pl-2`}
              placeholder=""
              value={companyDirector.email}
              onChange={handleChange}
              error={hasError(`email${directorID}`)}
              helperText={errorMsg(`email${directorID}`, directorID)}
              InputProps={{ disableUnderline: true }}
            />
          </div>
        </div>

        <div className="form-group row">
          <label
            className="col-sm-2 col-form-label control-label text-sm-right "
            htmlFor="CellNo"
          >
            Cell No.
          </label>
          <div className="input-group col-sm-10">
            <div className="col-sm-0">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <MaterialIcon icon="settings_cell" size={17} />
                </span>
              </div>
            </div>
            <TextField
              id={`cellNo${directorID}`}
              name={`cellNo${directorID}`}
              type="tel"
              className={`${classes.formControl} pl-2`}
              placeholder=""
              minLength="10"
              maxLength="12"
              value={companyDirector.cellNo}
              onChange={handleChange}
              error={hasError(`cellNo${directorID}`)}
              helperText={errorMsg(`cellNo${directorID}`, directorID)}
              InputProps={{ disableUnderline: true }}
            />
          </div>
        </div>
      </Form>

      <div className="row justify-content-end">
        <div className="col-10 offset-2 my-2">
          {companyDirector.certifiedID.length === 0 && companyDirector._id && (
            <DocumentUploader
              fileData={fileInfo}
              maxFiles={1 - Number(companyDirector.certifiedID.length)}
              multiple={true}
              inputContent={'Upload Certified ID'}
            />
          )}
        </div>
      </div>
      <div className="row justify-content-center absolute_center">
        {companyDirector.certifiedID.length > 0 && (
          <ViewUploadedFiles
            title="Directors Certified Document"
            data={companyDirector.certifiedID}
            handleRemoveDocument={handleRemoveDocument}
          />
        )}
      </div>
    </div>
  );
};
const DirectorState = inject('businessProfileStore')(DirectorDetails);
export default DirectorState;
