import React from 'react';
import { inject, observer } from 'mobx-react';
import { Swipeable } from 'react-swipeable';
import {Link} from 'react-router-dom';
import {
    Form, Input, Label, FormGroup, Button, FormText,
    Card, CardBody
} from '@Comp';
import images from '@assets';

import { FaTwitter, FaFacebook, FaGoogle } from 'react-icons/fa';

import './style.scss';

const _slides = [
    {
        title: 'Some Title',
        description: "asf asd fasd sad fsad fads fsd fds fads asdf.",
        navigate: { text: 'Login', to: '/signin'},
        backgroundColor: '#215787',
        img: images.img1
    },
    {
        title: 'Some Title',
        description: "asf asd fasd sad fsad fads fsd fds fads asdf.",
        navigate: { text: 'Login', to: '/signin'},
        backgroundColor: '#215787',
        img: images.img2
    },
    {
        title: 'Some Title',
        description: "asf asd fasd sad fsad fads fsd fds fads asdf.",
        navigate: { text: 'Login', to: '/signin'},
        backgroundColor: '#215787',
        img: images.img3
    },
    {
        title: 'Some Title',
        description: "asf asd fasd sad fsad fads fsd fds fads asdf.",
        navigate: { text: 'Login', to: '/signin'},
        backgroundColor: '#215787',
        img: images.img4
    },
  ];

@inject('commonStore')
@observer
export default class Walkthrough extends React.Component {
    constructor(props) {
      super(props);
      
      this.IMAGE_PARTS = 6;
      
      this.changeTO = null;
      this.AUTOCHANGE_TIME = 0;
      
      this.state = { activeSlide: -1, prevSlide: -1, sliderReady: false, slides: _slides };
    }
    
    componentWillUnmount() {
      window.clearTimeout(this.changeTO);
    }
    
    componentDidMount() {
      this.runAutochangeTO();
      setTimeout(() => {
        this.setState({ activeSlide: 0, sliderReady: true });
      }, 0);
    }
    
    runAutochangeTO() {
        if(this.AUTOCHANGE_TIME > 0){
            this.changeTO = setTimeout(() => {
                this.changeSlides(1);
                this.runAutochangeTO();
            }, this.AUTOCHANGE_TIME);
        }
    }
    
    changeSlides(change) {
      window.clearTimeout(this.changeTO);
      const { length } = this.state.slides;
      const prevSlide = this.state.activeSlide;
      let activeSlide = prevSlide + change;
      if (activeSlide < 0) activeSlide = length - 1;
      if (activeSlide >= length) activeSlide = 0;
      this.setState({ activeSlide, prevSlide });
    }
    
    render() {
      const { activeSlide, prevSlide, sliderReady, slides } = this.state;
      const config = {
        delta: 10,
        trackTouch: true,
        trackMouse: true,
      };

      if(slides && slides.length > 0){
        return (
            <Swipeable {...config}
                onSwipedLeft={(eventData) => { this.changeSlides(1); }} 
                onSwipedRight={(eventData) => { this.changeSlides(-1); }}>
            {/* <div className="view-content d-flex justify-content-center align-items-center flex-column"> */}
                <div className={`slider ${(sliderReady)?'s--ready':'' }`}>
                    <p className="slider__top-heading">
                        <img alt='logo' src={images.loginIcon}/>
                        <h1 className='headingLogin'>Welcome!</h1>
                    </p>
                    <div className="slider__slides">
                        {slides.map((slide, index) => (
                        <div
                            className={`slider__slide ${(activeSlide === index)?'s--active':''} ${(prevSlide === index)?'s--prev':''}`}
                            style={{ backgroundColor: slide.backgroundColor }}
                            key={index}
                            >
                            <div className="slider__slide-content">
                                <h2 className="slider__slide-heading">
                                    {slide.title.split('').map(l => <span>{l}</span>)}
                                </h2>
                                <h3 className="slider__slide-subheading">{slide.description || slide.title}</h3>
                                
                                {(slide.navigate && slide.navigate.text && slide.navigate.to && slide.navigate.text.length > 0)?
                                    <Link className="btn primary btn-lg btn-block slider__slide-btn" to={slide.navigate.to}>{slide.navigate.text}</Link>
                                :null}
                                </div>
                                <div className="slider__slide-parts">
                                {[...Array(this.IMAGE_PARTS).fill()].map((x, i) => (
                                    <div className="slider__slide-part" key={i}>
                                    <div className="slider__slide-part-inner" style={{ backgroundImage: `url(${slide.img})` }} />
                                    </div>
                                ))}
                            </div>
                        </div>
                        ))}
                    </div>
                    <div className="slider__control" onClick={() => this.changeSlides(-1)} />
                    <div className="slider__control slider__control--right" onClick={() => this.changeSlides(1)} />
                </div>
            {/* </div> */}
            </Swipeable>
        );
      } else {
          return null;
      }
    }
  }
  