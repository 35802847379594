
import React, { useState, useEffect } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import {
    Form, Input, Label, FormGroup, Button, FormText,
    Card, CardBody, Row
} from '@Comp';

import images from '@assets';
import { View } from '../../../shared/components';

import { FaTwitter, FaFacebook, FaGoogle } from 'react-icons/fa';

import '../style.scss';
import { inject } from 'mobx-react';

const LoginPage = (props) => { //NOSONAR
    const { commonStore, history, emailStore } = props;
    const [inputs, setInputs] = useState({
        businessEmailAddress: null,
        password: null
    });
    const [inputEmail] = useState({ email: true });
    const [err, setErr] = useState(null);
    const socialMediaLogin = false;
    const { state } = props.location;
    const handleChange = e => {
        if (e && e.target) {

            const target = e.target;
            //current input field being modified
            const { value, name } = target;
            setInputs(inputs1 => ({ ...inputs1, [name]: value }));
        }

    };
    useEffect(() => {
        let reload = false;
        if (!reload) {
            reload = true;//NOSONAR
            check();
        }
    }, [err]);


    const handleLogin = async (e) => { //NOSONAR
        e.preventDefault();
        if (inputs.businessEmailAddress && inputs.businessEmailAddress.length > 0 && inputs.businessEmailAddress.indexOf('@') > -1) {
            if (inputs.password && inputs.password.length > 0) {
                setErr(null);

                try {
                    await commonStore.login(inputs.businessEmailAddress, inputs.password);
                    if (state && state.from) { //NOSONAR
                        const queryParams = new URLSearchParams(state.from.search);
                        if (queryParams.has('o_id')) {
                            window.location.href = `${state.from.pathname}${state.from.search}`;
                            return;
                        }
                    }
                    window.location.href = '/home';
                } catch (e) {//NOSONAR
                    if (e.response) { //NOSONAR
                        commonStore.setLoading(false);
                        setErr(e.response.data.message);
                    } else {
                        commonStore.setLoading(false);
                        setErr('Invalid login details');//NOSONAR
                    }
                }
                if (inputEmail.email) {
                    emailStore.postEmail()
                        .then(() => {
                            commonStore.setLoading(false);
                        });
                }
            } else {
                setErr('Invalid login details');
            }
        } else {
            setErr('Invalid login details');
        }

    };
    const check = () => {
        if (state && state.from) {
            const queryParams = new URLSearchParams(state.from.search);
            if (queryParams.has('o_id')) {
                setErr('login to continue the process of document signing');
            }
        }
    };
    return (
        <View>
            <div
                className="view-content view-content-login view-pages view-session d-flex justify-content-center align-items-center flex-column">
                <Row>
                    <div className="col-md-7 mb-7 d-none d-md-block">
                        <img className="img-fluid" src={images.loginIcon} alt="" />
                        <div className="m-t-15 m-l-20">
                            <h1 className="font-weight-light font-size-35 text-white">Welcome Back!</h1>
                            <p className="text-white width-70 text-opacity m-t-25 font-size-16">Find everything you need to
                                track the progress of your application.</p>
                            <div className="m-t-60">
                                <a href="#" className="text-white text-link m-r-15">Term &amp; Conditions</a>
                                <a href="#" className="text-white text-link">Privacy &amp; Policy</a>
                            </div>
                        </div>
                    </div>

                    <Card className="col-md-5 mb-5 form-card">
                        <CardBody>
                            <header className="mb-5">
                                {/* Logo */}
                                <h2 className="">Login</h2>
                                {history.location.state ?
                                    history.location.state.detail === 'AccountCreated' ?
                                        <p className="notification">Please check your mailbox and verify your email address.</p>
                                        : history.location.state.detail === 'asswordChanged' ?
                                            <p className="notification">Password Successfully Changed.</p>
                                            : <p className="sublead">Please enter your business email and password to login.</p>
                                    : <p className="sublead">Please enter your business email and password to login.</p>}
                            </header>
                            <Form onSubmit={handleLogin}>
                                <FormGroup className="mb-4">
                                    <Label>Business Email Address</Label>
                                    <Input
                                        type="text"
                                        name="businessEmailAddress"
                                        placeholder="someone@xyz.com"
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                <FormGroup className="mb-4">
                                    <Label>Password</Label>
                                    <Input
                                        type="password"
                                        name="password"
                                        placeholder="enter your password here"
                                        onChange={handleChange}
                                    />
                                    <FormText className="text-right">
                                        <NavLink to="/forget">Forget your password?</NavLink>
                                    </FormText>
                                </FormGroup>
                                <FormGroup className="mb-4">
                                    {(err && err.length > 0) &&
                                        <Label className="err text-danger">{err}</Label>}
                                </FormGroup>
                                <FormGroup className="text-right">
                                    <NavLink className="btn btn-success" to="/register">Register</NavLink>{''}
                                    <Button color="info" type="submit">Login</Button>
                                </FormGroup>
                            </Form>
                            {socialMediaLogin &&
                                (<div>
                                    <p className="font-weight-bold">or sign in with...</p>
                                    <ul className="list-unstyled social-auth">
                                        <li><a href="#na"><FaTwitter size={22} /></a></li>
                                        <li><a href="#na"><FaFacebook size={22} /></a></li>
                                        <li><a href="#na"><FaGoogle size={22} /></a></li>
                                    </ul>
                                </div>)
                            }

                        </CardBody>
                    </Card>
                </Row>
            </div>
        </View>
    );

};

const Login = inject('commonStore', 'emailStore')(LoginPage);
export default Login;

withRouter(LoginPage);

