const setItem = (key, _data) => {
    let data = null;
    if(typeof _data === 'object'){
        data = JSON.stringify(_data);
    } else{
        data = _data;
    }
    window.localStorage.setItem(key, data);
};

const getItem = (key) => {
    let _data = window.localStorage.getItem(key);
    if(_data && _data.indexOf('{') > -1){
        try{
            return JSON.parse(_data);
        }catch(err){
            return null;
        }
    } else {
        return _data;
    }
};

const hasItem = (key) =>{
   let _item = getItem(key);
   return (_item !== null);
};

const removeItem = (key) => {
    window.localStorage.removeItem(key);
};

const clear = () => {
    window.localStorage.clear();
};

export default {
    setItem,
    getItem,
    hasItem,
    removeItem,
    clear
}