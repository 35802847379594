import { observable, action } from 'mobx';
import businessProfileStore from './businessProfileStore';
import { AuditTrails } from '../services/requests';
import storageUtil from './storageUtil';

class ViewStore {
    @observable loanOffersState = {
        loanAppl: null,
        providerList: [],
        selected: 0
    };

    // Update this state to change dash values ;)
    @observable dashboardState = {
        noOfLoans: 0,
        noOfLoansApproved: 0,
        totalFunds: 0,
        totalFundsApproved: 0,
        notifications: []
    };

    @observable adminState = {
        businessProfile: null,
        loanAppl: null,
        notfications: null
    };

    @observable loanApplyState = {
        id: null,
        invoiceAmount: '',
        invoiceBuyer: '',
        contactPerson: '',
        buyerEmail: '',
        loanDescription: '',
        buyerRelationship: 'supplyChain',
        loanPercent: 20,
        invoiceType: 'Purchase Order',
        startDate: new Date()
    };

    @action setDashboardState(data, callback) {
        Object.keys(data).forEach(key => {
            this.dashboardState[key] = data[key];
        });

        if (callback && typeof callback === 'function') {
            callback();
        }
    }

    @action async refreshDashboardState() {
        const check =
            businessProfileStore.loanApplications &&
            businessProfileStore.loanApplications.length > 0;
        const loansApproved = check
            ? businessProfileStore.loanApplications.filter(f => f.offerStatus === 'accepted').length : 0; //NOSONAR
        const tFunds = check
            ? businessProfileStore.loanApplications//NOSONAR
                .map(m => m.loanAmount)
                .reduce((a, b) => a + b) : 0;
        const tFundsA =
            check && loansApproved > 0
                ? businessProfileStore.loanApplications//NOSONAR
                    .filter(f => {
                        if (f.offerStatus === 'accepted') {
                            f['offer'] = f.offers.find(offer => offer.id === f.acceptedOffer) || {};
                            return f;
                        }
                        return null;
                    })
                    .map(m => m.offer.loanAmount || 0)
                    .reduce((a, b) => a + b)
                : 0;
        this.dashboardState = {
            ...this.dashboardState,
            noOfLoans: businessProfileStore.loanApplications.length,
            noOfLoansApproved: loansApproved,
            totalFunds: tFunds,
            totalFundsApproved: tFundsA
        };
    }
    @action async getNotifictions() {
        const usr = storageUtil.getItem('usr');
        const notif = [];
        if (usr && usr.profile) {
            const auditTret = await AuditTrails.getAllByProfileSorted(usr.profile);
            if (auditTret && auditTret.data) {
                auditTret.data.forEach(auditTrail => {
                    if (auditTrail && auditTrail.status && auditTrail.status.name) {
                        notif.push({
                            loandetail: auditTrail.loandetail,
                            offer: auditTrail.offer,
                            date: new Date(auditTrail.createdAt).toDateString(),
                            from: auditTrail.name,
                            title: auditTrail.status.smeTitle || auditTrail.status.name,
                            message: auditTrail.description,
                            status: auditTrail.status.smeTitle || auditTrail.status.name,
                            color: auditTrail.status.color,
                            link: auditTrail.status.link
                        });
                    }
                });
            }
        }

        this.dashboardState = { ...this.dashboardState, notifications: notif };
    }

    @action async getProfileNotifications(profileId) {
        const notif = [];
        const auditTret = await AuditTrails.getAllByProfileSorted(profileId);
        if (auditTret && auditTret.data) {
            auditTret.data.forEach(audit => {
                if (audit && audit.status && audit.status.name) {
                    notif.push({
                        loandetail: audit.loandetail,
                        offer: audit.offer,
                        date: new Date(audit.createdAt).toDateString(),
                        from: audit.name,
                        title: audit.status.smeTitle || audit.status.name,
                        message: audit.description,
                        status: audit.status.smeTitle || audit.status.name,
                        color: audit.status.color,
                        link: audit.status.link
                    });
                }
            });
        }
        this.adminState.notfications = notif;

        return notif;
    }

    @action setLoanOffersState(data, callback) {
        Object.keys(data).forEach(key => {
            this.loanOffersState[key] = data[key];
        });

        if (callback && typeof callback === 'function') {
            callback();
        }
    }

    @action setLoanApplyState(data, callback) {
        Object.keys(data).forEach(key => {
            this.loanApplyState[key] = data[key];
        });

        if (callback && typeof callback === 'function') {
            callback();
        }
    }

    @action setEditLoanDetails(loanAppl) {
        const detail = {
            id: loanAppl.id || loanAppl._id,
            invoiceAmount: loanAppl.invoiceAmount || 0,
            invoiceBuyer: loanAppl.buyer || '',
            contactPerson: loanAppl.contactPerson || '',
            buyerEmail: loanAppl.buyerEmail || '',
            loanDescription: loanAppl.loanDescription || '',
            buyerRelationship: loanAppl.buyerRelationship || '',
            loanPercent: loanAppl.loanPercent || 0,
            invoiceType: loanAppl.factoring ? 'factoring' : 'discounting', //NOSONAR
            startDate: new Date(loanAppl.invoiceDueDate)
        };

        this.loanApplyState = detail;
    }

    @action setAdminState(data, callback) {
        Object.keys(data).forEach(key => {
            this.adminState[key] = data[key];
        });

        if (callback && typeof callback === 'function') {
            callback();
        }
    }
}

const viewStore = new ViewStore();
export default viewStore;
